import { createContext } from "react";
import { EN_TRANSLATION } from "../translation/EN";
import { FR_TRANSLATION } from "../translation/FR";

export const TranslationContext = createContext();

export const translations = {
  EN: EN_TRANSLATION,
  FR: FR_TRANSLATION,
};
