import { useState, useContext, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "yup-phone";
import { TranslationContext } from "../../../assets/contexts/translationContext";
import Auth from "../Auth";
import UserTypeSelector from "../UserTypeSelector/UserTypeSelector";
import InputWithFormik from "../../InputWithFormik/InputWithFormik";
import CheckboxWithFormik from "../../CheckboxWithFormik/CheckboxWithFormik";
import AuthSubmitBtn from "../AuthSubmitBtn/AuthSubmitBtn";
import CheckEmailCode from "../CheckEmailCode/CheckEmailCode";
import ProfileInfo from "../../Desk/Profile/ProfileInfo/ProfileInfo";
import {
  REG_AUTH_TYPE,
  LOGIN_AUTH_TYPE,
  BUYER_USER_TYPE,
  BREEDER_USER_TYPE,
  // SHELTER_USER_TYPE,
  LOF_ALL_TYPE,
  LOF_SOME_TYPE,
  LOF_NO_TYPE,
  FACEBOOK_REGEXT_PATTERN,
  INSTAGRAM_REGEXT_PATTERN,
  WHATSAPP_REGEXT_PATTERN,
  ALREADY_EXIST_ERR_CODE,
  TOO_MANY_REQUESTS_ERR_CODE,
} from "../../../assets/utils/constants";
import { LikeIcon } from "../../../assets/icons/icons";
import pattern from "../../../assets/images/auth/registration-pattern.png";
import image from "../../../assets/images/auth/registration.png";
import "./Registration.css";
import moment from "moment/moment";

function Registration({
  language,
  isAuthOpen,
  onAuthClose,
  onAuthOpen,
  isAuthSwitch,
  setAuthSwitch,
  signupBuyer,
  signupBreederStepOne,
  signupBreederStepTwo,
  regStep,
  addressHints,
  suggestAddress,
  isPrelouderVisible,
  setIsPrelouderVisible,
  checkEmailCode,
  logout,
  handleResendCode,
  setAddressHints,
  apiError,
  isResendPreloader,
}) {
  const { auth, errors, labels } = useContext(TranslationContext);
  const { registration, confirmEmail } = auth;
  const {
    title,
    breederTitle,
    imageTitle,
    imageText,
    submitText,
    redirectText,
    redirectLink,
    breederSubmit,
  } = registration;
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [userType, setUserType] = useState("");
  const [apiErrText, setApiErrText] = useState("");

  const initialValues = {
    userType: BUYER_USER_TYPE,
    name: "",
    // siren: "",
    // shelter: "",
    email: "",
    password: "",
    acceptedTerms: false,
  };

  const initialBreederValues = {
    phone: "",
    // country: "",
    addressValue: "",
    address: {},
    description: "",
    breeds: [],
    startYear: "",
    isVerified: false,
    siren: "",
    isBreedClub: false,
    isGenetic: false,
    lof: LOF_NO_TYPE,
    facebook: "",
    instagram: "",
    whatsapp: "",
  };

  const validationSchema = Yup.object().shape({
    userType: Yup.string()
      .oneOf([
        BUYER_USER_TYPE,
        BREEDER_USER_TYPE,
        // SHELTER_USER_TYPE
      ])
      .required(),
    name: Yup.string().required(errors.required),
    // name: Yup.string().when("userType", {
    //   is: (val) => val === BUYER_USER_TYPE,
    //   then: (schema) => schema.required(errors.required),
    // }),
    // siren: Yup.string().when("userType", {
    //   is: (val) => val === BREEDER_USER_TYPE,
    //   then: (schema) => schema.required(errors.required),
    // }),
    // shelter: Yup.string().when("userType", {
    //   is: (val) => val === SHELTER_USER_TYPE,
    //   then: (schema) => schema.required(errors.required),
    // }),
    email: Yup.string().email(errors.email).required(errors.required),
    password: Yup.string().min(8, errors.min).required(errors.required),
    acceptedTerms: Yup.boolean()
      .required(errors.required)
      .oneOf([true], errors.terms),
  });

  const validationBreederSchema = Yup.object().shape({
    phone: Yup.string().phone().required(errors.required),
    // country: Yup.string(),
    address: Yup.object(),
    addressValue: Yup.string()
      .required(errors.required)
      .oneOf([Yup.ref("address.formatted_address")], errors.listValue),
    description: Yup.string().required(errors.required),
    breeds: Yup.array().of(Yup.object()).required(errors.required),
    startYear: Yup.number().required(errors.required).max(moment().format('YYYY'), errors.tooBigYear),
    isVerified: Yup.boolean().required(errors.required),
    siren: Yup.string().optional(),
    isBreedClub: Yup.boolean().required(errors.required),
    isGenetic: Yup.boolean().required(errors.required),
    lof: Yup.string()
      .oneOf([LOF_ALL_TYPE, LOF_SOME_TYPE, LOF_NO_TYPE])
      .required(errors.required),
    facebook: Yup.string().optional().matches(FACEBOOK_REGEXT_PATTERN, errors.notValidLinkData),
    instagram: Yup.string().optional().matches(INSTAGRAM_REGEXT_PATTERN, errors.notValidLinkData),
    whatsapp: Yup.string().optional().matches(WHATSAPP_REGEXT_PATTERN, errors.notValidWhatsAppData),
  });

  useEffect(() => {
    setAuthSwitch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (true) {
      case apiError === ALREADY_EXIST_ERR_CODE:
        setApiErrText(errors.alreadyExist);
        break;
      case apiError === TOO_MANY_REQUESTS_ERR_CODE:
        setApiErrText(errors.tooManyRequests);
        break;
      case apiError !== "":
        setApiErrText(errors.apiErr);
        break;
      default:
        setApiErrText("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiError]);

  function togglePasswordVisibility() {
    setPasswordVisible(!isPasswordVisible);
  }

  // function handleSirenChange(evt, setFieldValue, name) {
  //   evt.preventDefault();
  //   let value = evt.target.value.replace(/\D/g, "");
  //   let formattedValue = "";
  //   if (!value) {
  //     setFieldValue(name, "");
  //   } else {
  //     switch (true) {
  //       case value.length <= 3:
  //         formattedValue = value.substring(0);
  //         setFieldValue(name, formattedValue);
  //         break;
  //       case value.length <= 6:
  //         console.log(formattedValue);
  //         formattedValue = value.substring(0, 3) + " " + value.substring(3);
  //         setFieldValue(name, formattedValue);
  //         break;
  //       case value.length <= 9:
  //         formattedValue =
  //           value.substring(0, 3) +
  //           " " +
  //           value.substring(3, 6) +
  //           " " +
  //           value.substring(6);
  //         setFieldValue(name, formattedValue);
  //         break;
  //       case value.length <= 14:
  //         formattedValue =
  //           value.substring(0, 3) +
  //           " " +
  //           value.substring(3, 6) +
  //           " " +
  //           value.substring(6, 9) +
  //           " " +
  //           value.substring(9);
  //         setFieldValue(name, formattedValue);
  //         break;
  //       default:
  //         formattedValue =
  //           value.substring(0, 3) +
  //           " " +
  //           value.substring(3, 6) +
  //           " " +
  //           value.substring(6, 9) +
  //           " " +
  //           value.substring(9, 14);
  //         setFieldValue(name, formattedValue);
  //     }
  //   }
  // }

  function redirectAuth() {
    onAuthClose(REG_AUTH_TYPE);
    onAuthOpen(LOGIN_AUTH_TYPE);
    setAuthSwitch(true);
  }

  function closeAuth() {
    onAuthClose(REG_AUTH_TYPE);
  }

  function onCodeSubmit(values, setSubmitting) {
    checkEmailCode(values, setSubmitting, REG_AUTH_TYPE);
  }

  function onSubmitStepTwo(values, setSubmitting) {
    signupBreederStepTwo(values, setSubmitting, REG_AUTH_TYPE);
  }

  return (
    <Auth
      type={REG_AUTH_TYPE}
      bottomPattern={pattern}
      titleIcon={LikeIcon("auth__heart-icon", "auth__heart-icon-fill")}
      isBackBtnVisible={false}
      isCloseBtnVisible={true}
      onClose={closeAuth}
      onRedirect={redirectAuth}
      title={
        regStep === 0
          ? title
          : regStep === 1
            ? confirmEmail.title
            : breederTitle
      }
      subtitle={regStep === 1 ? confirmEmail.subtitle : undefined}
      {...{
        imageTitle,
        imageText,
        image,
        redirectText,
        redirectLink,
        isAuthOpen,
        isAuthSwitch,
        regStep,
        logout,
      }}
    >
      {regStep === 0 && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setUserType(values.userType);
            values.userType === BUYER_USER_TYPE
              ? signupBuyer(values, setSubmitting)
              : signupBreederStepOne(values, setSubmitting);
          }}
        >
          {({ isValid, dirty, isSubmitting, setFieldValue }) => (
            <Form className="auth__form auth__form_type_registration">
              <div className="auth__type-selector-block">
                <UserTypeSelector
                  id="userType"
                  name="userType"
                  {...{ setFieldValue }}
                />
              </div>

              <div className="auth__inputs-block">
                {/* {values.userType === BUYER_USER_TYPE ? ( */}
                <InputWithFormik
                  label={labels.name}
                  id="name"
                  name="name"
                  type="text"
                />
                {/* ) : values.userType === BREEDER_USER_TYPE ? (
                  <InputWithFormik
                    label={labels.siren}
                    id="siren"
                    name="siren"
                    type="text"
                    inputMode="numeric"
                    onChange={handleSirenChange}
                    {...{ setFieldValue }}
                  />
                ) : values.userType === SHELTER_USER_TYPE ? (
                  <InputWithFormik
                    label={labels.shelter}
                    id="shelter"
                    name="shelter"
                    type="text"
                  />
                ) : null} */}

                <InputWithFormik
                  label={labels.email}
                  id="email"
                  name="email"
                  type="email"
                />

                <InputWithFormik
                  label={labels.password}
                  labelSpan={`(${labels.minSymbols})`}
                  id="password"
                  name="password"
                  type={isPasswordVisible ? "text" : "password"}
                  onPasswordToggle={togglePasswordVisibility}
                  {...{ isPasswordVisible }}
                />
              </div>

              <div className="auth__checkbox-block">
                <CheckboxWithFormik
                  label={labels.termsCheckbox}
                  id="acceptedTerms"
                  name="acceptedTerms"
                  type={REG_AUTH_TYPE}
                />
              </div>

              <div className="auth__submit-btn-block">
                <AuthSubmitBtn
                  text={submitText}
                  apiError={apiErrText}
                  {...{ isValid, dirty, isSubmitting, isPrelouderVisible }}
                />
              </div>
            </Form>
          )}
        </Formik>
      )}

      {regStep === 1 && (
        <CheckEmailCode
          submitText={
            userType === BUYER_USER_TYPE
              ? confirmEmail.submitTextBuyer
              : confirmEmail.submitTextBreeder
          }
          onSubmit={onCodeSubmit}
          handleResendCode={handleResendCode}
          {...{ isPrelouderVisible, isResendPreloader, apiError }}
        />
      )}

      {regStep === 2 && (
        <ProfileInfo
          initialValues={initialBreederValues}
          validationSchema={validationBreederSchema}
          onSubmit={onSubmitStepTwo}
          submitText={breederSubmit}
          {...{
            language,
            addressHints,
            suggestAddress,
            isPrelouderVisible,
            setIsPrelouderVisible,
            setAddressHints,
            apiError,
          }}
        />
      )}
    </Auth>
  );
}

export default Registration;
