import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";
import "./AuthSubmitBtn.css";

function AuthSubmitBtn({
  text,
  isValid,
  dirty,
  isSubmitting,
  isPrelouderVisible,
  isEmailValid,
  apiError,
}) {
  const [isBtnDisabled, setIsBtnDisabled] = useState(false);

  useEffect(() => {
    setIsBtnDisabled(
      !(isValid && dirty) ||
        isSubmitting ||
        (isEmailValid !== undefined && !isEmailValid)
    );
  }, [isValid, dirty, isSubmitting, isEmailValid]);

  return (
    <>
      <motion.button
        className="auth-submit"
        type="submit"
        initial={{ background: "var(--disabled-btn-color)" }}
        animate={{
          background: isBtnDisabled
            ? "var(--disabled-btn-color)"
            : "var(--active-btn-color)",
        }}
        whileHover={{ background: "var(--hover-btn-color)" }}
        whileTap={{ background: "var(--tap-btn-color)" }}
        transition={{ duration: 0.2, ease: "easeInOut" }}
        style={{
          pointerEvents: isBtnDisabled ? "none" : "auto",
        }}
      >
        {!isPrelouderVisible ? (
          <motion.p
            className="auth-submit__btn-text"
            initial={{ opacity: 0.6 }}
            animate={{
              opacity: isBtnDisabled ? 0.6 : 1,
            }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            {text}
          </motion.p>
        ) : (
          <MiniPreloader />
        )}
      </motion.button>

      <motion.div
        className="auth-submit__error-box"
        initial={{
          height: "0px",
          marginTop: "0px",
          opacity: 0,
          visibility: "hidden",
        }}
        animate={{
          height: apiError ? "14px" : "0px",
          marginTop: apiError ? "6px" : "0px",
          opacity: apiError ? 1 : 0,
          visibility: apiError ? "visible" : "hidden",
        }}
        transition={{ duration: 0.15 }}
      >
        {apiError ? <p className="auth-submit__error">{apiError}</p> : null}
      </motion.div>
    </>
  );
}

export default AuthSubmitBtn;
