import { useState, useContext, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import validator from "validator";
import { TranslationContext } from "../../../assets/contexts/translationContext";
import Auth from "../Auth";
import UserTypeSelector from "../UserTypeSelector/UserTypeSelector";
import InputWithFormik from "../../InputWithFormik/InputWithFormik";
import AuthSubmitBtn from "../AuthSubmitBtn/AuthSubmitBtn";
import {
  REG_AUTH_TYPE,
  LOGIN_AUTH_TYPE,
  RECOVERY_AUTH_TYPE,
  BUYER_USER_TYPE,
  BREEDER_USER_TYPE,
  // SHELTER_USER_TYPE,
} from "../../../assets/utils/constants";
import bottomPattern from "../../../assets/images/auth/recovery-bottom-pattern.png";
import topPattern from "../../../assets/images/auth/recovery-top-pattern.png";
import image from "../../../assets/images/auth/recovery.png";
import imageMobile from "../../../assets/images/auth/recovery-mobile.png";
import "./Recovery.css";

function Recovery({
  isAuthOpen,
  onAuthClose,
  onAuthOpen,
  isAuthSwitch,
  setAuthSwitch,
  isUserExist,
  setIsUserExist,
  checkUserExistence,
  recoveryStep,
  forgotPasswordRecovery,
  isPrelouderVisible,
  apiError
}) {
  const { auth, errors, labels } = useContext(TranslationContext);
  const { recovery } = auth;
  const { title, subtitle, submitText, redirectText, redirectLink } = recovery;
  const [email, setEmail] = useState("");
  const [userType, setUserType] = useState(BUYER_USER_TYPE);
  const [apiErrText, setApiErrText] = useState("");

  useEffect(() => {
    setAuthSwitch(false);
    setIsUserExist(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (validator.isEmail(email))
        checkUserExistence({ email, user_type: userType });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, userType]);

  useEffect(() => {
    switch (true) {
      case apiError !== "":
        setApiErrText(errors.apiErr);
        break;
      default:
        setApiErrText("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiError]);

  function redirectAuth() {
    onAuthClose(RECOVERY_AUTH_TYPE);
    onAuthOpen(REG_AUTH_TYPE);
    setAuthSwitch(true);
  }

  function closeAuth() {
    onAuthClose(RECOVERY_AUTH_TYPE);
  }

  function handleBack() {
    onAuthClose(RECOVERY_AUTH_TYPE);
    onAuthOpen(LOGIN_AUTH_TYPE);
    setAuthSwitch(true);
  }

  function handleEmailChange(evt, setFieldValue) {
    const input = evt.target;
    const name = input.name;
    const value = input.value;
    setFieldValue(name, value);
    setEmail(value);
  }

  return (
    <Auth
      type={RECOVERY_AUTH_TYPE}
      isBackBtnVisible={recoveryStep === 0}
      isCloseBtnVisible={recoveryStep === 0}
      onClose={closeAuth}
      onRedirect={redirectAuth}
      onBack={handleBack}
      mobile={imageMobile}
      {...{
        bottomPattern,
        topPattern,
        image,
        title,
        subtitle,
        redirectText,
        redirectLink,
        recoveryStep,
        email,
        isAuthOpen,
        isAuthSwitch,
      }}
    >
      <Formik
        initialValues={{ userType: BUYER_USER_TYPE, email: "" }}
        validationSchema={Yup.object({
          userType: Yup.string()
            .oneOf([
              BUYER_USER_TYPE,
              BREEDER_USER_TYPE,
              // SHELTER_USER_TYPE
            ])
            .required(),
          email: Yup.string().email(errors.email).required(errors.required),
        })}
        onSubmit={(values, { setSubmitting }) => {
          forgotPasswordRecovery(values, setSubmitting);
          setEmail(values.email);
        }}
      >
        {({ values, isValid, dirty, isSubmitting, setFieldValue }) => (
          <Form className="auth__form auth__form_type_recovery">
            <div className="auth__type-selector-block">
              <UserTypeSelector
                id="userType"
                name="userType"
                {...{ setFieldValue, setUserType }}
              />
            </div>

            <div className="auth__inputs-block">
              <InputWithFormik
                label={labels.email}
                id="email"
                name="email"
                type="email"
                onChange={handleEmailChange}
                {...{ isUserExist, values, setFieldValue }}
              />
            </div>

            <div className="auth__submit-btn-block">
              <AuthSubmitBtn
                text={submitText}
                isEmailValid={isUserExist}
                apiError={apiErrText}
                {...{ isValid, dirty, isSubmitting, isPrelouderVisible }}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Auth>
  );
}

export default Recovery;
