import { useEffect, useState, useRef, useContext } from "react";
import { motion } from "framer-motion";
import { FixedSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import Balancer from "react-wrap-balancer";
import { TranslationContext } from "../../assets/contexts/translationContext";
import { CurrentUserContext } from "../../assets/contexts/currentUserContext";
import { SimpleArrowIcon, CheckIcon } from "../../assets/icons/icons";
import "./SelectInput.css";
import { storage } from "../../assets/utils/utils";

const arrowVariants = {
  open: {
    rotate: -90,
    transition: { duration: 0.15 },
  },
  closed: {
    rotate: 90,
    transition: { duration: 0.15 },
  },
};

function SelectInput({
  language,
  selectList,
  onSelect,
  selectedBreed,
  ...props
}) {
  const overlay = useRef();
  const user = useContext(CurrentUserContext);
  const { filtersBlock, breeders } = useContext(TranslationContext);
  const { searchNothing, placeholder } = filtersBlock;
  const [isSelectDropdownOpen, setSelectDropdownOpen] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [filteredList, setFilteredList] = useState([]);

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        closeSelectDropdown();
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });

  useEffect(() => {
    setFilteredList(selectList);
  }, [selectList]);

  function openSelectDropdown() {
    setSelectDropdownOpen(true);
  }

  function closeSelectDropdown() {
    setSelectDropdownOpen(false);
    setSearchKey("");
  }

  function handleSelect(value) {
    onSelect(value);
    closeSelectDropdown();
    setSearchKey("");
    saveScroll()
  }

  function handleSearchKeyChange(evt) {
    const value = evt.target.value;
    setSearchKey(value);
    setFilteredList(
      selectList.filter((item) =>
        item.title[user ? user.language : language.toLowerCase()]
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );
  }

  function Row({ style, index }) {
    const item = props.isNonEmpty
      ? filteredList[index]
      : filteredList[index - 1];
    return index === 0 && !props.isNonEmpty ? (
      <div
        className={`select__item ${!selectedBreed ? "select__item_selected" : ""
          }`}
        key="any-breed"
        style={style}
        onClick={() => handleSelect(undefined)}
      >
        <p className="select__dropdown-text">{breeders.any}</p>
        <div className="select__select-icon-box">
          {CheckIcon("select__select-icon", "select__select-icon-stroke")}
        </div>
      </div>
    ) : (
      <div
        style={style}
        className={`select__item ${selectedBreed && item.value === selectedBreed.value
            ? "select__item_selected"
            : ""
          }`}
        key={item.value}
        onClick={() => handleSelect(item)}
      >
        <p className="select__dropdown-text">
          <Balancer>
            {item.title[user ? user.language : language.toLowerCase()]}
          </Balancer>
        </p>
        <div className="select__select-icon-box">
          {CheckIcon("select__select-icon", "select__select-icon-stroke")}
        </div>
      </div>
    );
  }
  const [scrollNav, setScrollNav] = useState(false);

  function saveScroll() {
    storage.saveCurrentScroll(window.scrollY)
    setScrollNav(true)
  }
  useEffect(() => {
    if (scrollNav) {
      window.scrollTo(0, storage.getCurrentScroll())
    }
    setScrollNav(false)
  }, [scrollNav])

  function RowSecondary({ style, index }) {
    const item = selectList[index];
    return (
      <div
        style={style}
        className={`select__item ${item === props.value ? "select__item_selected" : ""
          }`}
        key={`${props.name}-${index}`}
        onClick={() => handleSelect(item)}
      >
        <p className="select__dropdown-text">
          <Balancer>{item}</Balancer>
        </p>
        <div className="select__select-icon-box">
          {CheckIcon("select__select-icon", "select__select-icon-stroke")}
        </div>
      </div>
    );
  }

  return (
    <motion.div
      className="select"
      initial={false}
      animate={isSelectDropdownOpen ? "open" : "closed"}
    >
      <div
        className={`select__input-box ${props.readOnly ? "select__input-box_disabled" : ""
          } ${props.isGrey ? "select__input-box_type_color" : ""}`}
        onClick={() => setSelectDropdownOpen(!isSelectDropdownOpen)}
      >
        <label className="select__label" htmlFor={props.name}>
          {props.label}
        </label>
        <input
          className={`select__input ${isSelectDropdownOpen ? "select__input_focused" : ""
            }`}
          id={props.name}
          name={props.name}
          type="text"
          autoComplete="off"
          value={
            props.value
              ? props.value
              : selectedBreed
                ? selectedBreed.title[
                user ? user.language : language.toLowerCase()
                ]
                : !props.isNonEmpty
                  ? breeders.any
                  : ""
          }
          readOnly
        />
        {!props.readOnly ? (
          <motion.div className="select__arrow-box" variants={arrowVariants}>
            {SimpleArrowIcon("select__arrow", "select__arrow-stroke")}
          </motion.div>
        ) : null}
      </div>

      {selectList && selectList.length > 0 ? (
        <div
          className={`select__dropdown ${isSelectDropdownOpen ? "select__dropdown_opened" : ""
            }`}
        >
          <div
            className="select__dropdown-container"
            style={{
              pointerEvents: isSelectDropdownOpen ? "auto" : "none",
            }}
          >
            {props.name.toLowerCase().includes("breed") ? (
              <div className="select__input-box">
                <input
                  className="select__input select__input_type_search"
                  id="search"
                  name="search"
                  type="search"
                  placeholder={placeholder.search}
                  autoComplete="off"
                  value={searchKey || ""}
                  onChange={handleSearchKeyChange}
                />
              </div>
            ) : null}
            <ul
              className={`select__list ${props.name.toLowerCase().includes("breed")
                  ? "select__list_type_breed"
                  : ""
                } ${props.isPopup ? "select__list_type_popup" : ""}`}
            >
              {props.name.toLowerCase().includes("breed") ? (
                filteredList.length > 0 ? (
                  <AutoSizer>
                    {({ height, width }) => (
                      <List
                        key={`select-list-${searchKey}`}
                        height={height}
                        itemCount={
                          props.isNonEmpty
                            ? filteredList.length
                            : filteredList.length + 1
                        }
                        itemSize={40}
                        width={width - 4}
                      >
                        {Row}
                      </List>
                    )}
                  </AutoSizer>
                ) : (
                  <li className="filter__radio-item" key="nothing_found">
                    <p className="filters__radio-label">
                      <Balancer>{searchNothing}</Balancer>
                    </p>
                  </li>
                )
              ) : selectList ? (
                <AutoSizer>
                  {({ height, width }) => (
                    <List
                      key={`select-list`}
                      height={height}
                      itemCount={selectList.length}
                      itemSize={40}
                      width={width - 4}
                    >
                      {RowSecondary}
                    </List>
                  )}
                </AutoSizer>
              ) : null}
            </ul>
          </div>

          <div
            className="select__dropdown-overlay"
            ref={overlay}
          //onTouchStart={closeSelectDropdown}
          />
        </div>
      ) : null}
    </motion.div>
  );
}

export default SelectInput;
