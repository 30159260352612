import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Balancer from "react-wrap-balancer";
import { TranslationContext } from "../../assets/contexts/translationContext";
import { CurrentUserContext } from "../../assets/contexts/currentUserContext";
import { SubcategoryContext } from "../../assets/contexts/subcategoryContext";
import {
  LogoIcon,
  InstagramIcon,
  FacebookIcon,
  LinkedinIcon,
  RighArrowtIcon,
  FooterBigShape,
  FooterSmallShape,
  CheckIcon,
  SimpleArrowIcon,
} from "../../assets/icons/icons";
import { BREED_FILTER, DROPDOWN_LANG, EN_LANGUAGE, FR_LANGUAGE, LANGUAGES } from "../../assets/utils/constants";
import dog from "../../assets/images/footer/dog.png";
import { motion } from "framer-motion";
import "./Footer.css";
import DropdownPopup from "../Header/DropdownPopup/DropdownPopup";

import privacy_policy from '../../assets/docs/privacy_policy.pdf'
import service from '../../assets/docs/service.pdf'
import sales from '../../assets/docs/sales.pdf'
import cookies from '../../assets/docs/cookies.pdf'

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
};

const arrowVariants = {
  open: {
    rotate: 180,
    transition: {
      duration: 0.2,
    },
  },
  closed: {
    rotate: 0,
    transition: {
      duration: 0.2,
    },
  },
};

const dropdownVariants = {
  open: {
    clipPath: "inset(0% 0% 0% 0% round 12px)",
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.5,
      delayChildren: 0.2,
      staggerChildren: 0.05,
    },
  },
  closed: {
    clipPath: "inset(10% 90% 90% 10% round 12px)",
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.3,
    },
  },
};


function Footer({ language, setLanguage }) {
  const { footer } = useContext(TranslationContext);
  const { races, breeders, utile, indispensable, info, copyright } = footer;
  const user = useContext(CurrentUserContext);
  const subcategory = useContext(SubcategoryContext);
  const [topBreeds, setTopBreeds] = useState([]);

  useEffect(() => {
    if (!subcategory) return;

    const breedsArr = subcategory.filters.find(
      (item) => item.value === BREED_FILTER
    ).options;
    const top = breedsArr
      .sort((x, y) => x.top_position - y.top_position)
      .slice(0, 10);
    setTopBreeds(top);
  }, [subcategory]);

  const [isDropdownOpen, setDropdownOpen] = useState({});


  function selectLanguage(lang) {
    setLanguage(lang);
    localStorage.setItem("language", lang);
    if (isDropdownOpen[DROPDOWN_LANG]) closeDropdown(DROPDOWN_LANG);
  }

  function openDropdown(type) {
    console.log(type)
    setDropdownOpen((prevObj) => ({ ...prevObj, [type]: true }));
  }

  function closeDropdown(type) {
    setDropdownOpen((prevObj) => ({ ...prevObj, [type]: false }));
  }

  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__main-info-block">
          <div className="footer__logo-block">
            <Link className="footer__logo-link" to="/">
              {LogoIcon(
                "footer__logo",
                "footer__logo-main-fill",
                "footer__logo-stroke",
                "footer__logo-secondary-fill"
              )}
            </Link>
            <p className="footer__text footer__text_type_copyright">
              {copyright}
            </p>
            {/* <motion.div
              className="header__box-with-dropdown header__box-with-dropdown_type_lang footer__lang"
              initial={false}
              animate={isDropdownOpen[DROPDOWN_LANG] ? "open" : "closed"}
            >
              <button
                className="header__dropdown-btn header__dropdown-btn_type_lang"
                type="button"
                onClick={() => openDropdown(DROPDOWN_LANG)}
              >
                <div className="header__icon-box header__icon-box_type_lang">
                  {LANGUAGES[language].flag}
                </div>
                <p className="header__text">{LANGUAGES[language].lang}</p>
                <motion.div
                  className="header__icon-box"
                  variants={arrowVariants}
                >
                  {SimpleArrowIcon(
                    "header__arrow-icon",
                    "header__arrow-icon-stroke"
                  )}
                </motion.div>
              </button>
              <DropdownPopup
                isOpen={isDropdownOpen[DROPDOWN_LANG]}
                onClose={closeDropdown}
                type={DROPDOWN_LANG}
                {...{ language }}
              >
                <motion.ul
                  className="header__dropdown-list header__dropdown-list_type_lang"
                  variants={dropdownVariants}
                  style={{
                    pointerEvents: isDropdownOpen[DROPDOWN_LANG]
                      ? "auto"
                      : "none",
                  }}
                >
                  <motion.li
                    className="header__dropdown-item"
                    variants={itemVariants}
                  >
                    <button
                      className={`header__lang-btn ${language === FR_LANGUAGE
                        ? "header__lang-btn_selected"
                        : ""
                        }`}
                      // style={{ opacity: 0.4, pointerEvents: "none" }}
                      type="button"
                      onClick={() => selectLanguage(FR_LANGUAGE)}
                    >
                    
                      <p className="header__text header__text_type_lang">
                        {LANGUAGES[FR_LANGUAGE].lang}
                      </p>
                      <div className="header__icon-box header__icon-box_type_check">
                        {CheckIcon(
                          "header__check-icon",
                          "header__check-icon-stroke"
                        )}
                      </div>
                    </button>
                  </motion.li>
                  <div className="header__divider-line" />
                  <motion.li
                    className="header__dropdown-item"
                    variants={itemVariants}
                  >
                    <button
                      className={`header__lang-btn ${language === EN_LANGUAGE
                        ? "header__lang-btn_selected"
                        : ""
                        }`}
                      type="button"
                      onClick={() => selectLanguage(EN_LANGUAGE)}
                    >
                      
                      <p className="header__text header__text_type_lang">
                        {LANGUAGES[EN_LANGUAGE].lang}
                      </p>
                      <div className="header__icon-box header__icon-box_type_check">
                        {CheckIcon(
                          "header__check-icon",
                          "header__check-icon-stroke"
                        )}
                      </div>
                    </button>
                  </motion.li>
                </motion.ul>
              </DropdownPopup>
            </motion.div> */}
          </div>

          <div className="footer__main-links-block">
            <div className="footer__main-links-box footer__main-links-box_type_races">
              <h4 className="footer__links-box-title">{races.title}</h4>
              <ul className="footer__main-links-list">
                {topBreeds.slice(0, 9).map((breed) => (
                  <li className="footer__link-item" key={breed.value}>
                    <Link className="footer__link" to={`/catalog/pets/dogs/${breed.value}`}>
                      <h5 className="footer__text">
                        <Balancer>
                          {
                            breed.title[
                            user ? user.language : language.toLowerCase()
                            ]
                          }
                        </Balancer>
                      </h5>
                    </Link>
                  </li>
                ))}
                <li className="footer__link-item" key={races.all}>
                  <Link className="footer__link" to="/catalog/pets/dogs">
                    <p className="footer__text footer__text_type_all">
                      {races.all}
                    </p>
                    <div className="footer__arrow-box">
                      {RighArrowtIcon(
                        "footer__arrow-icon",
                        "footer__arrow-icon-stroke"
                      )}
                    </div>
                  </Link>
                </li>
              </ul>
            </div>

            <div className="footer__main-links-box footer__main-links-box_type_breeders">
              <h4 className="footer__links-box-title">{breeders.title}</h4>
              <ul className="footer__main-links-list">
                {topBreeds.map((breed) => (
                  <li className="footer__link-item" key={breed.value}>
                    <Link
                      className="footer__link"
                      to={`/breeders/pets/dogs/${breed.value}`}
                    >
                      <p className="footer__text">
                        <span className="footer__text footer__text_type_hidden">
                          {breeders.breeding}{" "}
                        </span>
                        <Balancer>
                          {
                            breed.title[
                            user ? user.language : language.toLowerCase()
                            ]
                          }
                        </Balancer>
                      </p>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

            <div className="footer__main-links-box footer__main-links-box_type_utile">
              <h4 className="footer__links-box-title">{utile.title}</h4>
              <ul className="footer__main-links-list">
                <li className="footer__link-item">
                  <Link className="footer__link" to="/blog">
                    <p className="footer__text">
                      <Balancer>{utile.blog}</Balancer>
                    </p>
                  </Link>
                </li>
                {/* <li className="footer__link-item">
                  <Link className="footer__link" to="#">
                    <p className="footer__text">
                      <Balancer>{utile.contact}</Balancer>
                    </p>
                  </Link>
                </li> */}
                {/* <li className="footer__link-item">
                  <Link className="footer__link" to="/forum">
                    <p className="footer__text">
                      <Balancer>{utile.forum}</Balancer>
                    </p>
                  </Link>
                </li>
                <li className="footer__link-item">
                  <Link className="footer__link" to="#">
                    <p className="footer__text">
                      <Balancer>{utile.cgv}</Balancer>
                    </p>
                  </Link>
                </li> */}
              </ul>
            </div>

            <div className="footer__main-links-box footer__main-links-box_type_indispensable">
              <h4 className="footer__links-box-title">{indispensable.title}</h4>
              <ul className="footer__main-links-list">
                <li className="footer__link-item">
                  <Link className="footer__link" to="/about-us">
                    <p className="footer__text">
                      <Balancer>{indispensable.whoWeAre}</Balancer>
                    </p>
                  </Link>
                </li>
                {/* <li className="footer__link-item">
                  <Link className="footer__link" to="#">
                    <p className="footer__text">
                      <Balancer>{indispensable.breeder}</Balancer>
                    </p>
                  </Link>
                </li> */}
              </ul>
            </div>

            <div className="footer__main-links-box footer__main-links-box_type_social">
              <ul className="footer__social-links-list">
                <li className="footer__link-item">
                  <a className="footer__social-link" href="https://instagram.com/pet4me.fr" target="_blank" rel="noreferrer">
                    {InstagramIcon(
                      "footer__social-icon",
                      "footer__social-icon-fill"
                    )}
                  </a>
                </li>
                <li className="footer__link-item">
                  <a className="footer__social-link" href="https://www.facebook.com/profile.php?id=100088082196933" target="_blank" rel="noreferrer">
                    {FacebookIcon(
                      "footer__social-icon",
                      "footer__social-icon-fill"
                    )}
                  </a>
                </li>
                <li className="footer__link-item">
                  <a className="footer__social-link" href="https://www.linkedin.com/company/pet4me/about/" target="_blank" rel="noreferrer">
                    {LinkedinIcon(
                      "footer__social-icon",
                      "footer__social-icon-fill"
                    )}
                  </a>
                </li>
              </ul>
            </div>

            <div className="footer__main-links-box footer__main-links-box_type_info">
              <p className="footer__links-box-title">{info.title}</p>
              <ul className="footer__main-links-list">
                <li className="footer__link-item">
                  <a className="footer__link" href={privacy_policy} target="_blank" rel="noreferrer">
                    <p className="footer__text footer__text_type_info">
                      <Balancer>{info.privacyPolicy}</Balancer>
                    </p>
                  </a>
                </li>
                <li className="footer__link-item">
                  <a className="footer__link" href={service} target="_blank" rel="noreferrer">
                    <p className="footer__text footer__text_type_info">
                      <Balancer>{info.service}</Balancer>
                    </p>
                  </a>
                </li>
                <li className="footer__link-item">
                  <a className="footer__link" href={sales} target="_blank" rel="noreferrer">
                    <p className="footer__text footer__text_type_info">
                      <Balancer>{info.sales}</Balancer>
                    </p>
                  </a>
                </li>
                <li className="footer__link-item">
                  <a className="footer__link" href={cookies} target="_blank" rel="noreferrer">
                    <p className="footer__text footer__text_type_info">
                      <Balancer>{info.cookies}</Balancer>
                    </p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <img className="footer__decor-img" src={dog} alt="" />
        </div>
        <p className="footer__text footer__text_type_copyright footer__text_type_mobile">
          {copyright}
        </p>

        {FooterBigShape(
          "footer__big-shape footer__big-shape_type_left",
          "footer__big-shape-fill"
        )}
        {FooterBigShape(
          "footer__big-shape footer__big-shape_type_right",
          "footer__big-shape-fill"
        )}
        {FooterSmallShape(
          "footer__small-shape footer__small-shape_type_left",
          "footer__small-shape-fill"
        )}
        {FooterSmallShape(
          "footer__small-shape footer__small-shape_type_right",
          "footer__small-shape-fill"
        )}
        {FooterSmallShape(
          "footer__small-shape footer__small-shape_type_mobile",
          "footer__small-shape-fill"
        )}
      </div>
    </footer>
  );
}

export default Footer;
