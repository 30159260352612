import { useField } from "formik";
import { motion } from "framer-motion";
import "./ToggleBoxWithFormik.css";

function ToggleBoxWithFormik({
  label,
  type,
  setFieldValue,
  onChange,
  ...props
}) {
  const [field] = useField({ ...props, type: "checkbox" });

  return (
    <div
      className={`formik-toggle ${
        Boolean(type) ? `formik-toggle_type_${type}` : ""
      }`}
    >
      <span className="formik-toggle__label">{label}</span>
      <label className="formik-toggle__box">
        <input
          className="formik-toggle__invisible-input"
          type="checkbox"
          {...field}
          {...props}
          onChange={(evt) =>
            onChange ? onChange(evt, setFieldValue) : field.onChange(evt)
          }
        />
        <motion.span
          className={`formik-toggle__pseudo-item ${
            field.checked ? "formik-toggle__pseudo-item_checked" : ""
          }`}
          initial={{ background: "var(--input-bg-color)" }}
          animate={{
            background: field.checked
              ? "var(--pale-lilac-color)"
              : "var(--input-bg-color)",
          }}
          transition={{ duration: 0.2, ease: "easeInOut" }}
        >
          <motion.div
            className="formik-toggle__circle"
            initial={{ background: "var(--text-color)" }}
            animate={{
              x: field.checked ? "28px" : "0px",
              background: field.checked
                ? "var(--bright-color)"
                : "var(--text-color)",
            }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          />
        </motion.span>
      </label>
    </div>
  );
}

export default ToggleBoxWithFormik;
