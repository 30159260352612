import moment from "moment-timezone";
import { FACEBOOK_REGEXT_PATTERN, INSTAGRAM_REGEXT_PATTERN, LOF_NO_TYPE, MAIN_URL, WHATSAPP_REGEXT_PATTERN } from "../utils/constants";
import { STRING_OR_NULL, getCookie } from "../utils/utils";

class MainApi {
  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        return Promise.reject({
          statusCode: statusCode,
          message: res.msg,
          detail: res.detail,
        });
      });
    }
  }

  _checkResponseWithCookies({ res, func, params }) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        if (statusCode === 422 && res.detail === "Signature has expired") {
          console.log("ss");
          return mainApi
            .refreshJWT()
            .then((res) => {
              return func(params);
            })
            .catch((err) => {
              return Promise.reject(err);
            });
        } else {
          return Promise.reject({
            statusCode: statusCode,
            message: res.msg,
            detail: res.detail,
          });
        }
      });
    }
  }

  refreshJWT() {
    return fetch(`${MAIN_URL}/auth/refresh-jwt`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(this._checkResponse);
  }

  signupBuyer({ name, email, password, language = "en" }) {
    return fetch(`${MAIN_URL}/auth/register/users/signup`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email, password, language }),
    }).then(this._checkResponse);
  }

  setNewLanguage({ language }) {
    return fetch(`${MAIN_URL}/auth/set-new-language`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ language: language.toLowerCase() }),
    }).then(this._checkResponse);
  }

  signupBreederStepOne({ name, email, password, language = "en" }) {
    return fetch(`${MAIN_URL}/auth/register/breeders/signup`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email, password, language }),
    }).then(this._checkResponse);
  }

  signupBreederStepTwo({ values, category, subcategory }) {
    let wpMatch = values.whatsapp.match(WHATSAPP_REGEXT_PATTERN);
    let instMatch = values.instagram.match(INSTAGRAM_REGEXT_PATTERN);
    let fbMatch = values.facebook.match(FACEBOOK_REGEXT_PATTERN);
    let wpValue = wpMatch ? wpMatch.slice(1).find(v => v !== undefined) : null;
    let instValue = instMatch ? instMatch.slice(1).find(v => v !== undefined) : null;
    let fbValue = fbMatch ? fbMatch.slice(1).find(v => v !== undefined) : null;
    return fetch(`${MAIN_URL}/auth/register/breeders/signup-part-two`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        phone: values.phone.replace(/\D/g, ""),
        place: {
          key: "place_id",
          value: values.address.place_id,
        },
        profile_data: {
          description: values.description,
          breeds: values.breeds.map((breed) => ({
            category: { key: "type", value: category },
            sub_category: { key: "type", value: subcategory },
            breed: breed.value,
          })),
          experience_from: moment(values.startYear, "YYYY").format(
            "YYYY-MM-DD"
          ),
          has_pension_service: values.isVerified,
          is_in_breed_club: values.isBreedClub,
          has_genetic_tests: values.isGenetic,
          siren_number: values.siren,
          lof: values.lof === LOF_NO_TYPE ? null : values.lof.toLowerCase(),
          social: {
            whatsapp: wpMatch && wpValue ? wpValue : null,
            facebook: fbMatch && fbValue ? fbValue : null,
            instagram: instMatch && instValue ? instValue : null,
          },
        },
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.signupBreederStepTwo,
        params: { values, category, subcategory },
      })
    );
  }

  checkCode({ code }) {
    return fetch(`${MAIN_URL}/auth/change-email/check-code`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.checkCode,
        params: { code },
      })
    );
  }

  login({ user_type, email, password, rememberMe }) {

    return fetch(`${MAIN_URL}/auth/login?`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "remember-me": rememberMe,
      },
      body: JSON.stringify({ user_type, email, password }),
    }).then(this._checkResponse);
  }

  logout() {
    return fetch(`${MAIN_URL}/auth/logout`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.logout,
        params: {},
      })
    );
  }

  checkUserExistence({ email, user_type }) {
    let params = {};
    if (email) params.email = email;
    if (user_type) params.user_type = user_type;

    return fetch(
      `${MAIN_URL}/auth/validation-existence-basic?` +
      new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(this._checkResponse);
  }

  forgotPasswordWithoutAuth({ email, user_type }) {
    return fetch(`${MAIN_URL}/auth/forgot-password/main-not-logged-in`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, user_type }),
    }).then(this._checkResponse);
  }

  resetPassword({ email_token, password }) {
    return fetch(`${MAIN_URL}/auth/forgot-password/change`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email_token, password }),
    }).then(this._checkResponse);
  }

  getUser() {
   
    return fetch(`${MAIN_URL}/auth/get-me`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getUser,
        params: {},
      })
    );
  }

  getManyUsers({ id }) {
    let params = {};
    if (id)
      params.data = JSON.stringify({
        users: { ids: [id] },
        breeders: { ids: [id] },
      });

    return fetch(`${MAIN_URL}/auth/get-many?` + new URLSearchParams(params), {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getManyUsers,
        params: { id },
      })
    );
  }

  editBuyerData({ avatar, name, phone, new_messages, news_and_promotions }) {
    return fetch(`${MAIN_URL}/auth/register/users/edit-personal-data`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        avatar,
        name,
        phone: phone ? phone.replace(/\D/g, "") : null,
        notifications: {
          email: { new_messages, news_and_promotions },
        },
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editBuyerData,
        params: { avatar, name, phone, new_messages, news_and_promotions },
      })
    );
  }

  editBreederData({
    avatar,
    name,
    phone,
    address,
    description,
    breeds,
    category,
    subcategory,
    experience_from,
    has_pension_service,
    is_in_breed_club,
    has_genetic_tests,
    siren_number,
    lof,
    whatsapp,
    facebook,
    instagram,
    new_messages,
    news_and_promotions,
  }) {
    console.log('editBreederData')
    return fetch(`${MAIN_URL}/auth/register/breeders/edit-personal-data`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        avatar,
        name,
        phone: phone.replace(/\D/g, ""),
        place: {
          key: "place_id",
          value: address.place_id,
        },
        profile_data: {
          description,
          breeds: breeds.map((breed) => ({
            category: { key: "type", value: category },
            sub_category: { key: "type", value: subcategory },
            breed: breed.value,
          })),
          experience_from: moment(experience_from, "YYYY").format("YYYY-MM-DD"),
          has_pension_service,
          is_in_breed_club,
          has_genetic_tests,
          siren_number,
          lof: lof === LOF_NO_TYPE ? null : lof.toLowerCase(),
          social: {
            whatsapp: whatsapp ? whatsapp : null,
            facebook: facebook ? facebook : null,
            instagram: instagram ? instagram : null,
          },
        },
        notifications: {
          email: {
            new_messages,
            news_and_promotions,
          },
        },
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editBreederData,
        params: {
          avatar,
          name,
          phone,
          address,
          description,
          breeds,
          category,
          subcategory,
          experience_from,
          has_pension_service,
          is_in_breed_club,
          has_genetic_tests,
          siren_number,
          lof,
          whatsapp,
          facebook,
          instagram,
          new_messages,
          news_and_promotions,
        },
      })
    );
  }

  changeEmail({ email }) {
    return fetch(`${MAIN_URL}/auth/change-email/main`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.changeEmail,
        params: { email },
      })
    );
  }

  checkPassword({ current_password }) {
    return fetch(`${MAIN_URL}/auth/password/check`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ current_password }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.checkPassword,
        params: { current_password },
      })
    );
  }

  changePassword({ current_password, new_password }) {
    return fetch(`${MAIN_URL}/auth/password/update`, {
      method: "PATCH",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ current_password, new_password }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.changePassword,
        params: { current_password, new_password },
      })
    );
  }

  suggestAddress({ searchKey, types }) {
    let params = {};
    if (searchKey)
      params.data = JSON.stringify({ text: searchKey, types: [types] });

    return fetch(
      `${MAIN_URL}/geo-api/suggest-address?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(this._checkResponse);
  }

  getPlace({ place_id }) {
    let params = {};
    if (place_id) params.place_id = place_id;

    return fetch(
      `${MAIN_URL}/geo-api/get-place?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(this._checkResponse);
  }

  getLocation({ longitude, latitude }) {
    let params = {};
    if (longitude) params.longitude = longitude;
    if (latitude) params.latitude = latitude;

    return fetch(
      `${MAIN_URL}/geo-api/reverse-geocode?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(this._checkResponse);
  }

  getCategories({ limit = 1, last_id = "" }) {
    let params = {};
    if (limit) params.limit = limit;
    if (last_id) params.last_id = last_id;

    return fetch(
      `${MAIN_URL}/categories/get-all?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(this._checkResponse);
  }

  getAllSubcategories({ category, limit = 3, last_id = "" }) {
    let params = {};
    if (category)
      params.data = JSON.stringify({
        category: { key: "type", value: category },
        last_id: last_id ? last_id : null,
        limit,
      });

    return fetch(
      `${MAIN_URL}/sub-categories/get-all?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(this._checkResponse);
  }

  getExactSubcategories({ category, subcategory }) {
    let params = {};
    if (category && subcategory)
      params.data = JSON.stringify({
        key: "type",
        value: subcategory,
        category: { key: "type", value: category },
      });

    return fetch(
      `${MAIN_URL}/sub-categories/get-exact?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(this._checkResponse);
  }

  getItem({ data }) {
    let params = {};
    if (data) params.data = JSON.stringify(data);

    return fetch(`${MAIN_URL}/items/get-exact?` + new URLSearchParams(params), {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getItem,
        params: { data },
      })
    );
  }

  getAllArticles({ last_id, limit }) {
    let params = {};
    if (last_id) params.last_id = last_id;
    if (limit) params.limit = limit;

    return fetch(`${MAIN_URL}/blog/get-all?` + new URLSearchParams(params), {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getAllArticles,
        params: { last_id, limit },
      })
    );
  }

  getArticle({ value }) {
    let params = {};
    params.key = "_id";
    if (value) params.value = value;

    return fetch(`${MAIN_URL}/blog/get-exact?` + new URLSearchParams(params), {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(this._checkResponse);
  }

  setLike({ prior_id, collection, child_id }) {
    return fetch(`${MAIN_URL}/likes/add`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        prior_id,
        collection,
        child_id: child_id ? child_id : null,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.setLike,
        params: { prior_id, collection, child_id },
      })
    );
  }

  deleteLike({ prior_id, collection, child_id }) {
    let params = {};
    if (prior_id) params.prior_id = prior_id;
    if (collection) params.collection = collection;
    if (child_id) params.child_id = child_id;

    return fetch(`${MAIN_URL}/likes/delete?` + new URLSearchParams(params), {
      method: "DELETE",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.deleteLike,
        params: { prior_id, collection, child_id },
      })
    );
  }

  getBreeders({
    is_verified,
    category,
    sub_category,
    selectedBreed,
    selectedCity,
    limit,
    last_id,
    exclude_ids,
    min_distance,
  }) {
    let params = {};
    params.data = JSON.stringify({
      is_verified,
      breed: selectedBreed
        ? {
          category: { key: "type", value: category },
          sub_category: { key: "type", value: sub_category },
          breed: selectedBreed.value,
        }
        : null,
      location: selectedCity
        ? {
          longitude: selectedCity.mongo.coordinates[0],
          latitude: selectedCity.mongo.coordinates[1],
          distance: 150000,
          min_distance: min_distance ? min_distance : 0,
          exclude_ids: exclude_ids ? exclude_ids : [],
        }
        : null,
      last_id: last_id && !selectedCity ? last_id : null,
      limit: limit ? limit : null,
    });

    return fetch(
      `${MAIN_URL}/breeders/get-all?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getBreeders,
        params: {
          category,
          sub_category,
          selectedBreed,
          selectedCity,
          limit,
          last_id,
        },
      })
    );
  }

  getLikedBreeders({ last_id, limit }) {
    let params = {};
    if (last_id) params.last_id = last_id;
    if (limit) params.limit = limit;

    return fetch(
      `${MAIN_URL}/likes/all/breeders?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getLikedBreeders,
        params: { last_id, limit },
      })
    );
  }

  getLikedItems({ last_id, limit }) {
    let params = {};
    if (last_id) params.last_id = last_id;
    if (limit) params.limit = limit;

    return fetch(`${MAIN_URL}/likes/all/items?` + new URLSearchParams(params), {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getLikedItems,
        params: { last_id, limit },
      })
    );
  }

  getMyAdverts({ last_id, last_update, limit, collection }) {
    let params = {};
    if (last_id) params.last_id = last_id;
    if (last_update) params.last_update = last_update;
    if (limit) params.limit = limit;
    if (collection) params.collection = collection;

    return fetch(
      `${MAIN_URL}/items/auth/get-all?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getMyAdverts,
        params: { last_id, last_update, limit, collection },
      })
    );
  }

  getAllItems({ category, sub_category, sort_type, page, filters }) {
    let params = {};
    params.data = JSON.stringify({
      category: { key: "type", value: category },
      sub_category: { key: "type", value: sub_category },
      page,
      sort_type,
      filters,
    });

    return fetch(`${MAIN_URL}/items/get-all?` + new URLSearchParams(params), {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getAllItems,
        params: { category, sub_category, sort_type, page, filters },
      })
    );
  }

  setEmailNotification({ category, sub_category, breed }) {
    return fetch(`${MAIN_URL}/sub-categories/email-notification`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        key: "type",
        value: sub_category,
        category: {
          key: "type",
          value: category,
        },
        breed,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.setEmailNotification,
        params: { category, sub_category, breed },
      })
    );
  }

  setBreedRequest({
    category,
    sub_category,
    breed,
    email,
    name,
    phone,
    place,
    extra,
  }) {
    return fetch(`${MAIN_URL}/sub-categories/breed-request`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        key: "type",
        value: sub_category,
        category: {
          key: "type",
          value: category,
        },
        name,
        phone,
        breed,
        place: place
          ? {
            key: "place_id",
            value: place,
          }
          : null,
        extra,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.setBreedRequest,
        params: {
          category,
          sub_category,
          breed,
          email,
          name,
          phone,
          place,
          extra,
        },
      })
    );
  }

  getChats({ limit, last_id, last_utc_datetime }) {
    let params = {};
    if (limit) params.limit = limit;
    if (last_id) params.last_id = last_id;
    if (last_utc_datetime) params.last_utc_datetime = last_utc_datetime;

    return fetch(
      `${MAIN_URL}/messages/get-all-chats?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getChats,
        params: { limit, last_id, last_utc_datetime },
      })
    );
  }

  getMessages({ limit, last_id, user_id, tz }) {
    let params = {};
    if (limit) params.limit = limit;
    if (last_id) params.last_id = last_id;
    if (user_id) params.user_id = user_id;
    if (tz) params.tz = tz;

    return fetch(
      `${MAIN_URL}/messages/get-all-messages?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getMessages,
        params: { limit, last_id, user_id, tz },
      })
    );
  }

  setMessagesRead({ last_message_id, user_id }) {
    return fetch(`${MAIN_URL}/messages/set-messages-read`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ last_message_id, user_id }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.setMessagesRead,
        params: { last_message_id, user_id },
      })
    );
  }

  sendMessage({ user_id, random_id, text }) {
    return fetch(`${MAIN_URL}/messages/new-message`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ user_id, random_id, text }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.sendMessage,
        params: { user_id, random_id, text },
      })
    );
  }

  getUpcomings({ limit, last_datetime }) {
    let params = {};
    if (limit) params.limit = limit;
    if (last_datetime) params.last_datetime = last_datetime;

    return fetch(
      `${MAIN_URL}/meetings/auth/appointment/get-all-upcoming?` +
      new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getUpcomings,
        params: { limit, last_datetime },
      })
    );
  }

  cancelAppointment({ _id, date, time }) {
    let params = {};
    if (_id) params._id = _id;
    if (date) params.date = date;
    if (time) params.time = time;

    return fetch(
      `${MAIN_URL}/meetings/auth/appointment/cancel?` +
      new URLSearchParams(params),
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.cancelAppointment,
        params: { _id, date, time },
      })
    );
  }

  getCalendar({ date, user_id }) {
    let params = {};
    if (date) params.date = date;
    if (user_id) params.user_id = user_id;

    return fetch(
      `${MAIN_URL}/meetings/get-calendar?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getCalendar,
        params: { date, user_id },
      })
    );
  }

  getCalendarWithAuth({ date }) {
    let params = {};
    if (date) params.date = date;

    return fetch(
      `${MAIN_URL}/meetings/auth/get-calendar?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getCalendarWithAuth,
        params: { date },
      })
    );
  }

  getDate({ date, user_id }) {
    let params = {};
    if (date) params.date = date;
    if (user_id) params.user_id = user_id;

    return fetch(
      `${MAIN_URL}/meetings/get-date?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getDate,
        params: { date, user_id },
      })
    );
  }

  getDateWithAuth({ date }) {
    let params = {};
    if (date) params.date = date;

    return fetch(
      `${MAIN_URL}/meetings/auth/get-date?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getDateWithAuth,
        params: { date },
      })
    );
  }

  editDateSchedule({ schedule, date }) {
    return fetch(`${MAIN_URL}/meetings/auth/edit-exact-date-schedule`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ schedule, date }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editDateSchedule,
        params: { schedule, date },
      })
    );
  }

  editWeekdaySchedule({ schedule, weekday }) {
    return fetch(`${MAIN_URL}/meetings/auth/edit-weekday-schedule`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ schedule, weekday }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editWeekdaySchedule,
        params: { schedule, weekday },
      })
    );
  }

  async uploadImage({ data, type }) {
    return fetch(
      `${MAIN_URL}/images/upload?` +
      new URLSearchParams({ data: JSON.stringify({ type: type }) }),
      {
        method: "POST",
        credentials: "include",
        body: data,
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.uploadImage,
        params: { data, type },
      })
    );
  }

  savePublishedAdvert({
    _id,
    category_id,
    sub_category_id,
    title,
    with_deposit,
    offer_type,
    date_of_birth,
    description,
    breed,
    litter_info,
    father,
    mother,
    children,
    images,
  }) {
    return fetch(`${MAIN_URL}/items/auth/published/save`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        _id: _id ? _id : null,
        category_id: category_id,
        sub_category_id: sub_category_id,
        title: title,
        features: {
          with_deposit: with_deposit,
          offer_type: offer_type,
          date_of_birth: date_of_birth,
        },
        description: description,
        breed: breed,
        litter_info: litter_info,
        parents: {
          father: father,
          mother: mother,
        },
        children: children,
        images: images,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.savePublishedAdvert,
        params: {
          _id,
          category_id,
          sub_category_id,
          title,
          with_deposit,
          offer_type,
          date_of_birth,
          description,
          breed,
          litter_info,
          father,
          mother,
          children,
          images,
        },
      })
    );
  }

  deletePublishedAdvert({ _id }) {
    let params = {};
    if (_id) params._id = _id;

    return fetch(
      `${MAIN_URL}/items/auth/published/delete?` + new URLSearchParams(params),
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.deletePublishedAdvert,
        params: { _id },
      })
    );
  }

  getItemWithAuth({ _id, collection }) {
    let params = {};
    if (_id) params._id = _id;
    if (collection) params.collection = collection;

    return fetch(
      `${MAIN_URL}/items/auth/get-exact?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getItemWithAuth,
        params: { _id, collection },
      })
    );
  }

  saveDraft({
    _id,
    category_id,
    sub_category_id,
    title,
    with_deposit,
    offer_type,
    date_of_birth,
    description,
    breed,
    litter_info,
    father,
    mother,
    children,
    images,
  }) {
    return fetch(`${MAIN_URL}/items/auth/drafts/save`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        _id: _id ? _id : null,
        category_id: category_id,
        sub_category_id: sub_category_id,
        title: title,
        features: {
          with_deposit: with_deposit,
          offer_type: offer_type,
          date_of_birth: date_of_birth,
        },
        description: description,
        breed: breed,
        litter_info: litter_info,
        parents: {
          father: father,
          mother: mother,
        },
        children: children,
        images: images,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.saveDraft,
        params: {
          _id,
          category_id,
          sub_category_id,
          title,
          with_deposit,
          offer_type,
          date_of_birth,
          description,
          breed,
          litter_info,
          father,
          mother,
          children,
          images,
        },
      })
    );
  }

  publishDraft({
    _id,
  }) {
    return fetch(`${MAIN_URL}/items/auth/drafts/publish`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        _id: _id,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.publishDraft,
        params: {
          _id,
        },
      })
    );
  }

  deleteDraft({ _id }) {
    let params = {};
    if (_id) params._id = _id;

    return fetch(
      `${MAIN_URL}/items/auth/drafts/delete?` + new URLSearchParams(params),
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.deleteDraft,
        params: { _id },
      })
    );
  }

  getBreeder({ _id }) {
    let params = {};
    if (_id) params._id = _id;

    return fetch(
      `${MAIN_URL}/breeders/get-exact?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getBreeder,
        params: { _id },
      })
    );
  }

  editProfilePhotos({ profile_photos }) {
    return fetch(`${MAIN_URL}/auth/register/breeders/edit-profile-photos`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        profile_photos: profile_photos,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editProfilePhotos,
        params: { profile_photos },
      })
    );
  }

  getItemsByBreeder({ breeder_id, last_id, limit }) {
    let params = {};
    if (breeder_id) params.breeder_id = breeder_id;
    if (last_id) params.last_id = last_id;
    if (limit) params.limit = limit;

    return fetch(
      `${MAIN_URL}/items/get-all-by-breeder-id?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getItemsByBreeder,
        params: { breeder_id, last_id, limit },
      })
    );
  }

  getNewsByBreeder({ owner_id, last_id, limit, tag }) {
    let params = {};
    if (owner_id) params.owner_id = owner_id;
    if (last_id) params.last_id = last_id;
    if (limit) params.limit = limit;
    if (tag) params.tag = tag;

    return fetch(`${MAIN_URL}/news/get-all?` + new URLSearchParams(params), {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getNewsByBreeder,
        params: { owner_id, last_id, limit, tag },
      })
    );
  }

  getReviewsByBreeder({ owner_id, last_id, limit }) {
    let params = {};
    if (owner_id) params.owner_id = owner_id;
    if (last_id) params.last_id = last_id;
    if (limit) params.limit = limit;

    return fetch(`${MAIN_URL}/reviews/get-all?` + new URLSearchParams(params), {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getReviewsByBreeder,
        params: { owner_id, last_id, limit },
      })
    );
  }

  getSubscriptions({ last_id, limit, type }) {
    let params = {};
    if (type) params.type = type;
    if (last_id) params.last_id = last_id;
    if (limit) params.limit = limit;

    return fetch(
      `${MAIN_URL}/subscriptions/get-by-type?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(this._checkResponse);
  }

  createNews({ title, text, tags, image }) {
    return fetch(`${MAIN_URL}/news/auth/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        title: title,
        text: text,
        tags: tags ? tags : null,
        image: image,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.createNews,
        params: { title, text, tags, image },
      })
    );
  }

  getPhotosByBreeder({ owner_id, last_id, limit }) {
    let params = {};
    if (owner_id) params.owner_id = owner_id;
    if (last_id) params.last_id = last_id;
    if (limit) params.limit = limit;

    return fetch(`${MAIN_URL}/photos/get-all?` + new URLSearchParams(params), {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getPhotosByBreeder,
        params: { owner_id, last_id, limit },
      })
    );
  }

  createPhotosBreeder({ _id }) {
    return fetch(`${MAIN_URL}/photos/auth/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        _id: _id,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.createPhotosBreeder,
        params: { _id },
      })
    );
  }

  deletePhotosBreeder({ _id }) {
    let params = {};
    if (_id) params._id = _id;

    return fetch(
      `${MAIN_URL}/photos/auth/delete?` + new URLSearchParams(params),
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.deletePhotosBreeder,
        params: { _id },
      })
    );
  }

  getBreederDeposits({ last_id, limit, type }) {
    let params = {};
    params.data = JSON.stringify({
      last_id,
      limit,
      type,
    });
    return fetch(
      `${MAIN_URL}/deposits/owners/get-all?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getBreederDeposits,
        params: { last_id, limit, type },
      })
    );
  }

  getBuyerDeposits({ last_id, limit }) {
    let params = {};
    if (last_id) params.last_id = last_id;
    if (limit) params.limit = limit;

    return fetch(
      `${MAIN_URL}/deposits/users/get-all?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getBuyerDeposits,
        params: { last_id, limit },
      })
    );
  }

  getStripeDashboard() {
    return fetch(`${MAIN_URL}/stripe/dashboard`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getStripeDashboard,
        params: {},
      })
    );
  }
  getStripeCustomerPortal() {
    return fetch(`${MAIN_URL}/stripe/customer-portal`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.getStripeCustomerPortal,
        params: {},
      })
    );
  }

  retriveAccountStripe() {
    return fetch(`${MAIN_URL}/stripe/retrieve-account`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.retriveAccountStripe,
        params: {},
      })
    );
  }

  editDepositFromBreeder({ _id, status }) {
    return fetch(`${MAIN_URL}/deposits/owners/update-status`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        _id: _id,
        status: status,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editDepositFromBreeder,
        params: { _id, status },
      })
    );
  }

  editDepositFromBuyer({ _id, status }) {
    return fetch(`${MAIN_URL}/deposits/users/update-status`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        _id: _id,
        status: status,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.editDepositFromBuyer,
        params: { _id, status },
      })
    );
  }

  saveDepositToSchedule({ _id }) {
    return fetch(`${MAIN_URL}/deposits/owners/save-to-schedule`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        _id: _id,
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.saveDepositToSchedule,
        params: { _id },
      })
    );
  }

  deleteDepositFromBreeder({ _id }) {
    let params = {};
    if (_id) params._id = _id;

    return fetch(
      `${MAIN_URL}/deposits/owners/delete?` + new URLSearchParams(params),
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.deleteDepositFromBreeder,
        params: { _id },
      })
    );
  }

  deleteDepositFromBuyer({ _id }) {
    let params = {};
    if (_id) params._id = _id;

    return fetch(
      `${MAIN_URL}/deposits/users/delete?` + new URLSearchParams(params),
      {
        method: "DELETE",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.deleteDepositFromBuyer,
        params: { _id },
      })
    );
  }

  placeDeposit({ _id, child_id, utc_datetime, success_url, cancel_url }) {
    return fetch(`${MAIN_URL}/deposits/users/place`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        _id: _id,
        child_id: child_id,
        utc_datetime: STRING_OR_NULL(utc_datetime),
        success_url: STRING_OR_NULL(success_url),
        cancel_url: STRING_OR_NULL(cancel_url),
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.placeDeposit,
        params: { _id, child_id, utc_datetime, success_url, cancel_url },
      })
    );
  }

  purchaseSubscription({ lookup_key, success_url, cancel_url }) {
    return fetch(`${MAIN_URL}/auth/register/breeders/subscribe`, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        lookup_key: lookup_key,
        success_url: STRING_OR_NULL(success_url),
        cancel_url: STRING_OR_NULL(cancel_url),
      }),
    }).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.purchaseSubscription,
        params: { lookup_key, success_url, cancel_url },
      })
    );
  }


  searchAdverts({ text }) {
    let params = {};
    if (text) params.text = text;

    return fetch(
      `${MAIN_URL}/search/main?` + new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then((res) =>
      mainApi._checkResponseWithCookies({
        res: res,
        func: mainApi.searchAdverts,
        params: { text },
      })
    );
  }
}

const mainApi = new MainApi({
  baseUrl: MAIN_URL,
});

export default mainApi;
