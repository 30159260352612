import { Link } from "react-router-dom";

export const FR_TRANSLATION = {
  header: {
    breeds: "Toutes les races",
    breeders: "Éleveurs",
    community: "Communauté",
    beforeBuy: "Avant d'acheter",
    adopt: "Adoptez, n'achetez pas",
    forum: "Forum",
    blog: "Blog",
    login: "Se connecter",
    registration: "Inscription",
    advert: "Nouvelle annonce",
    home: "Accueil",
    logout: "Se déconnecter",
    allDogs: "Tous chiens",
    smallDogs: "Petits",
    mediumDogs: "Moyens",
    bigDogs: "Grands",
    search: "Race, nom d'utilisateur ou autre",
    searchBtn: "Recherche",
    language: "Langue",
  },


  footer: {
    races: {
      title: "Races",
      all: "Toutes les races",
    },
    breeders: {
      title: "Éleveurs",
      breeding: "Élevage",
    },
    utile: {
      title: "Utile",
      blog: "Blog & Conseils",
      contact: "Contact",
      forum: "Forum",
      cgv: "CGV",
    },
    indispensable: {
      title: "Indispensable",
      howTo: "Comment adopter",
      breeder: "Choisir l'éleveur",
      whoWeAre: "Qui sommes-nous?",
    },
    info: {
      title: "Infos",
      privacyPolicy: "Politique de confidentialité",
      service: "Conditions d'utilisation",
      sales: "CGV",
      cookies: "Confidentialité / Cookies",
    },
    copyright: "ⒸPet4Me 2023",
  },


  main: {
    searchTitle: " Trouvez votre futur compagnon à quatre pattes !",
    searchBreedInput: "Race de chien",
    searchBreedPlaceholder: "Teckel, berger australien",
    searchNothing: "Aucun résultat",
    searchLocationInput: "Où",
    searchLocationPlaceholder: "Ville, adresse",
    searchBtn: "Recherche",


    recommendedTitle: "Recommandé",
    recommendedAll: "Voir tout",


    howTitle: " Comment profiter au mieux des services Pet4me",
    howOnline: "Choisissez votre compagnon à quatre pattes sur Pet4me",
    howDetails: `Demandez des détails à l'éleveur, laissez un <a href='/blog/64e0190f0eab93e526dbc956'>acompte</a>`,
    howHome: "Ramenez votre compagnon à la maison !",


    reviewsTitle: "Avis",


    forumTitle: "Vous cherchez des informations sur une race de chien ? Vous aimeriez en savoir plus sur la manière de bien accueillir un chiot ?",
    forumText: "Visitez notre blog, il y a sûrement les réponses à vos questions…",
    forumLink: "Vers le blog",
  },


  blog: {
    title: "Blog",
    subtitle:
      "Pour être au courant de l’actualité de la communauté Pet4me ? Pour obtenir une réponse à une question qui concerne votre projet d’adoption ? Pour lire des infos amusantes ou instructives concernant le bien être de nos compagnons à quatre pattes ? Vous êtes au bon endroit !",
    moreBtn: "Lire la suite",
  },


  blogArticle: {
    also: "Lisez aussi",
    moreBtn: "Lire plus",
  },


  catalog: {
    title: "Annonces",
    filtersBtn: "Filtres",
    sortBtn: "Trier par prix",
    toHighPrice: "Prix bas à élevé",
    toLowPrice: "Prix élevé à bas",
    nothingTitle: "Désolé, aucun résultat ne correspond à votre recherche",
    findPuppyBtn: "Aidez-moi à trouver un chiot",
    notifyBtn: (breed) => `Notifiez-moi pour ${breed}`,
    notifyBtnText: "Nous vous enverrons un e-mail si le chiot de vos rêves est disponible",
    feedback: {
      formTitle: "Demande de race",
      formText: "Laissez vos informations et nous vous contacterons",
      sendBtn: "Envoyer",
      okTitle: "Merci",
      okText: "Nous reviendrons vers vous dans les plus brefs délais",
      okBtn: "D'accord",
      any: "N'importe lequel",
    },
  },


  filtersBlock: {
    title: "Filtres",
    placeholder: { search: "Filtrer les races", locacion: "Localisation" },
    resetBtn: "Réinitialiser les filtres",
    applyBtn: "Appliquer",
    searchNothing: "Rien trouvé",
    any: "N'importe quelle race",
    all: "Tous les",
    km: "km",
    over: "Plus de",
    from: "de",
  },


  dogCard: {
    boost: "Boost",
    newborn: "Nouveau-né",
    age: " Age",
    colour: "Couleur",
    topTouTou: "Top TouTou",
    deposit: "Acompte",
    draft: "Brouillon",
    draft_title: "Sans titre",
    draft_description: "Pas de description",
    draft_breed: "Sans race",
    draft_date_of_birth: "Date de naissance inconnue",
  },


  breeders: {
    provenTitle: "Nous sélectionnons pour vous les meilleurs éleveurs !",
    breedersTitle: "éleveurs",
    subtitle: "Tous nos éleveurs sont vérifiés par nos soins et doivent respecter notre code de déontologie",
    howTitle: "Comment vérifions-nous l'éleveur ?",
    howBackground:
      "Nous vérifions l’historique des éleveurs candidats, nous prenons la mesure de leur motivation à rejoindre Pet4me et nous menons une enquête précise afin de nous assurer qu’ils pourront se conformer à notre ",
    backgroundLink: "Code de déontologie",
    howVisit:
      "Les experts animaliers Pet4me visitent les installations de l’éleveur afin de vérifier les conditions dans lesquelles vivent les chiens (propreté, aires de repos et de jeu, service de santé).",
    howDeal: "Seuls les élevages qui proposent un cadre de vie épanouissant pour leurs animaux sont validés par nos experts et deviennent partenaires de Pet4me.",
    listTitle: "Nos éleveurs",
    verified: "Vérifié",
    everybody: "Tout le monde",
    any: "N'importe lequel",
  },


  breederCard: {
    reviews: "Avis",
    breeds: "Races",
    experience: "Expérience",
    club: "Club de race",
    siren: "Numéro SIREN",
    tests: "Tests génétiques",
    pension: "Pension",
    adverts: "Annonces",
    viewBtn: "Voir",
    messageBtn: "Message",
    profileBtn: "Voir le profil",
    yes: "Oui",
    no: "Non",
    more: "plus",
  },


  desk: {
    nav: {
      profile: "Mon profil",
      chats: "Chats",
      meetings: "Rendez-vous",
      deposits: "Acomptes",
      favorites: "Favoris",
      breeders: "Éleveurs",
      adverts: "Mes annonces",
      subscription: "Abonnement",
    },
    breedersTitle: "Éleveurs favoris",
    favoritesTitle: "Favoris",
    depositsTitle: "Acomptes",
    profileTitle: "Profil",
    chatsTitle: "Chats",
    meetsTitle: "Rendez-vous à venir",
    advertsTitle: "Mes annonces",
    myScheduleTitle: "Mon emploi du temps",
    scheduleTitle: "Emploi du temps de l'éleveur",
    subPlansTitle: "Plans d'abonnement",
    subscriptionTitle: "Mon abonnement",
    depositsNew: "Nouveau",
    depositsApproved: "Résolu",
    connectText: "Veuillez vous connecter à Stripe pour accepter les acomptes des utilisateurs",
    connectBtn: "Connectez-vous à Stripe",
    stripBtn: "Gérer le compte Stripe",
    cancelBtn: "Annuler",
    approveBtn: "Approuver",
    messageBtn: "Message",
    resolveBtn: "Valider",
    hideBtn: "Cacher",
    breederInfo: "Infos éleveur",
    addBreedBtn: "Ajouter une race",
    sendBtn: "Envoyer",
    socialNetworks: "Réseaux sociaux",
    notifications: "Notifications",
    password: "Mot de passe",
    account: "Compte",
    changeBtn: "Changer le mot de passe",
    deleteBtn: "Supprimer le compte",
    saveBtn: "Enregistrer les modifications",
    showAllBtn: "Afficher tout",
    collapseBtn: "Réduire",
    viewSlotBtn: "Voir",
    changeSlotBtn: "Changer",
    editSlotsBtn: "Modifier les créneaux",
    activateBtn: "Activer tout",
    repeatBtn: "Répéter chaque semaine",
    freeSlot: "Libre",
    unavailableSlot: "Indisponible",
    selectedTimeSlot: "Créneau choisi",
    appointment: "rendez-vous",
    puppy: "Chiot",
    nothing: "Il n'y a rien ici pour le moment",
    noDialogText: "Choisissez à qui vous voulez écrire",
    noDialogLink: "Allez à vos contacts",
    country: "France",
    advertsTypes: { published: "Publié", drafts: "Brouillons" },
    noMessages: `Il n'y a pas encore de messages dans ce dialogue.
  Soyez le premier !`,
    noMeetings: "Vous n'avez pas de rendez-vous à venir.",
    noSlots: "Vous n'avez pas de créneaux disponibles pour cette journée.",
    noDepositSlots: "Il n'y a pas de créneaux ce jour-là.",
    approvePopup: {
      title_confirm_approve: "Accepter l’acompte ?",
      title_confirm_cancel: "Refuser l’acompte?",
      title_resolve: "Verser l’acompte",
      title: "L’acompte approuvé",
      book: "Réserver le rendez-vous",
      bookedMeeting: "Rendez-vous planifié",
      scheduleBtn: "Ajouter à mon emploi du temps",
      messageBtn: "Message au client",
      messageBreederBtn: "Message à l'éleveur",
      approveBtn: "Accepter l’acompte",
      cancelBtn: "Refuser l’acompte",
      do_you_finalized_deal: "Avez-vous réussi à parvenir à un accord?",
      confirm_cancel_resolve: "Êtes-vous sûr de vouloir annuler le versement de l'acompte et la réservation ?",
      yes_text_label: "Oui",
      cancel_text_label: "Annuler",
      resolveBtn: "Oui, je valide le versement de l'acompte.",
      cancelResolveBtn: "Non, je souhaite être remboursé",
    },
    changePopup: {
      title: "Changer le mot de passe",
      saveBtn: "Enregistrer le nouveau mot de passe",
      cancelBtn: "Annuler",
    },
    deletePopup: {
      title: "Suppression du compte",
      subtitle: "S'il vous plaît, soyez prudent !",
      text: "La suppression du compte supprime également toutes les données associées, sans possibilité de restauration. Vous devrez entrer le mot de passe de votre compte pour le supprimer.",
      deleteBtn: "Supprimer mon compte",
      warning: "Irréversible",
      cancelBtn: "Annuler",
    },
    editSlotsPopup: {
      title: "Modifier les créneaux horaires",
      saveBtn: "Enregistrer les créneaux pour cette journée",
      cancelBtn: "Annuler",
    },
    dogsCard: {
      sale: "Vente",
      withDeposit: "Avec l’acompte",
      noDeposit: "Sans acompte",
      color: "Couleur",
      peculiarities: "Particularités",
      placeBtn: "Acompte",
      messageBtn: "Message à l'éleveur",
    },
    placeholder: {
      blank: "Laissez vide si aucun",
      usernameLink: "Nom d'utilisateur ou lien",
      usernameNumber: "Nom d'utilisateur ou numéro",
      breedSearch: "Tapez un nom de race",
      typeMessage: "Tapez votre message",
    },
    subscriptions: {
      pro_title: "Abonnement Pro",
      monthly_renewal_label: "Renouvellement mensuel",
      yearly_renewal_label: "Renouvellement annuel",
      per_month_label: "par mois",
      pro_status_in_profile: "Statut PRO sur votre profil",
      exclusive_features_title: "Fonctionnalités Pro exclusives",
      exclusive_features_news: "Fil d'actualité",
      exclusive_features_photos: "Galerie de photos",
      discount_label: "Remise",
      priority_moderation_label: "Modération prioritaire des annonces",
      free_photographer: " Séance photo OFFERTE (valeur 300€) !",


      yes_text_label: "Oui",
      no_text_label: "Non",
      buy_btn: "Acheter maintenant",
      manage_btn: "Gérer ou annuler",
    },
  },


  subscriptionCard: {
    title: "Abonnement Pro",
    monthlyType: "Renouvellement mensuel",
    yearlyType: "Renouvellement annuel",
    perMonth: "par mois",
    status: "Statut PRO sur votre profil",
    features: "Fonctionnalités Pro exclusives pour votre profil",
    news: "Fil d'actualité",
    photos: "Galerie de photos",
    discount: "Remise",
    no: "Non",
    buyBtn: "Acheter maintenant",
    manageBtn: "Gérer ou annuler",
  },


  auth: {
    backBtn: "Retour",
    buyer: "Je suis un acheteur",
    breeder: "Je suis un éleveur",
    shelter: "Refuge",
    or: "ou",
    facebook: "Continuer avec Facebook",
    google: "Continuer avec Google",
    message: {
      title: "E-mail envoyée",
      text: (email) =>
        `Un e-mail a été envoyé à l'adresse: <span>${email}</span> avec un lien pour réinitialiser votre mot de passe`,
    },
    ok: "D'accord",
    registration: {
      title: "Inscription",
      breederTitle: "Nouveau compte éleveur",
      imageTitle: "Je recherche un animal de compagnie",
      imageText: "Très bientôt, vous pourrez trouver votre compagnon",
      redirectText: "Déjà inscrit ?",
      redirectLink: "Se connecter",
      submitText: "Inscription",
      breederSubmit: "Enregistrer et continuer",
    },
    login: {
      title: "Se connecter",
      imageTitle: "Trouvez votre animal de compagnie !",
      imageText:
        "J'accepte les conditions générales et la politique de confidentialité",
      redirectText: "Pas encore inscrit ?",
      redirectLink: "Inscription",
      submitText: "Se connecter",
      reminderText: "J'ai oublié mon mot de passe",
    },
    recovery: {
      title: "Récupération du mot de passe",
      subtitle:
        "Entrez l'adresse e-mail que vous avez fournie lors de l'inscription et nous vous enverrons un e-mail avec un lien pour réinitialiser votre mot de passe.",
      redirectText: "Pas encore inscrit ?",
      redirectLink: "Inscription",
      submitText: "Envoyer",
    },
    newPassword: {
      title: "Nouveau mot de passe",
      subtitle:
        "Entrez un nouveau mot de passe et répétez-le. Le mot de passe doit contenir au moins 8 caractères.",
      submitText: "Terminé",
    },
    confirmEmail: {
      title: "Confirmer l'email",
      subtitle: "Entrez un code que nous vous avons envoyé pour compléter la vérification de l'email.",
      submitTextBreeder: "Continuer",
      submitTextBuyer: "Terminé",
    },
  },


  errors: {
    required: "Requis",
    email: "E-mail invalide",
    min: "Doit contenir au moins 8 caractères",
    terms: "Vous devez accepter les termes et conditions",
    confirmPassword: "Les mots de passe doivent correspondre",
    phone: "Numéro de téléphone invalide",
    length: "Doit contenir 6 caractères",
    pageNotFound: "Nous ne trouvons pas cette page, mais nous pouvons vous aider à trouver un chien",
    pageNotFoundBtn: "Trouver un chien",
    fileTooBig: "Le fichier est trop volumineux",
    searchNothing: "Rien n'a été trouvé",
    listValue: "Sélectionnez une valeur dans la liste",
    tooBigYear: "L'année est trop grande",
    login404: "E-mail ou mot de passe incorrect",
    code404: "Code incorrect",
    alreadyExist: "L'email est déjà enregistré",
    password400: "Mauvais mot de passe actuel",
    notFound: "Données non trouvées",
    tooManyRequests: "Trop de tentatives",
    apiErr: "Une erreur s'est produite. Veuillez réessayer."
  },


  labels: {
    name: "Nom",
    siren: "Numéro SIREN",
    shelter: "Nom du refuge",
    email: "E-mail",
    code: "Code",
    password: "Mot de passe",
    minSymbols: "min 8 symboles",
    termsCheckbox: `J'accepte les conditions générales et la politique de confidentialité.`,
    rememberMe: "Se souvenir de moi",
    currentPassword: "Mot de passe actuel",
    newPassword: "Nouveau mot de passe",
    confirmPassword: "Répétez le mot de passe",
    confirmPswd: "Confirmer le nouveau mot de passe",
    phone: "Numéro de téléphone",
    breed: "Race recherchée",
    location: "Emplacement",
    requests: "Des demandes spécifiques ?",
    selectBreed: "Race",
    city: "Ville",
    phoneNumber: "Téléphone",
    country: "Pays",
    address: "Adresse",
    breederAddress: "Adresse",
    profileDescription: "Description du profil",
    startYear: "Année de début de l'activité d'élevage",
    verifiedBreeder: "Service de pension",
    sirenNumber: "SIREN",
    breedClub: "Club de race",
    geneticTests: "Tests génétiques de santé pour les animaux",
    lof: "LOF",
    facebook: "Facebook",
    instagram: "Instagram",
    whatsapp: "WhatsApp",
    enableNotifications: "Activer les notifications",
    newMessages: "Nouveaux messages",
    breedSubscriptions: "Abonnements de race",
    promotions: "Actualités et promotions",
    yes: "Oui",
    no: "Non",
    all: "Tous",
    some: "Certains",
  },


  breederHeading: {
    show_all_photos: "Voir toutes les photos",
    edit_profile: "Modifier le profil",
    edit_profile_short: "Modifier",
  },


  breederInfo: {
    reviews_text_single_form: "avis",
    reviews_text_multiple_form: "avis",
    message_btn_text: "Message",
    new_advert_btn_text: "Nouvelle annonce",
    breeds_title: "Races",
    experience_title: "Expérience",


    breed_club_title: "Club de race",
    yes_text_label: "Oui",
    no_text_label: "Non",
    genetic_tests_title: "Tests génétiques",
    pension_service_title: "Service de pension",
    siren_title: "Numéro SIREN",
    verified_title_long: "Éleveur vérifié",
    verified_title_short: "Vérifié",


    days_label: "jours",
    days_label_single_form: "jour",
    years_label: "années",
    years_label_single_form: "an",
    months_label: "mois",
    months_label_single_form: "mois",


    share_text: "J'ai vraiment apprécié cet éleveur. {BREEDER_NAME}",
    share_text_advert: "Regardez comme ce {BREED_NAME} est mignon",
  },


  breederNav: {
    adverts_title: "Annonces",
    news_title: "Actualités",
    photos_title: "Photos",
    reviews_title: "Avis",
    contacts_title: "Contacts",
  },


  advertHeading: {
    show_all_photos: "Voir toutes les photos",
    edit: "Modifier l'annonce",
    edit_short: "Modifier",
  },


  advert: {
    nothing_title: "Désolé, aucun résultat pour votre demande",
    retutn_back: "Retourner en arrière",
    gallery_popup_title: "Toutes les photos"
  },


  advertTitle: {
    type_sale: "Vente",
    type_adoption: "Adoption",
    with_deposit: "Avec l’acompte",
    no_deposit: "Sans acompte",
    title_label: "Titre",
    type_sale_label: "Vente",
    type_adoption_label: "Adoption",
    deposit_label: "Acompte",
    no_deposit_label: "Pas d’acompte",
  },


  editAdvertBanner: {
    title: "Modifier l'annonce",
    title_new: "Nouvelle annonce",
    title_draft: "Brouillon",
    cancel_btn: "Annuler",
    publish_btn: "Publier l'annonce",
    publish_btn_short: "Publier",
    unpublish_btn: "Dépublier",
    unpublish_btn_moved: "Déplacé en brouillons!",
    unpublish_btn_moved_short: "Déplacé!",
    save_btn_published: "Sauvegarder l'annonce",
    save_btn: "Sauvegarder en brouillon",
    save_btn_saved: "Brouillon enregistré!",
    save_btn_saved_short: "Enregistré!",
    save_btn_short: "Sauvegarder",
    delete_draft: "Supprimer le brouillon",
    delete_draft_short: "Supprimer",


    delete_question: "Êtes-vous sûr?",
    delete_question_short: "Sûr?",
    yes_text_label: "Oui",
    no_text_label: "Non",
  },


  advertBanner: {
    editPhotos: "Modifier les photos",
    uploadPhotos: "Télécharger les photos",
    minPhotos: "Min. 5 photos",
  },


  advertDescription: {
    description_label: "Description",
  },


  litterInfo: {
    title: "Informations sur la portée",
    basic_title: "De base",
    veterinary_title: "Vétérinaire",
    father_title: "Père",
    mother_title: "Mère",


    param_breed_title: "Race",
    param_age_title: "Age",
    param_id_title: "ID",
    param_lof_title: "LOF",
    param_vaccination_title: "Vaccination",
    param_wormed_title: "Vermifugé",
    param_microchip_title: "Puce électronique",
    param_dna_test_title: "Test ADN",


    days_label: "jours",
    days_label_single_form: "jour",
    years_label: "années",
    years_label_single_form: "an",
    months_label: "mois",
    months_label_single_form: "mois",


    yes_text_label: "Oui",
    no_text_label: "Non",
    yes_dna_tests: "Résultats sur demande",
    id_and_lof_placeholder: "Numéro · Laisser vide si aucun",
  },


  litterParent: {
    breed_title: "Race",
    lof_title: "LOF",
    color_title: "Couleur",
    age_title: "Age",
    days_label: "jours",
    days_label_single_form: "jour",
    years_label: "années",
    years_label_single_form: "an",
    months_label: "mois",
    months_label_single_form: "mois",


    edit_photo: "Modifier la photo",
    upload_photo: "Télécharger une photo",


    name_placeholder: "Nom",
    breed_placeholder: "Race",
    lof_placeholder: "LOF· Laisser vide si aucun",
    colour_placeholder: "Couleur",
    date_of_birth_placeholder: "Né le JJ.MM.AAAA",
  },


  advertAuthor: {
    buyers_checklist: "Guide de l'adoption",
  },


  loginPopup: {
    title: "Veuillez vous connecter",
    text: "Cette action nécessite une autorisation. Veuillez vous connecter à votre compte.",
    btnText: "D'accord",
  },


  childrens: {
    boy_title: "Garçons",
    girls_title: "Filles",
    add_puppy_label: "Ajouter un chiot",
  },


  childCard: {
    color_label: "Couleur",
    peculiarities_label: "Particularités",
    palce_deposit_label: "Placer un acompte",
    not_available_label: "Non disponible",
    message_label: "Message à l'éleveur",


    name_placeholder: "Nom",
    colour_placeholder: "Couleur",
    peculiarities_placeholder: "Particularités",
    price_placeholder: "Prix · €",


    edit_photo: "Modifier la photo",
    upload_photo: "Télécharger une photo",
  },


  depositPopup: {
    title: "Placer un acompte",
    selectBtn: "Sélectionnez l'heure du rendez-vous et continuez",
    laterBtn: "Choisissez l'heure du rendez-vous plus tard dans le chat",
    readBeforeText: "Avant de verser un acompte, veuillez vous informer sur le fonctionnement de notre service.",
  },


  successPopup: {
    title: "Votre demande de réservation a été envoyée",
    text: "L'éleveur examinera votre demande et vous contactera via le chat",
  },


  bannerPopup: {
    title: "Photos",
    add_btn: "Ajouter une photo",
  },


  dropdown: {
    placeholder: "Choisir",
  },


  breederProfile: {
    nothing_title: "Désolé, aucun résultat ne correspond à votre recherche.",
    retutn_back: "Retourner en arrière",
    gallery_popup_title: "Galerie de photos de {USER}",
  },


  breederAdvert: {
    days_label: "jours",
    days_label_single_form: "jour",
    years_label: "années",
    years_label_single_form: "an",
    months_label: "mois",
    months_label_single_form: "mois",
    with_deposit_label: "Dépôt",
  },


  advertsCards: {
    show_more: "Voir plus",
  },


  reviews: {
    show_more: "Voir plus",
    no_text:
      "Vous pourrez recevoir des avis uniquement des personnes ayant acheté un chiot avec l’acompte via Pet4me",
  },


  news: {
    show_more: "Voir plus",
    pro_text:
      "Devenez Pro pour poster des actualités et des photos, c’est très amusant et attire plus de monde",
    become_pro_btn: "Devenir Pro",
    add_btn: "Ajouter une Actualité",
    create_title: "Créer une Actualité",
    title_label: "Titre",
    message_label: "Message / 200 symboles",
    add_tags_label: "Ajouter des Tags",
    add_image_label: "Ajouter une image",
    publish_label: "Publier",
  },


  emptyStatus: {
    text: `Oups...
  Il n'y a rien ici pour le moment`,
  },


  contacts: {
    address_label: "Adresse",
    phone_label: "Numéro de téléphone",
    email_label: "Email",
    social_label: "Réseaux sociaux",
  },


  breederPhotos: {
    add_photo_label: "Ajouter une photo",
    show_more: "Voir plus",
  },


  checkEmailCode: {
    resend_code: "Renvoyer le code",
  },




  aboutUs: {
    who_we_are_title: "Qui sommes-nous ?",
    who_we_are_text: "Pet4me est le lieu idéal pour que celles et ceux qui souhaitent accueillir un chien puissent rencontrer l’éleveur qui saura les comprendre. Nous ne sommes pas seulement une plateforme d’adoption, nous sommes avant tout une communauté organisée afin de proposer aux animaux de compagnie un cadre de vie apaisant et sécurisant. L’adoption et l’achat sont les deux manières d’accueillir chez vous le chien qui transformera positivement votre vie, Pet4me proposant également la mise en relation de propriétaires de chiens en vue de la reproduction.",
    mission_title: "Notre mission",
    mission_text: "Notre plateforme vous permet de rencontrer des passionnés de l’élevage et de l'éducation de chiots de toute race et toute origine. Les éleveurs partenaires présentés sur Pet4me sont les garants de notre sérieux. Accueillir un chien chez soi est un acte d’amour et de responsabilité, c’est pour cette raison que toutes les candidatures de propriétaires d’élevages de chiens sont vérifiées et validées par nos experts. Ce travail de contrôle vous garantit d’avoir affaire à des éleveurs aux pratiques éthiques respectueuses du bien-être des animaux.",
    cert_breeders_title: "Des éleveurs certifiés",
    cert_breeders_text: "Lorsque Pet4me certifie un élevage, c’est que ce dernier a été visité et qu’il respecte les exigences de notre cahier des charges. Chaque élevage est mis en avant sur la plateforme par le biais d’un descriptif complet et de photos réalisées par les experts animaliers Pet4me. La page consacrée à la présentation d’un élevage a vocation à transmettre les valeurs du ou des propriétaires. La fiche d’identité d’un éleveur doit représenter la réalité en termes de propreté, de sécurité et de savoir-faire.",
    our_values_title: "Nos valeurs",
    our_values_text: "Le projet Pet4me n’est pas seulement de permettre à un particulier de trouver le chien qu’il désire, mais de permettre à un animal de compagnie de rencontrer la famille au sein de laquelle il pourra s’épanouir. Pour aboutir à l'union parfaite entre un chien en désir d’affection et une famille en désir d’accueil d’un animal, Pet4me sensibilise les futurs propriétaires aux enjeux de l’adoption. La plateforme n’hésite pas à prévenir les candidats à l’accueil d’un chien que la présence d’un animal au quotidien n’est pas seulement une source de joie, mais également de contraintes. L’objectif de Pet4me est de prévenir l’adoption compulsive de chiens qui précède bien trop souvent l’abandon et la maltraitance des animaux.",
    safety_title: "La sécurité d'abord",
    safety_text: "Pet4me est une plateforme où les animaux sont en sécurité et les transactions financières également. La confiance qui unit les éleveurs Pet4me et les personnes qui désirent accueillir un chien est confortée par des fonctionnalités anti-fraude qui vous garantissent :",
    safety_point_1: "Que les animaux proposés sur Pet4me sont en bonne santé et qu’ils vivent dans des élevages confortables.",
    safety_point_2: "Que les transactions financières sont sécurisées et que le paiement des acomptes de réservation est garanti par Pet4me.",
    safety_last_text: "Cela signifie que si votre animal (provenant de nos élevages certifiés) n’est pas conforme à la promesse initiale, ou s’il tombe malade après son arrivée chez vous, alors Pet4me procèdera au remboursement intégral de vos versements de réservation.",
    proposal_title: "Proposition et objectif",
    proposal_subtitle: "Notre proposition est la suivante : imposer une nouvelle éthique de l’accueil des animaux. Notre objectif ? Le bien-être des animaux.",
    proposal_text: "Pet4me n’accorde aucun droit à l’erreur à l’égard de nos éleveurs partenaires, ces derniers étant les ambassadeurs de notre exigence. Les annonces mises en ligne sur notre plateforme sont toutes vérifiées et validées par nos experts. Les normes à respecter sont strictes et conditionnent la mise en ligne des propositions d’accueil de chiots et de chiens adultes. Pet4me refuse toutes les annonces qui ne respectent pas notre charte d’éthique et peut exclure un éleveur dont les pratiques nous semblent suspectes.",
    proposal_info_title: "Voici quelques exemples d’annonces que vous ne trouverez pas sur Pet4me :",
    proposal_info_point_1: "Animaux proposés en cadeaux dans le cadre d’une offre commerciale",
    proposal_info_point_2: "Animaux proposés en échange d’un bien matériel",
    proposal_info_point_3: "Animaux proposés en échange d’un autre animal",
    proposal_info_point_4: "Animaux livrés à domicile sans possibilité de visite d’un chenil",
    proposal_info_point_5: "Chiots présentés sans une photo sur laquelle il est visible à côté de sa mère",
    proposal_info_text: "Afin de sensibiliser toute notre communauté au drame que représente l’économie liée au trafic des animaux de compagnie, Pet4me propose des articles rédigés par des professionnels (vétérinaires, éleveurs, dresseurs…). Ces articles sont en lecture libre et permettent à une personne désireuse d’accueillir un animal de compagnie dans sa maison de mesurer l’importance et les conséquences d’une telle décision sur sa vie et celle de l’animal. En sus de ces articles, des conseils spécifiques à chaque race de chiens sont proposés afin de vivre plus intensément et avec sérénité sa relation avec son nouveau compagnon à quatre pattes. Il s’agit d’articles et de tutoriels destinés aux possesseurs de chien, afin qu’ils puissent se familiariser avec le comportement et la santé de leurs animaux.",
    our_community_title: "Une communauté impliquée",
    our_community_text: "La communauté Pet4me dispose d’une plateforme à partir de laquelle les éleveurs et les acheteurs peuvent signaler tout comportement suspect à l’égard des animaux. Cela peut concerner la maltraitance, les abus d’élevage de chiots et le trafic de chiens. Pet4me s’engage à surveiller les annonces douteuses qui masqueraient les élevages à risque, c’est la raison pour laquelle nos experts se déplacent systématiquement sur les lieux où résident les chiots (ces visites concernent également les élevages qui revendiquent un numéro d’agrément valable). Ce réflexe est une habitude que nous préconisons d’appliquer auprès de toute notre communauté. On n’organise pas l’accueil d’un animal de compagnie de chez soi : tout adoptant se doit d’aller là où réside son futur compagnon à quatre pattes.",
    our_community_text_2: "Pet4me a été créée pour favoriser le bien-être des animaux de compagnie. La plateforme accueille des propriétaires d’animaux de compagnie, des éleveurs professionnels ou amateurs responsables et des centres d’accueil d’animaux abandonnés qui sont prévenus que nous sommes intransigeants à l’égard des personnes qui tenteraient d’utiliser nos services sans respecter nos visiteurs et les animaux qu’ils présenteraient sur Pet4me.",




  },
  sensitizationPopup: {
    popup_name: "Adopter un chien est un acte à ne pas prendre à la légère. Prenez conscience q'avoir un chien, c'est du temps et de l'argent.",
    devote_subtitle: 'Consacrez du temps à votre chien',
    devote_point_1: `Un chien n’est pas une peluche. Vous devrez vous dégager du temps pour être à la maison à certains moments de la journée pour vous occuper de lui, pour jouer avec lui, mais aussi pour faire son éducation.`,
    devote_point_2: `Un chien a besoin d’être sorti plusieurs fois par jour, même s’il pleut, que ce soit pour ses besoins ou pour se dépenser. Prévoyez de faire régulièrement des promenades avec lui, c’est bon pour son épanouissement.`,
    cost_subtitle: 'Avoir un chien, ça représente un coût',
    cost_point_1: 'Il va de soi qu’il faudra prévoir un budget nourriture, jouets, accessoires.',
    cost_point_2: `Vous allez certainement dépenser le plus dans tout ce qui tourne autour de la santé et des soins. Sachez que plus un chien vieillit, plus il aura des problèmes de santé, et plus vous ferez d’allers-retours chez le vétérinaire.`,
    cost_point_3: `N’oubliez pas les frais de toilettage. Certaines races ont besoin d’être toilettées plus que d’autres. Et si un jour vous devez vous absenter sans lui, le faire garder, ça a un coût.`,
    cost_point_4: `Pour info: un budget annuel de 2000 € est un minimum à prévoir.`,
    end_test: `Adopter un chien est un sérieux engagement. C’est un peu comme un mariage, on s’engage pour le meilleur et pour le pire.`,
  },


  maxSizePopup: {
    title: 'Le fichier est trop volumineux',
    hint: 'Veuillez télécharger des fichiers sous {MAX_SIZE} MB',
    okay_btn: `D'accord`,
  },
}