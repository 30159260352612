import { useEffect, useState, useContext, useRef } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { motion, AnimatePresence, useCycle } from "framer-motion";
// import { FixedSizeList as List } from "react-window";
import { FixedSizeGrid as Grid } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import Balancer from "react-wrap-balancer";
import { TranslationContext } from "../../assets/contexts/translationContext";
import { SubcategoryContext } from "../../assets/contexts/subcategoryContext";
import { CurrentUserContext } from "../../assets/contexts/currentUserContext";
import useWindowSize from "../../assets/hooks/useWindowSize";
import DropdownPopup from "./DropdownPopup/DropdownPopup";
import AvatarBox from "../AvatarBox/AvatarBox";
import {
  LANGUAGES,
  EN_LANGUAGE,
  FR_LANGUAGE,
  DROPDOWN_BREEDS,
  DROPDOWN_COMMUNITY,
  DROPDOWN_PROFILE,
  DROPDOWN_LANG,
  MOBILE_NAV_TYPE,
  SMALL_BREEDS,
  MEDIUM_BREEDS,
  BIG_BREEDS,
  LOGIN_AUTH_TYPE,
  REG_AUTH_TYPE,
  BUYER_USER_TYPE,
  BREED_FILTER,
  MAIN_URL,
  BREEDER_USER_TYPE,
} from "../../assets/utils/constants";
import {
  LogoIcon,
  SmallBurgerIcon,
  SimpleArrowIcon,
  ProvenBreedersIcon,
  // BeforeBuyIcon,
  // AdoptIcon,
  // ForumIcon,
  BlogIcon,
  CheckIcon,
  ProfileIcon,
  ChatIcon,
  MeetsIcon,
  MeetingsIcon,
  DepositIcon,
  FavoritesIcon,
  SubscriptionIcon,
  SearchIcon,
  LoginIcon,
  CloseIcon,
} from "../../assets/icons/icons";
import dog from "../../assets/images/header/dog.png";
import "./Header.css";
import mainApi from "../../assets/api/MainApi";

const dropdownVariants = {
  open: {
    clipPath: "inset(0% 0% 0% 0% round 12px)",
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.5,
      delayChildren: 0.2,
      staggerChildren: 0.05,
    },
  },
  closed: {
    clipPath: "inset(10% 90% 90% 10% round 12px)",
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.3,
    },
  },
};

const searchBarVariants = {
  open: {
    clipPath: "inset(0% 0% 0% 0% round 10px)",
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.5,
      delayChildren: 0.2,
      staggerChildren: 0.05,
    },
  },
  closed: {
    clipPath: "inset(0% 100% -0% 0% round 10px)",
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.3,
    },
  },
};

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
};

const arrowVariants = {
  open: {
    rotate: 180,
    transition: {
      duration: 0.2,
    },
  },
  closed: {
    rotate: 0,
    transition: {
      duration: 0.2,
    },
  },
};

const Path = (props) => (
  <motion.path
    className="header__menu-icon-fill"
    strokeWidth="2.3"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  />
);

function Header({ isLoggedIn, language, setLanguage, setUser, handleAuthOpen, logout }) {
  const searchInput = useRef(null);
  const { width } = useWindowSize();
  const { header, desk } = useContext(TranslationContext);
  const { nav } = desk;
  const user = useContext(CurrentUserContext);
  const subcategory = useContext(SubcategoryContext);
  const [isDropdownOpen, setDropdownOpen] = useState({});
  const [breeds, setBreeds] = useState([]);
  const [breedsSelected, setBreedsSelected] = useState("");
  const [isSearchBarOpen, setSearchBarOpen] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [isMobileOpen, toggleMobileOpen] = useCycle(false, true);
  const [zIndex, setZIndex] = useState(0)

  useEffect(() => {
    if (!subcategory) return;

    const breedsArr = subcategory.filters.find(
      (item) => item.value === BREED_FILTER
    ).options;

    let selected = [];
    switch (true) {
      case breedsSelected === SMALL_BREEDS:
        selected = breedsArr.filter((item) => item.size <= 1);
        break;
      case breedsSelected === MEDIUM_BREEDS:
        selected = breedsArr.filter((item) => item.size === 2);
        break;
      case breedsSelected === BIG_BREEDS:
        selected = breedsArr.filter((item) => item.size >= 3);
        break;
      default:
        selected = breedsArr;
    }

    selected.sort((x, y) => x.count - y.count);
    setBreeds(selected);
  }, [breedsSelected, subcategory]);

  useEffect(() => {
    if (width > 900)
      setDropdownOpen((prevObj) => ({
        ...prevObj,
        [MOBILE_NAV_TYPE]: false,
      }));

    if (width <= 900) setSearchBarOpen(false);
  }, [width]);

  function openDropdown(type) {
    console.log(type)
    setDropdownOpen((prevObj) => ({ ...prevObj, [type]: true }));
    setZIndex(10)
  }

  function closeDropdown(type) {
    setDropdownOpen((prevObj) => ({ ...prevObj, [type]: false }));
    setZIndex(0)
  }

  function toggleNavMenu() {
    isDropdownOpen[MOBILE_NAV_TYPE]
      ? closeDropdown(MOBILE_NAV_TYPE)
      : openDropdown(MOBILE_NAV_TYPE);
    toggleMobileOpen();
  }

  function openSearchBar() {
    setSearchBarOpen(true);
    searchInput.current.focus();
  }

  function closeSearchBar() {
    setSearchBarOpen(false);
    setSearchKey("");
  }

  function selectLanguage(lang) {

    localStorage.setItem("language", lang);
    setLanguage(lang);

    if (isLoggedIn) {
      mainApi
      .setNewLanguage({
        language: lang
      })
      .then(() => {
        setUser((prevObj) => ({
          ...prevObj, 
          language: lang.toLowerCase()
        }));
      })
      .catch((err) => {
        console.log(err);
      })
    }
    if (isDropdownOpen[DROPDOWN_LANG]) closeDropdown(DROPDOWN_LANG);
  }

  function handleLogoutClick() {
    logout();
    closeDropdown(DROPDOWN_PROFILE);
  }

  function handleKeyChange(evt) {
    setSearchKey(evt.target.value);
  }

  const navigate = useNavigate()

  function handleSearchSubmit(evt) {
    evt.preventDefault();
    
    console.log(searchKey);
    navigate(`/search?value=${searchKey}`)
    setSearchKey("");
  }

  // function CatalogRow({ style, index }) {
  //   const item = breeds[index]
  //   return (
  //     <div>
  //       <div
  //         className="header__breed-item"
  //         style={style}
  //       // key={item.value}
  //       >
  //         <Link
  //           className="header__breed-link"
  //           to={`/catalog/pets/dogs/${item?.value}`}
  //           onClick={() => closeDropdown(DROPDOWN_BREEDS)}
  //         >
  //           <p className="header__breed-name">
  //             <Balancer>
  //               {
  //                 item?.title[
  //                 user
  //                   ? user.language
  //                   : language.toLowerCase()
  //                 ]
  //               }
  //             </Balancer>
  //           </p>
  //           {Boolean(item?.count) ? (
  //             <p className="header__offers-number">
  //               {item?.count}
  //             </p>
  //           ) : null}
  //         </Link>
  //       </div>
  //     </div>
  //   )
  // }

  function CatalogCell({ columnIndex, rowIndex, style }) {
    const item = breeds[rowIndex * 2 + columnIndex]
    return (
      <div>
        <div
          className="header__breed-item"
          style={style}
        // key={item.value}
        >
          <Link
            className="header__breed-link"
            to={`/catalog/pets/dogs/${item?.value}`}
            onClick={() => closeDropdown(DROPDOWN_BREEDS)}
          >
            <p className="header__breed-name">
              <Balancer>
                {
                  item?.title[
                  user
                    ? user.language
                    : language.toLowerCase()
                  ]
                }
              </Balancer>
            </p>
            {Boolean(item?.count) ? (
              <p className="header__offers-number">
                {item?.count}
              </p>
            ) : null}
          </Link>
        </div>
      </div>
    )
  }

  return (
    <header className="header" style={{zIndex: zIndex}}>
      <div className="header__container">
        <div className="header__decor-container">
          <div className="header__decor-content">
            <div className="header__decor-box">
              <div className="header__decor-circle header__decor-circle_type_big" />
              <div className="header__decor-circle header__decor-circle_type_top" />
              <div className="header__decor-circle header__decor-circle_type_bottom" />
            </div>
          </div>
        </div>

        <div className="header__content">
          <motion.div
            className="header__box-with-dropdown header__box-with-dropdown_type_mobile-nav"
            initial={false}
            animate={isDropdownOpen[MOBILE_NAV_TYPE] ? "open" : "closed"}
          >
            <button
              className="header__menu-btn"
              type="button"
              aria-label="Open mobile menu"
              onClick={toggleNavMenu}
              animate={isMobileOpen ? "open" : "closed"}
            >
              <svg
                className="header__menu-icon"
                width="22"
                height="18"
                viewBox="0 0 22 18"
              >
                <Path
                  variants={{
                    closed: { d: "M21 1L1 1.20605H1" },
                    open: { d: "M17 1L1 17H1" },
                  }}
                />
                <Path
                  d="M21 9.12677H1"
                  variants={{
                    closed: { opacity: 1 },
                    open: { opacity: 0 },
                  }}
                  transition={{ duration: 0.1 }}
                />
                <Path
                  variants={{
                    closed: { d: "M21 17L1 17" },
                    open: { d: "M17 17L1 1" },
                  }}
                />
              </svg>
            </button>
            <DropdownPopup
              isOpen={isDropdownOpen[MOBILE_NAV_TYPE]}
              onClose={closeDropdown}
              type={MOBILE_NAV_TYPE}
              isLangOpen={isDropdownOpen[DROPDOWN_LANG]}
              navList={[
                {
                  link: "/proven-breeders/pets/dogs",
                  icon: ProvenBreedersIcon,
                  text: header.breeders,
                },
                // {
                //   link: "/before-buy",
                //   icon: BeforeBuyIcon,
                //   text: header.beforeBuy,
                // },
                // {
                //   link: "/adopt",
                //   icon: AdoptIcon,
                //   text: header.adopt,
                // },
                // {
                //   link: "/forum",
                //   icon: ForumIcon,
                //   text: header.forum,
                // },
                {
                  link: "/blog",
                  icon: BlogIcon,
                  text: header.blog,
                },
              ]}
              {...{
                dropdownVariants,
                itemVariants,
                searchKey,
                handleKeyChange,
                handleSearchSubmit,
                breedsSelected,
                setBreedsSelected,
                breeds,
                language,
                openDropdown,
                selectLanguage,
              }}
            />
          </motion.div>

          <Link className="header__logo-link" to="/">
            {LogoIcon(
              "header__logo",
              "header__logo-main-fill",
              "header__logo-stroke",
              "header__logo-secondary-fill"
            )}
          </Link>

          <nav className="header__nav">
            <ul className="header__nav-list">
              <motion.li
                className="header__nav-item"
                initial={false}
                animate={isSearchBarOpen ? "open" : "closed"}
              >
                <button
                  className="header__search-btn"
                  type="button"
                  aria-label="Open search bar"
                  onClick={openSearchBar}
                >
                  {SearchIcon(
                    "header__search-icon",
                    "header__search-icon-stroke"
                  )}
                </button>
                <motion.div
                  className={`header__search-bar ${isLoggedIn
                    ? user.user_type !== BUYER_USER_TYPE
                      ? "header__search-bar_type_breeder"
                      : ""
                    : ""
                    } ${!isLoggedIn ? "header__search-bar_type_auth" : ""}`}
                  variants={searchBarVariants}
                  style={{
                    pointerEvents: isSearchBarOpen ? "auto" : "none",
                  }}
                >
                  <form
                    className="header__search-form"
                    onSubmit={handleSearchSubmit}
                  >
                    <div className="header__search-icon-box">
                      {SearchIcon(
                        "header__search-icon",
                        "header__search-icon-stroke"
                      )}
                    </div>
                    <input
                      ref={searchInput}
                      className="header__search-input"
                      id="globalSearch"
                      name="search"
                      type="text"
                      autoComplete="off"
                      placeholder={header.search}
                      value={searchKey || ""}
                      onChange={handleKeyChange}
                    />
                    <button className="header__find-btn" type="submit">
                      <p className="header__find-btn-text">
                        {header.searchBtn}
                      </p>
                    </button>
                  </form>
                  <button
                    className="header__search-close-btn"
                    type="button"
                    aria-label="Close search bar"
                    onClick={closeSearchBar}
                  >
                    {CloseIcon(
                      "header__close-icon",
                      "header__close-icon-stroke"
                    )}
                  </button>
                </motion.div>
              </motion.li>

              {!isLoggedIn || (user && user.user_type === BUYER_USER_TYPE) ? (
                <motion.li
                  className="header__nav-item header__nav-item_type_breeds"
                  initial={false}
                  animate={isDropdownOpen[DROPDOWN_BREEDS] ? "open" : "closed"}
                >
                  <button
                    className="header__dropdown-btn"
                    type="button"
                    onClick={() => openDropdown(DROPDOWN_BREEDS)}
                  >
                    <div className="header__burger-icon-box">
                      {SmallBurgerIcon({
                        mainClassName: "header__breed-burger-icon",
                        strokeClassName: "header__breed-burger-icon-stroke",
                      })}
                    </div>
                    <p className="header__text">{header.breeds}</p>
                  </button>
                  <DropdownPopup
                    isOpen={isDropdownOpen[DROPDOWN_BREEDS]}
                    onClose={closeDropdown}
                    type={DROPDOWN_BREEDS}
                    {...{ language }}
                  >
                    <motion.div
                      className="header__breeds-container"
                      variants={dropdownVariants}
                      style={{
                        pointerEvents: isDropdownOpen[DROPDOWN_BREEDS]
                          ? "auto"
                          : "none",
                      }}
                    >
                      <div className="header__selectors-block">
                        <ul className="header__selectors-list">
                          <motion.li
                            className="header__selector-item"
                            variants={itemVariants}
                          >
                            <button
                              className={`header__selector-btn ${breedsSelected === ""
                                ? "header__selector-btn_selected"
                                : ""
                                }`}
                              type="button"
                              onClick={() => setBreedsSelected("")}
                            >
                              {header.allDogs}
                            </button>
                          </motion.li>
                          <motion.li
                            className="header__selector-item"
                            variants={itemVariants}
                          >
                            <button
                              className={`header__selector-btn ${breedsSelected === SMALL_BREEDS
                                ? "header__selector-btn_selected"
                                : ""
                                }`}
                              type="button"
                              onClick={() => setBreedsSelected(SMALL_BREEDS)}
                            >
                              {header.smallDogs}
                            </button>
                          </motion.li>
                          <motion.li
                            className="header__selector-item"
                            variants={itemVariants}
                          >
                            <button
                              className={`header__selector-btn ${breedsSelected === MEDIUM_BREEDS
                                ? "header__selector-btn_selected"
                                : ""
                                }`}
                              type="button"
                              onClick={() => setBreedsSelected(MEDIUM_BREEDS)}
                            >
                              {header.mediumDogs}
                            </button>
                          </motion.li>
                          <motion.li
                            className="header__selector-item"
                            variants={itemVariants}
                          >
                            <button
                              className={`header__selector-btn ${breedsSelected === BIG_BREEDS
                                ? "header__selector-btn_selected"
                                : ""
                                }`}
                              type="button"
                              onClick={() => setBreedsSelected(BIG_BREEDS)}
                            >
                              {header.bigDogs}
                            </button>
                          </motion.li>
                        </ul>

                        <motion.img
                          className="header__breeds-img"
                          src={dog}
                          alt=""
                          variants={itemVariants}
                        />
                        <div className="header__breeds-border" />
                      </div>

                      <motion.div
                        className="header__breeds-block"
                        variants={itemVariants}
                      >
                        <AnimatePresence mode="wait">
                          {breeds ?
                            <AutoSizer>
                              {({ height, width }) => (
                                <Grid
                                  key={breedsSelected}
                                  columnCount={2}
                                  columnWidth={width / 2}
                                  height={height}
                                  rowCount={breeds ? Math.ceil(breeds.length / 2) : 0}
                                  rowHeight={35}
                                  width={width}
                                >
                                  {CatalogCell}
                                </Grid>
                              )}
                            </AutoSizer>
                            : null}
                        </AnimatePresence>
                      </motion.div>
                    </motion.div>
                  </DropdownPopup>
                </motion.li>
              ) : null}

              <li className="header__nav-item">
                <NavLink
                  className={({ isActive }) =>
                    "header__link" + (isActive ? " header__link_selected" : "")
                  }
                  to="/proven-breeders/pets/dogs"
                >
                  <p className="header__text">{header.breeders}</p>
                </NavLink>
              </li>

              <motion.li
                className="header__nav-item"
                initial={false}
                animate={isDropdownOpen[DROPDOWN_COMMUNITY] ? "open" : "closed"}
              >
                <button
                  className="header__dropdown-btn"
                  type="button"
                  onClick={() => openDropdown(DROPDOWN_COMMUNITY)}
                >
                  <p className="header__text">{header.community}</p>
                </button>
                <DropdownPopup
                  isOpen={isDropdownOpen[DROPDOWN_COMMUNITY]}
                  onClose={closeDropdown}
                  type={DROPDOWN_COMMUNITY}
                 
                  navList={[
                    // {
                    //   link: "/before-buy",
                    //   icon: BeforeBuyIcon,
                    //   text: header.beforeBuy,
                    // },
                    // {
                    //   link: "/adopt",
                    //   icon: AdoptIcon,
                    //   text: header.adopt,
                    // },
                    // {
                    //   link: "/forum",
                    //   icon: ForumIcon,
                    //   text: header.forum,
                    // },
                    {
                      link: "/blog",
                      icon: BlogIcon,
                      text: header.blog,
                    },
                  ]}
                  {...{ dropdownVariants, itemVariants, language }}
                />
              </motion.li>
            </ul>
          </nav>

          <div className="header__options">
            {!isLoggedIn ? (
              <div className="header__btns-block header__btns-block_type_auth">
                <button
                  className="header__btn header__btn_type_reg"
                  type="button"
                  onClick={() => handleAuthOpen(REG_AUTH_TYPE)}
                >
                  {header.registration}
                </button>

                <button
                  className="header__btn header__btn_type_login"
                  type="button"
                  onClick={() => handleAuthOpen(LOGIN_AUTH_TYPE)}
                >
                  {header.login}
                </button>

                <button
                  className="header__btn header__btn_type_login-mobile"
                  type="button"
                  aria-label={header.login}
                  onClick={() => handleAuthOpen(LOGIN_AUTH_TYPE)}
                >
                  {LoginIcon({
                    mainClassName: "header__login-icon",
                    fillClassName: "header__login-icon-fill",
                  })}
                </button>
              </div>
            ) : (
              <div className="header__btns-block">
                {user.user_type !== BUYER_USER_TYPE ? (
                  <Link
                    className="header__btn header__btn_type_advert"
                    to={`/new-advert`}
                  >
                    {header.advert}
                  </Link>
                ) : null}

                <motion.div
                  className="header__box-with-dropdown"
                  initial={false}
                  animate={isDropdownOpen[DROPDOWN_PROFILE] ? "open" : "closed"}
                >
                  <button
                    className="header__dropdown-btn header__dropdown-btn_type_profile"
                    type="button"
                    onClick={() => openDropdown(DROPDOWN_PROFILE)}
                  >
                    <div className="header__avatar-box">
                      <AvatarBox
                        avatar={
                          user.avatar
                            ? `${MAIN_URL}/get-file/${user.avatar.file_path.orig}`
                            : null
                        }
                      />
                      {/* {Number(messagesNumber) !== 0 ? (
                        <div className="header__new-messages header__new-messages_type_avatar" />
                      ) : null} */}
                    </div>
                    <p className="header__text">
                      {width > 900 ? user.name : user.name.split(" ")[0]}
                    </p>
                    <motion.div
                      className="header__icon-box"
                      variants={arrowVariants}
                    >
                      {SimpleArrowIcon(
                        "header__arrow-icon",
                        "header__arrow-icon-stroke"
                      )}
                    </motion.div>
                  </button>
                  <DropdownPopup
                    isOpen={isDropdownOpen[DROPDOWN_PROFILE]}
                    onClose={closeDropdown}
                    type={DROPDOWN_PROFILE}
                    onLogout={handleLogoutClick}
                    isLangOpen={isDropdownOpen[DROPDOWN_LANG]}
                    navList={[
                      {
                        link: "/desk/chats",
                        icon: ChatIcon,
                        text: nav.chats,
                        amount: user.stats.unread_messages,
                      },
                      {
                        link: "/desk/meets",
                        icon: MeetingsIcon,
                        text: nav.meetings,
                      },
                      {
                        link: "/desk/deposits",
                        icon: DepositIcon,
                        text: nav.deposits,
                      },
                      {
                        link: "/desk/favourites",
                        icon: FavoritesIcon,
                        text: nav.favorites,
                        // amount: favoritesNumber,
                      },
                      {
                        link: "/desk/breeders",
                        icon: MeetsIcon,
                        text: nav.breeders,
                      },
                      {
                        link: "/desk/adverts",
                        icon: MeetsIcon,
                        text: nav.adverts,
                      },
                      {
                        link: "/desk/subscription",
                        icon: SubscriptionIcon,
                        text: nav.subscription,
                      },
                      {
                        link: "/desk/profile",
                        breader_link:
                          user.user_type === BREEDER_USER_TYPE
                            ? `/breeder-profile/${user._id}/adverts`
                            : undefined,
                        icon: ProfileIcon,
                        text: nav.profile,
                      },
                    ]}
                    {...{
                      dropdownVariants,
                      itemVariants,
                      openDropdown,
                      language,
                      selectLanguage,
                    }}
                  />
                </motion.div>
              </div>
            )}

            <motion.div
              className="header__box-with-dropdown header__box-with-dropdown_type_lang"
              initial={false}
              animate={isDropdownOpen[DROPDOWN_LANG] ? "open" : "closed"}
            >
              <button
                className="header__dropdown-btn header__dropdown-btn_type_lang"
                type="button"
                onClick={() => openDropdown(DROPDOWN_LANG)}
              >
                <div className="header__icon-box header__icon-box_type_lang">
                  {LANGUAGES[language].flag}
                </div>
                <p className="header__text">{LANGUAGES[language].lang}</p>
                <motion.div
                  className="header__icon-box"
                  variants={arrowVariants}
                >
                  {SimpleArrowIcon(
                    "header__arrow-icon",
                    "header__arrow-icon-stroke"
                  )}
                </motion.div>
              </button>
              <DropdownPopup
                isOpen={isDropdownOpen[DROPDOWN_LANG]}
                onClose={closeDropdown}
                type={DROPDOWN_LANG}
                {...{ language }}
              >
                <motion.ul
                  className="header__dropdown-list header__dropdown-list_type_lang"
                  variants={dropdownVariants}
                  style={{
                    pointerEvents: isDropdownOpen[DROPDOWN_LANG]
                      ? "auto"
                      : "none",
                  }}
                >
                  <motion.li
                    className="header__dropdown-item"
                    variants={itemVariants}
                  >
                    <button
                      className={`header__lang-btn ${language === FR_LANGUAGE
                        ? "header__lang-btn_selected"
                        : ""
                        }`}
                      // style={{ opacity: 0.4, pointerEvents: "none" }}
                      type="button"
                      onClick={() => selectLanguage(FR_LANGUAGE)}
                    >
                      <div className="header__icon-box header__icon-box_type_lang">
                        {LANGUAGES[FR_LANGUAGE].flag}
                      </div>
                      <p className="header__text header__text_type_lang">
                        {LANGUAGES[FR_LANGUAGE].lang}
                      </p>
                      <div className="header__icon-box header__icon-box_type_check">
                        {CheckIcon(
                          "header__check-icon",
                          "header__check-icon-stroke"
                        )}
                      </div>
                    </button>
                  </motion.li>
                  <div className="header__divider-line" />
                  <motion.li
                    className="header__dropdown-item"
                    variants={itemVariants}
                  >
                    <button
                      className={`header__lang-btn ${language === EN_LANGUAGE
                        ? "header__lang-btn_selected"
                        : ""
                        }`}
                      type="button"
                      onClick={() => selectLanguage(EN_LANGUAGE)}
                    >
                      <div className="header__icon-box header__icon-box_type_lang">
                        {LANGUAGES[EN_LANGUAGE].flag}
                      </div>
                      <p className="header__text header__text_type_lang">
                        {LANGUAGES[EN_LANGUAGE].lang}
                      </p>
                      <div className="header__icon-box header__icon-box_type_check">
                        {CheckIcon(
                          "header__check-icon",
                          "header__check-icon-stroke"
                        )}
                      </div>
                    </button>
                  </motion.li>
                </motion.ul>
              </DropdownPopup>
            </motion.div>
          </div>
        </div>
      </div>
    </header >
  );
}

export default Header;
