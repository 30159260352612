/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useContext, useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import validator from "validator";
import { TranslationContext } from "../../../assets/contexts/translationContext";
import Auth from "../Auth";
import UserTypeSelector from "../UserTypeSelector/UserTypeSelector";
import InputWithFormik from "../../InputWithFormik/InputWithFormik";
import CheckboxWithFormik from "../../CheckboxWithFormik/CheckboxWithFormik";
import AuthSubmitBtn from "../AuthSubmitBtn/AuthSubmitBtn";
import {
  REG_AUTH_TYPE,
  LOGIN_AUTH_TYPE,
  RECOVERY_AUTH_TYPE,
  BUYER_USER_TYPE,
  BREEDER_USER_TYPE,
  NOT_FOUND_ERR_CODE,
  // SHELTER_USER_TYPE,
} from "../../../assets/utils/constants";
import pattern from "../../../assets/images/auth/login-pattern.png";
import image from "../../../assets/images/auth/login.png";
import "./Login.css";

function Login({
  isAuthOpen,
  onAuthClose,
  onAuthOpen,
  isAuthSwitch,
  setAuthSwitch,
  signin,
  isUserExist,
  setIsUserExist,
  checkUserExistence,
  isPrelouderVisible,
  handleResendCode,
  apiError,
}) {
  const { auth, errors, labels } = useContext(TranslationContext);
  const { login } = auth;
  const {
    title,
    imageTitle,
    imageText,
    submitText,
    redirectText,
    redirectLink,
    reminderText,
  } = login;
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState("");
  const [userType, setUserType] = useState(BUYER_USER_TYPE);
  const [apiErrText, setApiErrText] = useState("");

  const initialValues = {
    userType: BUYER_USER_TYPE,
    email: "",
    password: "",
    rememberMe: false,
  };

  const validationSchema = Yup.object({
    userType: Yup.string()
      .oneOf([
        BUYER_USER_TYPE,
        BREEDER_USER_TYPE,
        //  SHELTER_USER_TYPE
      ])
      .required(),
    email: Yup.string().email(errors.email).required(errors.required),
    password: Yup.string().min(8, errors.min).required(errors.required),
    rememberMe: Yup.boolean(),
  });

  useEffect(() => {
    setAuthSwitch(false);
    setIsUserExist(false);
  }, []);

  useEffect(() => {
    switch (true) {
      case apiError === NOT_FOUND_ERR_CODE:
        setApiErrText(errors.login404);
        break;
      case apiError !== "":
        setApiErrText(errors.apiErr);
        break;
      default:
        setApiErrText("");
    }
  }, [apiError]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (validator.isEmail(email))
        checkUserExistence({ email, user_type: userType });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [email, userType]);

  function togglePasswordVisibility() {
    setPasswordVisible(!isPasswordVisible);
  }

  function redirectAuth() {
    onAuthClose(LOGIN_AUTH_TYPE);
    onAuthOpen(REG_AUTH_TYPE);
    setAuthSwitch(true);
  }

  function closeAuth() {
    onAuthClose(LOGIN_AUTH_TYPE);
  }

  function openPasswordRecovery() {
    onAuthClose(LOGIN_AUTH_TYPE);
    onAuthOpen(RECOVERY_AUTH_TYPE);
    setAuthSwitch(true);
  }

  function handleEmailChange(evt, setFieldValue) {
    const input = evt.target;
    const name = input.name;
    const value = input.value;
    setFieldValue(name, value);
    setEmail(value);
  }

  return (
    <Auth
      type={LOGIN_AUTH_TYPE}
      bottomPattern={pattern}
      isBackBtnVisible={false}
      isCloseBtnVisible={true}
      onClose={closeAuth}
      onRedirect={redirectAuth}
      {...{
        imageTitle,
        imageText,
        image,
        title,
        redirectText,
        redirectLink,
        isAuthOpen,
        isAuthSwitch,
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          signin(values, setSubmitting, LOGIN_AUTH_TYPE);
        }}
      >
        {({ values, isValid, dirty, isSubmitting, setFieldValue }) => (
          <Form className="auth__form auth__form_type_login">
            <div className="auth__type-selector-block">
              <UserTypeSelector
                id="userType"
                name="userType"
                {...{ setFieldValue, setUserType }}
              />
            </div>

            <div className="auth__inputs-block">
              <InputWithFormik
                label={labels.email}
                id="email"
                name="email"
                type="email"
                onChange={handleEmailChange}
                {...{ isUserExist, values, setFieldValue }}
              />

              <InputWithFormik
                label={labels.password}
                id="password"
                name="password"
                type={isPasswordVisible ? "text" : "password"}
                onPasswordToggle={togglePasswordVisibility}
                {...{ isPasswordVisible }}
              />
            </div>

            <div className="auth__checkbox-block">
              <div className="auth__checkbox-container">
                <CheckboxWithFormik
                  label={labels.rememberMe}
                  id="rememberMe"
                  name="rememberMe"
                  type={LOGIN_AUTH_TYPE}
                />
                <div className="auth__divider-line auth__divider-line_type_checkbox" />
                <button
                  className="auth__redirect-link auth__redirect-link_type_recovery"
                  type="button"
                  onClick={openPasswordRecovery}
                >
                  <p className="auth__redirect-link-text">{reminderText}</p>
                </button>
              </div>
            </div>

            <div className="auth__submit-btn-block">
              <AuthSubmitBtn
                text={submitText}
                isEmailValid={isUserExist}
                apiError={apiErrText}
                {...{
                  isValid,
                  dirty,
                  isSubmitting,
                  isPrelouderVisible,
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Auth>
  );
}

export default Login;
