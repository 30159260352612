import { useContext } from "react";
import { useField } from "formik";
import { TranslationContext } from "../../assets/contexts/translationContext";
import "./SwitchCheckboxWithFormik.css";

function SwitchCheckboxWithFormik({ label, type, ...props }) {
  const [field] = useField({ ...props, type: "checkbox" });
  const { labels } = useContext(TranslationContext);
  const { yes, no } = labels;

  return (
    <div
      className={`formik-switch ${
        Boolean(type) ? `formik-switch_type_${type}` : ""
      }`}
    >
      <span className="formik-switch__label">{label}</span>
      <label className="formik-switch__box">
        <input
          className="formik-switch__invisible-input"
          type="checkbox"
          {...field}
          {...props}
        />
        <span
          className={`formik-switch__pseudo-item ${
            field.checked ? "formik-switch__pseudo-item_checked" : ""
          }`}
        >
          <div className="formik-switch__btns">
            <div
              className={`formik-switch__btn ${
                field.checked ? "formik-switch__btn_active" : ""
              }`}
            >
              <p className="formik-switch__btn-text">{yes}</p>
            </div>
            <div
              className={`formik-switch__btn ${
                !field.checked ? "formik-switch__btn_active" : ""
              }`}
            >
              <p className="formik-switch__btn-text">{no}</p>
            </div>
          </div>
        </span>
      </label>
    </div>
  );
}

export default SwitchCheckboxWithFormik;
