import { useEffect, useRef } from "react";
import Balancer from "react-wrap-balancer";
import usePreventScroll from "../../../assets/hooks/usePreventScroll";
import { CloseIcon } from "../../../assets/icons/icons";
import { ADD_BREED_POPUP } from "../../../assets/utils/constants";
import "./DeskPopup.css";

function DeskPopup({
  isOpen,
  onClose,
  title,
  subtitle,
  children,
  type,
  isDepositPopup,
}) {
  const overlay = useRef();
  usePreventScroll(isOpen && type !== ADD_BREED_POPUP);

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });

  return (
    <div
      className={`desk-popup ${isOpen ? "desk-popup_opened" : ""} ${
        type && type === ADD_BREED_POPUP ? "desk-popup_type_breed" : ""
      } ${isDepositPopup ? "desk-popup_type_deposit" : ""}`}
    >
      <div className="desk-popup__container">
        <button
          className="desk-popup__close-btn"
          type="button"
          aria-label="Close popup"
          onClick={onClose}
        >
          {CloseIcon("desk-popup__close-icon", "desk-popup__close-icon-stroke")}
        </button>
        <p className="desk-popup__title">{title}</p>
        {subtitle ? (
          <p className="desk-popup__subtitle">
            <Balancer>{subtitle}</Balancer>
          </p>
        ) : null}
        {children}
      </div>

      <div
        className="desk-popup__overlay"
        ref={overlay}
        onTouchStart={onClose}
      />
    </div>
  );
}

export default DeskPopup;
