import { useEffect, useState, lazy, Suspense } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import {
  TranslationContext,
  translations,
} from "../../assets/contexts/translationContext";
import { SubcategoryContext } from "../../assets/contexts/subcategoryContext";
import { CurrentUserContext } from "../../assets/contexts/currentUserContext";
import { ProSubscriptionContext } from "../../assets/contexts/proSubscriptionContext";
import mainApi from "../../assets/api/MainApi";
import useMainWebsocket from "../../assets/hooks/useMainWebsocket";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import Registration from "../Auth/Registration/Registration";
import Login from "../Auth/Login/Login";
import Recovery from "../Auth/Recovery/Recovery";
import NewPassword from "../Auth/NewPassword/NewPassword";
import MiniPreloader from "../MiniPreloader/MiniPreloader";
import usePreventScroll from "../../assets/hooks/usePreventScroll";
import {
  BREEDER_USER_TYPE,
  BUYER_USER_TYPE,
  CHECK_CODE_POPUP,
  EN_LANGUAGE,
  ERROR_VISIBILITY_TIME,
  FR_LANGUAGE,
  LOGIN_AUTH_TYPE,
  REG_AUTH_TYPE,
  WS_MAIN_URL,
} from "../../assets/utils/constants";
import "./App.css";
import Search from "../Search/Search";

const Main = lazy(() => import("../Main/Main"));
const Blog = lazy(() => import("../Blog/Blog"));
const Article = lazy(() => import("../Blog/Article/Article"));
const Catalog = lazy(() => import("../Catalog/Catalog"));
const ProvenBreeders = lazy(() => import("../ProvenBreeders/ProvenBreeders"));
const Breeders = lazy(() => import("../ProvenBreeders/Breeders/Breeders"));
const BreederProfile = lazy(() => import("../BreederProfile/BreederProfile"));
const Desk = lazy(() => import("../Desk/Desk"));
const Advert = lazy(() => import("../Advert/Advert"));
const ErrorPage = lazy(() => import("../ErrorPage/ErrorPage"));
const AboutUs = lazy(() => import("../AboutUs/AboutUs"));

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState(
    localStorage.getItem("language") !== null
      ? localStorage.getItem("language")
      : FR_LANGUAGE
  );
  const [proSubscriptions, setProSubscriprions] = useState(undefined);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(undefined);
  const [isAuthOpen, setAuthOpen] = useState({});
  const [isAuthSwitch, setAuthSwitch] = useState(false);
  const [regStep, setRegStep] = useState(0);
  const [recoveryStep, setRecoveryStep] = useState(0);
  const [addressHints, setAddressHints] = useState([]);
  const [isPrelouderVisible, setIsPrelouderVisible] = useState(false);
  const [isMainPreloaderVisible, setIsMainPreloaderVisible] = useState(false);
  const [isResendPreloader, setIsResendPreloader] = useState(false);
  const [isEmailPrelouder, setIsEmailPrelouder] = useState(false);
  // const [categories, setCategories] = useState([]);
  // const [subcategories, setSubcategories] = useState(undefined);
  const [subcategoryInfo, setSubcategoryInfo] = useState(undefined);
  const [isUserExist, setIsUserExist] = useState(false);
  const [isPreventAnim, setIsPreventAnim] = useState(false);
  const [apiError, setApiError] = useState("");
  const [regValues, setRegValues] = useState(null);
  const [mainWsMessages, setMainWsMessages] = useState([]);
  const mainWS = useMainWebsocket({ id: user ? user._id : null });
  usePreventScroll(Object.values(isAuthOpen).some((popup) => popup));

  useEffect(() => {
    if (!user || !user.language) return;
    setLanguage(user.language.toUpperCase());
    localStorage.setItem("language", user.language.toUpperCase());
  }, [user]);

  useEffect(() => {
    setIsMainPreloaderVisible(true);
    mainApi
      .getUser()
      .then((res) => {
        setUser({ ...res });
        switch (true) {
          case !res.registered && res.register_stage === 0:
            setRegStep(1);
            handleAuthOpen(REG_AUTH_TYPE);
            break;
          case !res.registered && res.register_stage === 1:
            setRegStep(2);
            handleAuthOpen(REG_AUTH_TYPE);
            break;
          default:
            setLoggedIn(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setUser(undefined);
        setLoggedIn(false);
        if (location.pathname.includes("desk")) navigate("/");
      })
      .finally(() => {
        setIsMainPreloaderVisible(false);
      });

    mainApi
      .getCategories({})
      .then((res) => {
        // setCategories(res.data);
        const category = res.data[0].type;
        mainApi
          .getAllSubcategories({ category })
          .then((res) => {
            const subcategory = res.data[0].type;
            // setSubcategories(res);
            mainApi
              .getExactSubcategories({ category, subcategory })
              .then((res) => {
                setSubcategoryInfo(res);
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
    mainApi
      .getSubscriptions({
        limit: 25,
        type: "pro",
      })
      .then((res) => {
        console.log(res);
        setProSubscriprions(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!location.pathname.includes("breeders")) setIsPreventAnim(false);
  }, [location]);

  function handleAuthOpen(popup) {
    setAuthOpen((prevObj) => ({ ...prevObj, [popup]: true }));
  }

  function handleAuthClose(popup) {
    setAuthOpen((prevObj) => ({ ...prevObj, [popup]: false }));
  }

  function signupBuyer(values, setSubmitting) {
    setRegValues(values);
    setIsPrelouderVisible(true);
    mainApi
      .signupBuyer({
        name: values.name,
        email: values.email,
        password: values.password,
        language: language.toLocaleLowerCase(),
      })
      .then((res) => {
        setUser({ ...res, user_type: values.userType });
        setRegStep(regStep + 1);
      })
      .catch((err) => {
        console.log(err);
        setApiError(err.statusCode);
        setTimeout(() => setApiError(""), ERROR_VISIBILITY_TIME);
      })
      .finally(() => {
        setSubmitting(false);
        setIsPrelouderVisible(false);
      });
  }

  function signupBreederStepOne(values, setSubmitting) {
    setRegValues(values);
    setIsPrelouderVisible(true);
    mainApi
      .signupBreederStepOne({
        name: values.name,
        email: values.email,
        password: values.password,
        language: language.toLocaleLowerCase(),
      })
      .then((res) => {
        setUser({ ...res, user_type: values.userType });
        setRegStep(regStep + 1);
      })
      .catch((err) => {
        console.log(err);
        setApiError(err.statusCode);
        setTimeout(() => setApiError(""), ERROR_VISIBILITY_TIME);
      })
      .finally(() => {
        setSubmitting(false);
        setIsPrelouderVisible(false);
      });
  }

  function signupBreederStepTwo(values, setSubmitting, popup) {
    const category = subcategoryInfo.category.type;
    const subcategory = subcategoryInfo.type;
    setIsPrelouderVisible(true);
    mainApi
      .signupBreederStepTwo({ values, category, subcategory })
      .then((res) => {
        setUser((prevObj) => ({
          ...prevObj,
          ...res,
          user_type: BREEDER_USER_TYPE,
        }));
        setLoggedIn(true);
        setRegStep(0);
        handleAuthClose(popup);
      })
      .catch((err) => {
        console.log(err);
        setApiError(err.statusCode);
        setTimeout(() => setApiError(""), ERROR_VISIBILITY_TIME);
      })
      .finally(() => {
        setSubmitting(false);
        setIsPrelouderVisible(false);
      });
  }

  function checkEmailCode(values, setSubmitting, popup, closePopup) {
    setIsPrelouderVisible(true);
    mainApi
      .checkCode({ code: values.code })
      .then((res) => {
        setRegValues(null);
        setUser((prevObj) => ({
          ...prevObj,
          ...res,
          user_type: prevObj.user_type,
        }));
        if (user.user_type === BUYER_USER_TYPE) handleAuthClose(popup);
        if (popup !== CHECK_CODE_POPUP) {
          if (user.user_type === BUYER_USER_TYPE) {
            setLoggedIn(true);
            setRegStep(0);
          } else {
            setRegStep(regStep + 1);
          }
        } else {
          closePopup();
          setIsEmailPrelouder(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setApiError(err.statusCode);
        setTimeout(() => setApiError(""), ERROR_VISIBILITY_TIME);
      })
      .finally(() => {
        setSubmitting(false);
        setIsPrelouderVisible(false);
      });
  }

  function signin(values, setSubmitting, popup) {
    setIsPrelouderVisible(true);
    mainApi
      .login({
        user_type: values.userType,
        email: values.email,
        password: values.password,
        rememberMe: values.rememberMe,
      })
      .then((res) => {
        setUser({ ...res, user_type: values.userType });
        setLoggedIn(true);
        handleAuthClose(popup);

        if (
          values.userType === BREEDER_USER_TYPE &&
          res.register_stage === 1 &&
          !res.registered
        ) {
          setRegStep(2);
          handleAuthOpen(REG_AUTH_TYPE);
        }
      })
      .catch((err) => {
        console.log(err);
        setApiError(err.statusCode);
        setTimeout(() => setApiError(""), ERROR_VISIBILITY_TIME);
      })
      .finally(() => {
        setSubmitting(false);
        setIsPrelouderVisible(false);
      });
  }

  function logout() {
    mainApi
      .logout()
      .then(() => {
        setUser(undefined);
        setLoggedIn(false);
        setRegStep(0);
        if (location.pathname.includes("desk")) navigate("/");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function checkUserExistence({ email, user_type }) {
    mainApi
      .checkUserExistence({ email, user_type })
      .then(() => {
        setIsUserExist(false);
      })
      .catch((err) => {
        setIsUserExist(err.statusCode === 400);
      });
  }

  function forgotPasswordRecovery(values, setSubmitting) {
    setIsPrelouderVisible(true);
    mainApi
      .forgotPasswordWithoutAuth({
        email: values.email,
        user_type: values.userType,
      })
      .then(() => {
        setRecoveryStep(1);
      })
      .catch((err) => {
        console.log(err);
        setApiError(err.statusCode);
        setTimeout(() => setApiError(""), ERROR_VISIBILITY_TIME);
      })
      .finally(() => {
        setSubmitting(false);
        setIsPrelouderVisible(false);
      });
  }

  function resetPassword(values, emailToken, setSubmitting) {
    setIsPrelouderVisible(true);
    mainApi
      .resetPassword({ email_token: emailToken, password: values.newPassword })
      .then(() => {
        navigate("/");
        handleAuthOpen(LOGIN_AUTH_TYPE);
      })
      .catch((err) => {
        console.log(err);
        setApiError(err.statusCode);
        setTimeout(() => setApiError(""), ERROR_VISIBILITY_TIME);
      })
      .finally(() => {
        setSubmitting(false);
        setIsPrelouderVisible(false);
      });
  }

  function suggestAddress({ searchKey, types }) {
    // setIsPrelouderVisible(true);
    mainApi
      .suggestAddress({ searchKey, types })
      .then((res) => {
        setAddressHints(res.data);
      })
      .catch((err) => {
        console.log(err);
        setAddressHints([]);
      })
      .finally(() => {
        setIsPrelouderVisible(false);
      });
  }

  function handleResendCode() {
    if (user.register_stage === 0) {
      if (user.user_type === BUYER_USER_TYPE) {
        setIsResendPreloader(true);
        mainApi
          .signupBuyer({
            name: regValues.name,
            email: regValues.email,
            password: regValues.password,
            language: language.toLocaleLowerCase(),
          })
          .then((res) => {
            setUser((prevValue) => ({
              ...prevValue,
              ...res,
              user_type: prevValue.user_type,
            }));
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setIsResendPreloader(false);
          });
      }

      if (user.user_type === BREEDER_USER_TYPE) {
        setIsResendPreloader(true);
        mainApi
          .signupBreederStepOne({
            name: regValues.name,
            email: regValues.email,
            password: regValues.password,
            language: language.toLocaleLowerCase(),
          })
          .then((res) => {
            setUser((prevValue) => ({
              ...prevValue,
              ...res,
              user_type: prevValue.user_type,
            }));
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setIsResendPreloader(false);
          });
      }
    } else {
      setIsResendPreloader(true);
      mainApi
        .changeEmail({ email: user.new_email.email })
        .then((res) => {
          setUser((prevValue) => ({
            ...prevValue,
            ...res,
            user_type: prevValue.user_type,
          }));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setIsResendPreloader(false);
        });
    }
  }

  useEffect(() => {
    if (!mainWS) return;

    mainWS.onmessage = (e) => {
      if (
        e.data !== "Missing CSRF Token" &&
        e.data !== "Missing cookie access_token_cookie" &&
        e.data !== "Missing cookie refresh_token_cookie" &&
        e.data !== "Could not find the current user"
      ) {
        const res = JSON.parse(e.data);

        switch (res.type) {
          case "notification":
            switch (res.data.type) {
              case "new_message":
                if (
                  ["/chats"].findIndex((path) => {
                    return location.pathname.indexOf(path) <= 0;
                  })
                ) {
                  const NEW_MAIL_CONTACTS = {
                    ...res,
                    data: {
                      ...res.data,
                    },
                    type: res.data.type + "_contacts",
                    _id: res.data.type + "_contacts" + res._id,
                  };

                  if (
                    ["im/"].findIndex((path) => {
                      return location.pathname.indexOf(path) <= 0;
                    })
                  ) {
                    const NEW_MAIL = {
                      ...res,
                      data: {
                        ...res.data,
                      },
                      type: res.data.type + "_mail",
                      _id: res.data.type + "_mail" + res._id,
                    };
                    setMainWsMessages((prevValue) => [
                      ...prevValue,
                      NEW_MAIL_CONTACTS,
                      NEW_MAIL,
                    ]);
                  } else {
                    setMainWsMessages((prevValue) => [
                      ...prevValue,
                      NEW_MAIL_CONTACTS,
                    ]);
                  }

                  if (res.data.type !== "new_read_mark_2") {
                    setUser((prevValue) => ({
                      ...prevValue,
                      stats: {
                        ...prevValue.stats,
                        unread_messages: res.data.payload.extra_data.unread_messages,
                      },
                    }));
                  }
                }
                break;

              case "new_read_mark_1":
                if (
                  ["/chats"].findIndex((path) => {
                    return location.pathname.indexOf(path) <= 0;
                  })
                ) {
                  const NEW_MAIL_CONTACTS = {
                    ...res,
                    data: {
                      ...res.data,
                    },
                    type: res.data.type + "_contacts",
                    _id: res.data.type + "_contacts" + res._id,
                  };

                  setMainWsMessages((prevValue) => [
                    ...prevValue,
                    NEW_MAIL_CONTACTS,
                  ]);

                  if (res.data.type !== "new_read_mark_2") {
                    setUser((prevValue) => ({
                      ...prevValue,
                      stats: {
                        ...prevValue.stats,
                        unread_messages: res.data.payload.extra_data.unread_messages,
                      },
                    }));
                  }
                }
                break;

              case "new_read_mark_2":
                if (
                  ["im/"].findIndex((path) => {
                    return location.pathname.indexOf(path) <= 0;
                  })
                ) {
                  const NEW_MAIL = {
                    ...res,
                    data: {
                      ...res.data,
                    },
                    type: res.data.type + "_mail",
                    _id: res.data.type + "_mail" + res._id,
                  };

                  setMainWsMessages((prevValue) => [...prevValue, NEW_MAIL]);
                }
                break;

              default:
                break;
            }
            break;

          default:
            break;
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainWS, location]);

  return (
    <TranslationContext.Provider value={translations[language]}>
      <div className="app">
        <ProSubscriptionContext.Provider value={proSubscriptions}>
          <SubcategoryContext.Provider value={subcategoryInfo}>
            <CurrentUserContext.Provider value={user}>
              <Header
                {...{
                  isLoggedIn,
                  language,
                  setLanguage,
                  setUser,
                  handleAuthOpen,
                  logout,
                }}
              />
              {user && user.last_unresolved_deposit ? <p>DOPOSIT</p> : null}
              {/* AFTER RESOLVE GET ME USER AGAIN */}

              <Routes location={location}>
                <Route
                  path="/"
                  element={
                    <Suspense fallback={<div className="app__page-load"></div>}>
                      <Main
                        {...{
                          language,
                          suggestAddress,
                          addressHints,
                          isPrelouderVisible,
                          user,
                        }}
                      />
                    </Suspense>
                  }
                />
                <Route
                  path="/blog"
                  element={
                    <Suspense fallback={<div className="app__page-load"></div>}>
                      <Blog {...{ language }} />
                    </Suspense>
                  }
                />
                <Route
                  path="/search"
                  element={
                    <Suspense fallback={<div className="app__page-load"></div>}>
                      <Search {...{ language }} />
                    </Suspense>
                  }
                />
                <Route
                  path="/blog/:article_id"
                  element={
                    <Suspense fallback={<div className="app__page-load"></div>}>
                      <Article {...{ language }} />
                    </Suspense>
                  }
                />
                <Route
                  path="/catalog/pets/dogs"
                  element={
                    <Suspense fallback={<div className="app__page-load"></div>}>
                      <Catalog {...{ language }} />
                    </Suspense>
                  }
                />
                <Route
                  path="/catalog/pets/dogs/:breed"
                  element={
                    <Suspense fallback={<div className="app__page-load"></div>}>
                      <Catalog
                        {...{
                          language,
                          suggestAddress,
                          addressHints,
                          isPrelouderVisible,
                          setIsPrelouderVisible,
                          setAddressHints,
                        }}
                      />
                    </Suspense>
                  }
                />
                {/* <Route
                    path="/breeder-profile/:id"
                    element={<BreederProfile {...{ language }} />}
                  /> */}
                <Route
                  path="/breeder-profile/:id/*"
                  element={
                    <Suspense fallback={<div className="app__page-load"></div>}>
                      <BreederProfile {...{ language, handleAuthOpen }} />
                    </Suspense>
                  }
                />
                <Route
                  path="/advert/:id"
                  element={
                    <Suspense fallback={<div className="app__page-load"></div>}>
                      <Advert
                        isDraft={false}
                        {...{ language, handleAuthOpen }}
                      />
                    </Suspense>
                  }
                />
                <Route
                  path="/draft/:id"
                  element={
                    <Suspense fallback={<div className="app__page-load"></div>}>
                      <Advert isDraft={true} {...{ language }} />
                    </Suspense>
                  }
                />
                <Route
                  path="/new-advert"
                  element={
                    <Suspense fallback={<div className="app__page-load"></div>}>
                      <Advert
                        isNew={true}
                        // isDraft={true}
                        {...{ language }}
                      />
                    </Suspense>
                  }
                />
                <Route
                  path="/proven-breeders/pets/dogs"
                  element={
                    <Suspense fallback={<div className="app__page-load"></div>}>
                      <ProvenBreeders
                        onAuthOpen={handleAuthOpen}
                        {...{
                          language,
                          addressHints,
                          isPrelouderVisible,
                          suggestAddress,
                          setIsPrelouderVisible,
                          setAddressHints,
                          isPreventAnim,
                          setIsPreventAnim,
                        }}
                      />
                    </Suspense>
                  }
                />
                <Route
                  path="/proven-breeders/pets/dogs/:breed"
                  element={
                    <Suspense fallback={<div className="app__page-load"></div>}>
                      <ProvenBreeders
                        onAuthOpen={handleAuthOpen}
                        {...{
                          language,
                          addressHints,
                          isPrelouderVisible,
                          suggestAddress,
                          setIsPrelouderVisible,
                          setAddressHints,
                          isPreventAnim,
                          setIsPreventAnim,
                        }}
                      />
                    </Suspense>
                  }
                />
                <Route
                  path="/breeders/pets/dogs"
                  element={
                    <Suspense fallback={<div className="app__page-load"></div>}>
                      <Breeders
                        onAuthOpen={handleAuthOpen}
                        {...{
                          language,
                          addressHints,
                          isPrelouderVisible,
                          suggestAddress,
                          isPreventAnim,
                          setIsPreventAnim,
                          setIsPrelouderVisible,
                          setAddressHints,
                        }}
                      />
                    </Suspense>
                  }
                />
                <Route
                  path="/breeders/pets/dogs/:breed"
                  element={
                    <Suspense fallback={<div className="app__page-load"></div>}>
                      <Breeders
                        onAuthOpen={handleAuthOpen}
                        {...{
                          language,
                          addressHints,
                          isPrelouderVisible,
                          suggestAddress,
                          isPreventAnim,
                          setIsPreventAnim,
                          setIsPrelouderVisible,
                          setAddressHints,
                        }}
                      />
                    </Suspense>
                  }
                />
                <Route
                  path="/desk/*"
                  element={
                    <Suspense fallback={<div className="app__page-load"></div>}>
                      <Desk
                        {...{
                          language,
                          addressHints,
                          suggestAddress,
                          checkEmailCode,
                          setUser,
                          isPrelouderVisible,
                          handleResendCode,
                          setIsPrelouderVisible,
                          setAddressHints,
                          apiError,
                          isEmailPrelouder,
                          setIsEmailPrelouder,
                          isResendPreloader,
                          mainWsMessages,
                          setMainWsMessages
                        }}
                      />
                    </Suspense>
                  }
                />
                <Route
                  path="/reset-password"
                  element={
                    <NewPassword
                      isAuthOpen={isAuthOpen.password}
                      {...{
                        handleAuthOpen,
                        resetPassword,
                        isPrelouderVisible,
                        apiError,
                      }}
                    />
                  }
                />
                <Route
                  path="/about-us"
                  element={
                    <Suspense fallback={<div className="app__page-load"></div>}>
                      <AboutUs {...{ language }} />
                    </Suspense>
                  }
                />
                <Route
                  path="*"
                  element={
                    <Suspense fallback={<div className="app__page-load"></div>}>
                      <ErrorPage />
                    </Suspense>
                  }
                />
              </Routes>
              <Footer {...{ language, setLanguage }} />

              <AnimatePresence initial={false} mode="wait">
                {isAuthOpen.registration && (
                  <Registration
                    isAuthOpen={isAuthOpen.registration}
                    onAuthOpen={handleAuthOpen}
                    onAuthClose={handleAuthClose}
                    {...{
                      language,
                      isAuthSwitch,
                      setAuthSwitch,
                      signupBuyer,
                      signupBreederStepOne,
                      signupBreederStepTwo,
                      regStep,
                      addressHints,
                      suggestAddress,
                      isPrelouderVisible,
                      setIsPrelouderVisible,
                      checkEmailCode,
                      logout,
                      handleResendCode,
                      setAddressHints,
                      apiError,
                      isResendPreloader,
                    }}
                  />
                )}
                {isAuthOpen.login && (
                  <Login
                    isAuthOpen={isAuthOpen.login}
                    onAuthOpen={handleAuthOpen}
                    onAuthClose={handleAuthClose}
                    {...{
                      isAuthSwitch,
                      setAuthSwitch,
                      signin,
                      isUserExist,
                      setIsUserExist,
                      checkUserExistence,
                      isPrelouderVisible,
                      handleResendCode,
                      apiError,
                    }}
                  />
                )}
                {isAuthOpen.recovery && (
                  <Recovery
                    isAuthOpen={isAuthOpen.recovery}
                    onAuthOpen={handleAuthOpen}
                    onAuthClose={handleAuthClose}
                    {...{
                      isAuthSwitch,
                      setAuthSwitch,
                      isUserExist,
                      setIsUserExist,
                      checkUserExistence,
                      recoveryStep,
                      forgotPasswordRecovery,
                      isPrelouderVisible,
                      handleResendCode,
                      apiError,
                    }}
                  />
                )}
              </AnimatePresence>
            </CurrentUserContext.Provider>
          </SubcategoryContext.Provider>
        </ProSubscriptionContext.Provider>
      </div>
    </TranslationContext.Provider>
  );
}

export default App;
