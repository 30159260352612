import { Link } from "react-router-dom";

export const EN_TRANSLATION = {
    header: {
      breeds: "All breeds",
      breeders: "Breeders",
      community: "Community",
      beforeBuy: "Before you buy",
      adopt: "Adopt don’t shop",
      forum: "Forum",
      blog: "Blog",
      login: "Log In",
      registration: "Registration",
      advert: "New Advert",
      home: "Home",
      logout: "Logout",
      allDogs: "All Dogs",
      smallDogs: "Small",
      mediumDogs: "Medium",
      bigDogs: "Big",
      search: "Breed, username or anything else",
      searchBtn: "Search",
      language: "Language",
    },

    footer: {
      races: {
        title: "Breeds",
        all: "All Breeds",
      },
      breeders: {
        title: "Breeders",
        breeding: "Elevage",
      },
      utile: {
        title: "Useful",
        blog: "Blog & Advice",
        contact: "Contact",
        forum: "Forum",
        cgv: "CGV",
      },
      indispensable: {
        title: "Essential",
        howTo: "How to Adopt",
        breeder: "Choosing the breeder",
        whoWeAre: "Who we are?",
      },
      info: {
        title: "Info",
        privacyPolicy: "Privacy Policy",
        service: "Terms of Service",
        sales: "Terms of Sales",
        cookies: "Privacy / Cookies",
      },
      copyright: "ⒸPet4Me 2023",
    },

    main: {
      searchTitle: "Here you will find a friend",
      searchBreedInput: "Breed of dog",
      searchBreedPlaceholder: "Dachshund, sheepdog",
      // searchHintTitle: "Perhaps you are looking for",
      searchNothing: "Nothing found",
      searchLocationInput: "Where",
      searchLocationPlaceholder: "City, address",
      searchBtn: "Search",

      recommendedTitle: "Recommended",
      recommendedAll: "View all",

      howTitle: "How the service works",
      howOnline: "Choose your furry friend online",
      howDetails: `Ask the breeder for details, leave a <a href='/blog/64e0190f0eab93e526dbc956'>deposit</a>`,
      howHome: "Take a friend home",

      reviewsTitle: "Reviews",

      // forumTitle:
      //   "Don't know where to ask about something you're interested in?",
      forumTitle:
        "Don't know where to read about something you're interested in?",
      // forumText:
      //   "On the forum, you can find all the answers to your questions, as well as meet like-minded people",
      forumText: "In the blog you can find all the answers to your questions",
      // forumLink: "Go to the forum",
      forumLink: "Go to the blog",
    },

    blog: {
      title: "Blog",
      subtitle:
        " Do you want to be aware of everything that happens in the community? Be the first to know about important things - go to the blog faster!",
      moreBtn: "Continue reading",
    },

    blogArticle: {
      also: "Read also",
      moreBtn: "Read more",
    },

    catalog: {
      title: "Announcements",
      filtersBtn: "Filters",
      sortBtn: "Sort by Price",
      toHighPrice: "Price Low to High",
      toLowPrice: "Price High to Low",
      nothingTitle: "Sorry, no results for your request",
      // nothingText: "Contact us and we will help you find a puppy",
      findPuppyBtn: "Help me find a puppy",
      notifyBtn: (breed) => `Notify me on ${breed}`,
      notifyBtnText: "We’ll email you new offers for this breed",
      feedback: {
        formTitle: "Breed request",
        formText: "Leave your information and we will contact you",
        sendBtn: "Send",
        okTitle: "Thank You",
        okText: "Your feedback will be reviewed shortly",
        okBtn: "Okay",
        any: "Any",
      },
    },

    filtersBlock: {
      title: "Filters",
      placeholder: { search: "Filter breeds", locacion: "Location" },
      resetBtn: "Reset Filters",
      applyBtn: "Apply",
      searchNothing: "Nothing found",
      any: "Any breed",
      all: "All of",
      km: "km",
      over: "Over",
      from: "from",
    },

    dogCard: {
      boost: "Boost",
      newborn: "Newborn",
      age: "Age",
      colour: "Colour",
      topTouTou: "Top TouTou",
      deposit: "Deposit",
      draft: "Draft",
      draft_title: "No title",
      draft_description: "No description",
      draft_breed: "No breed",
      draft_date_of_birth: "No date of birth",
    },

    breeders: {
      provenTitle: "We have selected only the best breeders for you",
      breedersTitle: "breeders",
      subtitle: "Breeders we have personally verified",
      howTitle: "How do we check the breeder?",
      howBackground:
        "We do a background check and conduct interview with the breeder to make sure he is compliant with our",
      backgroundLink: "Code of Ethics",
      howVisit:
        "Our agents visits the breeder facilites to check the conditions in which dogs live",
      howDeal: "Only responsible and ethical breeders become our partners.",
      listTitle: "Our breeders",
      verified: "Verified",
      everybody: "Everybody",
      any: "Any",
    },

    breederCard: {
      reviews: "Reviews",
      breeds: "Breeds",
      experience: "Experience",
      club: "Breed Club",
      siren: "SIREN Number",
      tests: "Gen. Tests",
      pension: "Pension",
      adverts: "Adverts",
      viewBtn: "View",
      messageBtn: "Message",
      profileBtn: "View profile",
      yes: "Yes",
      no: "No",
      more: "more",
    },

    desk: {
      nav: {
        profile: "My profile",
        chats: "Chats",
        meetings: "Meetings",
        deposits: "Deposits",
        favorites: "Favourites",
        breeders: "Breeders",
        adverts: "My Adverts",
        subscription: "Subscription",
      },
      breedersTitle: "Favourite breeders",
      favoritesTitle: "Favourites",
      depositsTitle: "Deposits",
      profileTitle: "Profile",
      chatsTitle: "Chats",
      meetsTitle: "Upcoming meetings",
      advertsTitle: "My Adverts",
      myScheduleTitle: "My Schedule",
      scheduleTitle: "Breeder’s Schedule",
      subPlansTitle: "Subscription Plans",
      subscriptionTitle: "My subscription",
      depositsNew: "New",
      depositsApproved: "Resolved",
      connectText: "Please, connect to Stripe to accept user’s deposits",
      connectBtn: "Connect to Stripe",
      stripBtn: "Manage Stripe account",
      cancelBtn: "Cancel",
      approveBtn: "Approve",
      messageBtn: "Message",
      resolveBtn: "Resolve",
      hideBtn: "Hide",
      breederInfo: "Breeder’s Info",
      addBreedBtn: "Add a breed",
      sendBtn: "Send",
      socialNetworks: "Social Networks",
      notifications: "Notifications",
      password: "Password",
      account: "Account",
      changeBtn: "Change password",
      deleteBtn: "Delete account",
      saveBtn: "Save changes",
      showAllBtn: "Show all",
      collapseBtn: "Collapse",
      viewSlotBtn: "View",
      changeSlotBtn: "Change",
      editSlotsBtn: "Edit slots",
      activateBtn: "Activate all",
      repeatBtn: "Repeat every week",
      freeSlot: "Free",
      unavailableSlot: "Unavailable",
      selectedTimeSlot: "Selected",
      appointment: "appointment",
      puppy: "Puppy",
      nothing: "There is nothing here yet",
      noDialogText: "Choose who you want to write to",
      noDialogLink: "Go to your contacts",
      country: "France",
      advertsTypes: { published: "Published", drafts: "Drafts" },
      noMessages: `There are no messages in this dialog yet.
      Be the first!`,
      noMeetings: "You have no upcoming appointments.",
      noSlots: "You have no timeslots for this day.",
      noDepositSlots: "There are no timeslots on this day.",
      approvePopup: {
        title_confirm_approve: "Approve deposit?",
        title_confirm_cancel: "Cancel deposit?",
        title_resolve: "Resolve Deposit",
        title: "Deposit approved",
        book: "Book the meeting",
        bookedMeeting: "Scheduled meeting",
        scheduleBtn: "Add to my Schedule",
        messageBtn: "Message customer",
        messageBreederBtn: "Message breeder",
        approveBtn: "Approve deposit",
        cancelBtn: "Cancel deposit",
        do_you_finalized_deal: "Have you finalized the deal?",
        confirm_cancel_resolve: "You sure want to cancel approved deposit?",
        yes_text_label: "Yes",
        cancel_text_label: "Cancel",
        resolveBtn: "Yes, transfer me the deposit",
        cancelResolveBtn: "No, return the deposit",
      },
      changePopup: {
        title: "Change password",
        saveBtn: "Save new password",
        cancelBtn: "Cancel",
      },
      deletePopup: {
        title: "Account deletion",
        subtitle: "Please, proceed carefully!",
        text: "Deletion of the account also deletes all the associated data, with no options to ever restore it. You will need to enter your account password to delete it.",
        deleteBtn: "Delete my account",
        warning: "Can’t be undone",
        cancelBtn: "Cancel",
      },
      editSlotsPopup: {
        title: "Edit time slots",
        saveBtn: "Save time slots for this day",
        cancelBtn: "Cancel",
      },
      dogsCard: {
        sale: "Sale",
        withDeposit: "With deposit",
        noDeposit: "No deposit",
        color: "Colour",
        peculiarities: "Peculiarities",
        placeBtn: "Place deposit",
        messageBtn: "Message breeder",
      },
      placeholder: {
        blank: "Leave blank if none",
        usernameLink: "Username or link",
        usernameNumber: "Username or number",
        breedSearch: "Type in a breed name",
        typeMessage: "Type your message",
      },
      subscriptions: {
        pro_title: "Pro Subscription",
        monthly_renewal_label: "Monthly Renewal",
        yearly_renewal_label: "Yearly Renewal",
        per_month_label: "per month",
        pro_status_in_profile: "PRO status on your profile",
        exclusive_features_title: "Exclusive additional pro features for your profile",
        exclusive_features_news: "News Feed",
        exclusive_features_photos: "Photos Feed",
        discount_label: "Subscription discount",
        priority_moderation_label: "Priority AD moderation",
        free_photographer: "FREE photo seance",

        yes_text_label: "Yes",
        no_text_label: "No",
        buy_btn: "Buy now",
        manage_btn: "Manage or cancel",
      },
    },

    subscriptionCard: {
      title: "Pro Subscription",
      monthlyType: "Monthly Renewal",
      yearlyType: "Yearly Renewal",
      perMonth: "per month",
      status: "PRO status on your profile",
      features: "Exclusive additional pro features for your profile",
      news: "News Feed",
      photos: "Photos Feed",
      discount: "Subscription discount",
      no: "No",
      buyBtn: "Buy now",
      manageBtn: "Manage or cancel",
    },

    auth: {
      backBtn: "Back",
      buyer: "I’m a Buyer",
      breeder: "I’m a Breeder",
      shelter: "Shelter",
      or: "or",
      facebook: "Continue with Facebook",
      google: "Continue with Google",
      message: {
        title: "Letter Sent",
        text: (email) =>
          `To the email address: <span>${email}</span>, an email has been sent with a link to reset your password`,
      },
      ok: "Ok",
      registration: {
        title: "Registration",
        breederTitle: "New breeder account",
        imageTitle: "I want a Pet",
        imageText: "Very soon you will be able to choose your friend",
        redirectText: "Already registered?",
        redirectLink: "Log In",
        submitText: "Registration",
        breederSubmit: "Save and continue",
      },
      login: {
        title: "Log In",
        imageTitle: "Find your pet!",
        imageText:
          "I agree to the terms of the Customer Agreement, the Online Member Privacy Policy and the Online Member Terms of Use, which I have read and understood.",
        redirectText: "Not registered?",
        redirectLink: "Registration",
        submitText: "Login",
        reminderText: "I forgot my password",
      },
      recovery: {
        title: "Password Recovery",
        subtitle:
          "Enter the email address you provided during registration and we will send you an email with a link to reset your password.",
        redirectText: "Not registered?",
        redirectLink: "Registration",
        submitText: "Send",
      },
      newPassword: {
        title: "New Password",
        subtitle:
          "Enter a new password and repeat it. Password must contain at least 8 characters.",
        submitText: "Done",
      },
      confirmEmail: {
        title: "Confirm Email",
        subtitle: "Enter a code we send you to complete email verification.",
        submitTextBreeder: "Continue",
        submitTextBuyer: "Done",
      },
    },

    errors: {
      required: "Required",
      email: "Invalid email",
      min: "Must be 8 characters or more",
      terms: "You must accept the terms and conditions",
      confirmPassword: "Passwords must match",
      phone: "Invalid phone number",
      length: "Must be 6 characters",
      pageNotFound: "We can’t find this page, but we can find you a dog",
      pageNotFoundBtn: "Find a dog",
      fileTooBig: "File is too big",
      searchNothing: "Nothing was found",
      listValue: "Select a value from the list",
      notValidWhatsAppData: "Not valid data, awaiting link or phone",
      notValidLinkData: "Not valid data, awaiting link or username",
      tooBigYear: "Year is too big",
      login404: 'Incorrect email or password',
      code404: 'Incorrect code',
      alreadyExist: 'Email is already registered',
      password400: 'Wrong current password',
      notFound: 'Data not found',
      tooManyRequests: 'Too many attempts',
      apiErr: 'An error has occurred. Please try again.'
    },

    labels: {
      name: "Name",
      siren: "SIREN Code",
      shelter: "Shelter Name",
      email: "Email",
      code: "Code",
      password: "Password",
      minSymbols: "min 8 symbols",
      termsCheckbox: `I accept the terms and conditions and the privacy policy.`,
      rememberMe: "Remember me",
      currentPassword: "Current password",
      newPassword: "New Password",
      confirmPassword: "Repeat Password",
      confirmPswd: "Confirm new password",
      phone: "Phone Number",
      breed: "Breed you’re looking for",
      location: "Location",
      requests: "Any specific requests?",
      selectBreed: "Breed",
      city: "City",
      phoneNumber: "Phone",
      country: "Country",
      address: "Address",
      breederAddress: "Address for appointments",
      profileDescription: "Profile description",
      startYear: "Year you’ve started breeding",
      verifiedBreeder: "Pension Service",
      sirenNumber: "SIREN",
      breedClub: "Breed club",
      geneticTests: "Genetic health tests for pets",
      lof: "LOF",
      facebook: "Facebook",
      instagram: "Instagram",
      whatsapp: "WhatsApp",
      enableNotifications: "Enable notifications",
      newMessages: "New messages",
      breedSubscriptions: "Breed subscriptions",
      promotions: "News and promotions",
      yes: "Yes",
      no: "No",
      all: "All",
      some: "Some",
    },

    breederHeading: {
      show_all_photos: "Show all photos",
      edit_profile: "Edit Profile",
      edit_profile_short: "Edit",
    },


    breederInfo: {
      reviews_text_single_form: "review",
      reviews_text_multiple_form: "reviews",
      message_btn_text: "Message",
      new_advert_btn_text: "New Advert",
      breeds_title: "Breeds",
      experience_title: "Experience",

      breed_club_title: "Breed Club",
      yes_text_label: "Yes",
      no_text_label: "No",
      genetic_tests_title: "Genetic Tests",
      pension_service_title: "Pension Service",
      siren_title: "SIREN Number",
      verified_title_long: "Verified breeder",
      verified_title_short: "Verified",

      days_label: "days",
      days_label_single_form: "day",
      years_label: "years",
      years_label_single_form: "year",
      months_label: "months",
      months_label_single_form: "month",

      share_text: "I really enjoyed this breeder. {BREEDER_NAME}",
      share_text_advert: "Just look at this cute {BREED_NAME}",
    },

    breederNav: {
      adverts_title: "Adverts",
      news_title: "News",
      photos_title: "Photos",
      reviews_title: "Reviews",
      contacts_title: "Contacts",
    },

    advertHeading: {
      show_all_photos: "Show all photos",
      edit: "Edit advert",
      edit_short: "Edit",
    },

    advert: {
      nothing_title: "Sorry, no results for your request",
      retutn_back: "Return to back",
      gallery_popup_title: "All photos"
    },

    advertTitle: {
      type_sale: "Sale",
      type_adoption: "Adoption",
      with_deposit: "With deposit",
      no_deposit: "Without deposit",
      title_label: "Title",
      type_sale_label: "Sale",
      type_adoption_label: "Adoption",
      deposit_label: "Deposit",
      no_deposit_label: "No deposit",
    },

    editAdvertBanner: {
      title: "Edit advert",
      title_new: "New advert",
      title_draft: "Draft",
      cancel_btn: "Cancel",
      publish_btn: "Publish advert",
      publish_btn_short: "Publish",
      unpublish_btn: "Unpublish",
      unpublish_btn_moved: "Moved to drafts!",
      unpublish_btn_moved_short: "Moved!",
      save_btn_published: "Save advert",
      save_btn: "Save draft",
      save_btn_saved: "Draft saved!",
      save_btn_saved_short: "Saved!",
      save_btn_short: "Save",
      delete_draft: "Delete draft",
      delete_draft_short: "Delete",

      delete_question: "Are you sure?",
      delete_question_short: "Sure?",
      yes_text_label: "Yes",
      no_text_label: "No",
    },

    advertBanner: {
      editPhotos: "Edit photos",
      uploadPhotos: "Upload photos",
      minPhotos: "Min. 5 photos",
    },

    advertDescription: {
      description_label: "Description",
    },

    litterInfo: {
      title: "Litter information",
      basic_title: "Basic",
      veterinary_title: "Veterinary",
      father_title: "Father",
      mother_title: "Mother",

      param_breed_title: "Breed",
      param_age_title: "Age",
      param_id_title: "ID",
      param_lof_title: "LOF / LOOF",
      param_vaccination_title: "Vaccination",
      param_wormed_title: "Wormed",
      param_microchip_title: "Microchip",
      param_dna_test_title: "DNA Test",

      days_label: "days",
      days_label_single_form: "day",
      years_label: "years",
      years_label_single_form: "year",
      months_label: "months",
      months_label_single_form: "month",

      yes_text_label: "Yes",
      no_text_label: "No",
      yes_dna_tests: "Results on request",
      id_and_lof_placeholder: "Number · Leave blank if none",
    },

    litterParent: {
      breed_title: "Breed",
      lof_title: "LOF / LOOF",
      color_title: "Colour",
      age_title: "Age",
      days_label: "days",
      days_label_single_form: "day",
      years_label: "years",
      years_label_single_form: "year",
      months_label: "months",
      months_label_single_form: "month",

      edit_photo: "Edit photo",
      upload_photo: "Upload photo",

      name_placeholder: "Name",
      breed_placeholder: "Breed",
      lof_placeholder: "LOF / LOOF · Leave blank if none",
      colour_placeholder: "Colour",
      date_of_birth_placeholder: "Born DD.MM.YYYY",
    },

    advertAuthor: {
      buyers_checklist: "Buyer’s Checklist",
    },

    loginPopup: {
      title: "Please, login",
      text: "This action requires authorization. Please, login to your account.",
      btnText: "Okay",
    },

    childrens: {
      boy_title: "Boys",
      girls_title: "Girls",
      add_puppy_label: "Add puppy",
    },

    childCard: {
      color_label: "Colour",
      peculiarities_label: "Peculiarities",
      palce_deposit_label: "Place deposit",
      not_available_label: "Not available",
      message_label: "Message breeder",

      name_placeholder: "Name",
      colour_placeholder: "Colour",
      peculiarities_placeholder: "Peculiarities",
      price_placeholder: "Price · €",

      edit_photo: "Edit photo",
      upload_photo: "Upload photo",
    },

    depositPopup: {
      title: "Place a deposit",
      selectBtn: "Select appointment time and continue",
      laterBtn: "Choose appointment time later in chat",
      readBeforeText: "Before Placing a deposit, please learn how our service works",
    },

    successPopup: {
      title: "Deposit sent for approval",
      text: "Breeder will review your deposit and contact you via chat",
    },

    bannerPopup: {
      title: "Photos",
      add_btn: "Add Photo",
    },

    dropdown: {
      placeholder: "Choose",
    },

    breederProfile: {
      nothing_title: "Sorry, no results for your request",
      retutn_back: "Return to back",
      gallery_popup_title: "{USER} Photo Gallery",
    },

    breederAdvert: {
      days_label: "days",
      days_label_single_form: "day",
      years_label: "years",
      years_label_single_form: "year",
      months_label: "months",
      months_label_single_form: "month",
      with_deposit_label: "Deposit",
    },

    advertsCards: {
      show_more: "Show more",
    },

    reviews: {
      show_more: "Show more",
      no_text:
        "You will be able to receive reviews only from people who have purchased a puppy using DEPOSIT option",
    },

    news: {
      show_more: "Show more",
      pro_text:
        "Become a Pro to post news and photos, it’s super fun and attracts more people",
      become_pro_btn: "Become a Pro",
      add_btn: "Add News",
      create_title: "Create a News",
      title_label: "Title",
      message_label: "Message / 200 symbols",
      add_tags_label: "Add Tags",
      add_image_label: "Add image",
      publish_label: "Publish",
    },

    emptyStatus: {
      text: `Oops...
Nobody's here yet`,
    },

    contacts: {
      address_label: "Address",
      phone_label: "Phone Number",
      email_label: "Email",
      social_label: "Social Networks",
    },

    breederPhotos: {
      add_photo_label: "Add photo",
      show_more: "Show more",
    },

    checkEmailCode: {
      resend_code: "Resend code",
    },

    aboutUs: {
      who_we_are_title: "Who are we?",
      who_we_are_text: "Pet4me is the perfect place for those looking to welcome a dog into their lives to meet breeders who understand their needs. We are not just an adoption platform; we are primarily a community organized to offer pets a soothing and safe environment. Both adoption and purchase are ways to bring a dog into your life that will positively transform it. Pet4me also facilitates connections between dog owners for breeding purposes.",
      mission_title: "Our mission",
      mission_text: "Our platform allows you to meet enthusiasts dedicated to breeding and training puppies of all breeds and origins. The partner breeders featured on Pet4me are a testament to our commitment to quality. Welcoming a dog into one's home is an act of love and responsibility. That's why all applications from dog breeders are reviewed and approved by our experts. This rigorous vetting ensures that you are dealing with breeders who practice ethically and prioritize the well-being of the animals.",
      cert_breeders_title: "Certified breeders",
      cert_breeders_text: "When Pet4me certifies a breeding operation, it means that the facility has been visited and meets the requirements set out in our specifications document. Each breeding operation is showcased on the platform with a comprehensive description and photos taken by Pet4me animal experts. The page dedicated to presenting a breeder aims to convey the values of the owner(s). The breeder's profile should reflect the reality in terms of cleanliness, safety, and expertise.",
      our_values_title: "Our Values",
      our_values_text: "Pet4me's mission isn't just to help individuals find the dog they desire, but to ensure that pets find a family where they can truly thrive. To achieve the perfect union between a dog seeking affection and a family wanting to welcome a pet, Pet4me educates potential owners about the responsibilities of adoption. The platform does not hesitate to inform those considering bringing a dog into their homes that having a pet is not only a source of joy but also comes with its own set of challenges. Pet4me's goal is to prevent impulsive dog adoptions which all too often lead to abandonment and mistreatment of animals.",
      safety_title: "Safety First",
      safety_text: "Pet4me is a platform where animals are safe, and financial transactions are secure. The trust between Pet4me breeders and those wishing to welcome a dog is strengthened by anti-fraud features that ensure:",
      safety_point_1: "Animals listed on Pet4me are healthy and live in comfortable breeding facilities.",
      safety_point_2: "Financial transactions are secure, and deposit payments are guaranteed by Pet4me.",
      safety_last_text: "This means that if your pet (from our certified breeders) does not match the initial promise, or if it falls ill after arriving at your home, then Pet4me will provide a full refund of your deposit payments.",
      proposal_title: "Proposal and Objective",
      proposal_subtitle: "Our proposition is clear: impose a new ethic for welcoming animals. Our goal? The well-being of animals.",
      proposal_text: "Pet4me allows no room for error when it comes to our partner breeders, who are the ambassadors of our standards. Listings posted on our platform are all checked and approved by our experts. The standards to be met are strict and determine the postings for puppy and adult dog adoption offers. Pet4me rejects any listings that do not adhere to our ethical charter and can exclude breeders whose practices seem suspicious to us.",
      proposal_info_title: "Here are some examples of listings you will not find on Pet4me:",
      proposal_info_point_1: "Animals offered as gifts as part of a commercial deal.",
      proposal_info_point_2: "Animals proposed in exchange for material goods.",
      proposal_info_point_3: "Animals offered in trade for another animal.",
      proposal_info_point_4: "Animals delivered to homes without the option to visit the kennel.",
      proposal_info_point_5: "Puppies presented without a photo where they can be seen alongside their mother.",
      proposal_info_text: "To raise awareness within our community about the tragedy of the pet trafficking economy, Pet4me offers articles written by professionals (veterinarians, breeders, trainers, etc.). These articles are freely accessible and help anyone considering welcoming a pet into their home understand the significance and repercussions of such a decision on both their life and that of the animal. In addition to these articles, specific advice for each dog breed is provided to help owners foster a deep and peaceful relationship with their new four-legged companion. These pieces, along with tutorials, are designed for dog owners, helping them become familiar with their pets' behavior and health.",
      our_community_title: "An Engaged Community",
      our_community_text: "The Pet4me community has a platform where breeders and buyers can report any suspicious behavior related to animals. This could relate to maltreatment, abusive puppy breeding, and dog trafficking. Pet4me is committed to monitoring suspicious listings that might conceal high-risk breedings, which is why our experts consistently visit locations where puppies are kept (these visits also apply to breeders claiming to have a valid accreditation number). We highly recommend adopting this proactive approach throughout our entire community. You don't arrange for the adoption of a pet without visiting its home: every adopter must visit the place where their future four-legged companion resides.",
      our_community_text_2: "Pet4me was created to promote the well-being of pets. The platform welcomes pet owners, responsible professional and hobbyist breeders, and shelters for abandoned animals. They are informed that we are uncompromising towards those who would attempt to use our services without respecting our visitors and the animals they list on Pet4me.",


    },
    sensitizationPopup: {
      popup_name: 'Adopting a dog is a significant responsibility, as it requires a lifelong commitment of both time and money.',
      devote_subtitle: 'Devote Time to Your Dog',
      devote_point_1: `A dog isn't just a toy. You will need to make time to be at home at specific times of the day to care for him, play with him, and also to train him.`,
      devote_point_2: `A dog needs to be taken out several times a day, even if it's raining, whether it's for their needs or to get some exercise. Make sure to regularly take walks with him; it's essential for his well-being.`,
      cost_subtitle: 'Owning a Dog Comes at a Cost',
      cost_point_1: 'Naturally, you will need to set aside a budget for food, toys, and accessories.',
      cost_point_2: `Your most significant expenses will likely be related to health and care. Remember, as a dog ages, they're more prone to health issues, leading to frequent visits to the vet.`,
      cost_point_3: `Don't forget grooming costs. Some breeds need more grooming than others. And if one day you need to leave without your dog, boarding has its price.`,
      cost_point_4: `For reference: An annual budget of €2000 is a minimum to consider.`,
      end_test: `Adopting a dog is a serious commitment. It's somewhat like a marriage; you commit for better or for worse.`,
    },
    maxSizePopup: {
      title: 'File is too big',
      hint: 'Please, upload files under {MAX_SIZE} MB',
      okay_btn: 'Okay',
    },
  }