import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Pagination, EffectFade } from "swiper";
import moment from "moment-timezone";
import "moment/min/locales";
import { Balancer } from "react-wrap-balancer";
import { TranslationContext } from "../../assets/contexts/translationContext";
import { CurrentUserContext } from "../../assets/contexts/currentUserContext";
import AvatarBox from "../AvatarBox/AvatarBox";
import { MAIN_URL } from "../../assets/utils/constants";
import { PlaceIcon, BoostedCardShape } from "../../assets/icons/icons";
// import tou from "../../assets/images/catalog/top-tou-tou-icon.png";
import coin from "../../assets/images/catalog/deposit-icon.png";
import "./ListDogCard.css";
import "swiper/css";
import "swiper/css/pagination";

function ListDogCard({ language, card, isDraft }) {
  moment.locale(language.toLowerCase());
  const user = useContext(CurrentUserContext);
  const { dogCard, breederAdvert, advertTitle } = useContext(TranslationContext);
  const {
    // topTouTou,
    deposit,
    draft,
  } = dogCard;
  const [photoArr, setPhotoArr] = useState([]);
  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    if (card.images) setPhotoArr(card.images.slice(0, 5));
  }, [card.images]);

  const date_of_birth_moment = card?.features?.date_of_birth
    ? moment(card?.features?.date_of_birth)
    : null;
  const years_from_birth = date_of_birth_moment
    ? Math.floor(Math.abs(date_of_birth_moment.diff(moment(), "years", true)))
    : null;
  const months_from_birth = date_of_birth_moment
    ? Math.floor(Math.abs(date_of_birth_moment.diff(moment(), "months", true)))
    : null;
  const days_from_birth = date_of_birth_moment
    ? Math.floor(Math.abs(date_of_birth_moment.diff(moment(), "days", true)))
    : null;

  return (
    <div className="list-dog-card">
      {card?.boost_data?.is_boosted ? (
        <>
          {BoostedCardShape({
            mainClassName: "list-dog-card__shape list-dog-card__shape_type_big",
            fillClassName: "list-dog-card__shape-fill",
          })}
          {BoostedCardShape({
            mainClassName:
              "list-dog-card__shape list-dog-card__shape_type_small",
            fillClassName: "list-dog-card__shape-fill",
          })}
        </>
      ) : null}

      <Link
        className="list-dog-card__link"
        to={isDraft ? `/draft/${card._id}` : `/advert/${card._id}`}
      >
        {photoArr && photoArr.length > 0 ? (
          <div className="list-dog-card__photo-block">
            <div className="list-dog-card__swiper-container">
              <Swiper
                className="list-dog-card__photo-swiper"
                modules={[Mousewheel, Pagination]}
                spaceBetween={0}
                grabCursor={true}
                mousewheel={{ forceToAxis: true }}
                touchMoveStopPropagation={true}
                pagination={{
                  clickable: true,
                  dynamicBullets: true,
                }}
                loop={true}
              >
                {photoArr.length > 0
                  ? photoArr.map((photo) => (
                    <SwiperSlide
                      className="list-dog-card__photo-swiper-slide"
                      key={photo._id}
                    >
                      <img
                        className="list-dog-card__photo"
                        src={`${MAIN_URL}/get-file/${photo.file_path.orig}`}
                        alt=""
                      />
                    </SwiperSlide>
                  ))
                  : null}
              </Swiper>

              <Swiper
                className="list-dog-card__photo-swiper-with-nav"
                modules={[Pagination, EffectFade]}
                effect="fade"
                fadeEffect={{ crossFade: true }}
                pagination={{ dynamicBullets: true }}
                onInit={(ev) => setSwiper(ev)}
              >
                {photoArr.length > 0
                  ? photoArr.map((photo) => (
                    <SwiperSlide
                      className="list-dog-card__photo-swiper-slide"
                      key={photo._id}
                    >
                      <img
                        className="list-dog-card__photo"
                        src={`${MAIN_URL}/get-file/${photo.file_path.orig}`}
                        alt=""
                      />
                    </SwiperSlide>
                  ))
                  : null}
              </Swiper>

              <ul
                className="list-dog-card__slide-nav"
                style={{
                  gridTemplateColumns: `repeat(${photoArr.length > 0 ? photoArr.length : 1
                    }, 1fr)`,
                }}
              >
                {photoArr.length > 0
                  ? photoArr.map((item, i) => (
                    <li
                      className="list-dog-card__nav-item"
                      onMouseEnter={() => swiper.slideTo(i, 200)}
                      key={`nav-item-${i}`}
                    />
                  ))
                  : null}
              </ul>
            </div>
          </div>
        ) : (
          <div className="list-dog-card__photo-placeholder"></div>
        )}
        {/* draft_title: 'No title',
      draft_description: 'No description',
      draft_breed: 'No breed',
      draft_date_of_birth: 'No date of birth', */}
        <div className="list-dog-card__main-block">
          <div className="list-dog-card__title-block">
            <div className="list-dog-card__title-box">
              <p className="list-dog-card__title">
                {card.title ? card.title : dogCard.draft_title}
              </p>
              <p className="list-dog-card__title list-dog-card__title_type_price">
                {card.features.offer_type === 'adoption' ?
                  advertTitle.type_adoption
                  :
                  card.price ?
                    `~ ${Math.floor(Number(card.price.avg)).toLocaleString()} €`
                    :
                    ''
                }

              </p>
            </div>
            <p className="list-dog-card__date">
              {isDraft ? `${draft} · ` : ""}
              {moment(card.utc_datetime, "YYYY-MM-DDTHH:mm:ssZ").format(
                "MMMM, D, YYYY"
              )}
            </p>
          </div>

          <div className="list-dog-card__info-block">
            <p className="list-dog-card__breed">
              {card?.breed_data
                ? card?.breed_data?.title[
                user ? user.language : language.toLowerCase()
                ]
                : dogCard.draft_breed}
              <br />
              <Balancer>
                {date_of_birth_moment
                  ? years_from_birth >= 1
                    ? `${years_from_birth} ${years_from_birth === 1
                      ? breederAdvert.years_label_single_form
                      : breederAdvert.years_label
                    }`
                    : months_from_birth >= 1
                      ? `${months_from_birth} ${months_from_birth === 1
                        ? breederAdvert.months_label_single_form
                        : breederAdvert.months_label
                      }`
                      : `${days_from_birth} ${days_from_birth === 1
                        ? breederAdvert.days_label_single_form
                        : breederAdvert.days_label
                      }`
                  : dogCard.draft_date_of_birth}
              </Balancer>
            </p>
            <p className="list-dog-card__breed list-dog-card__breed_type_mobile">
              {card?.breed_data
                ? card?.breed_data?.title[
                user ? user.language : language.toLowerCase()
                ]
                : dogCard.draft_breed}
              ,{" "}
              <Balancer>
                {date_of_birth_moment
                  ? years_from_birth >= 1
                    ? `${years_from_birth} ${years_from_birth === 1
                      ? breederAdvert.years_label_single_form
                      : breederAdvert.years_label
                    }`
                    : months_from_birth >= 1
                      ? `${months_from_birth} ${months_from_birth === 1
                        ? breederAdvert.months_label_single_form
                        : breederAdvert.months_label
                      }`
                      : `${days_from_birth} ${days_from_birth === 1
                        ? breederAdvert.days_label_single_form
                        : breederAdvert.days_label
                      }`
                  : dogCard.draft_date_of_birth}
              </Balancer>
            </p>
            <p className="list-dog-card__text">
              {card.description ? card.description : dogCard.draft_description}
            </p>
          </div>

          {!card.is_super_extra_advert ?
            <div className="list-dog-card__user-block">
              {card.owner ?
                <div className="list-dog-card__user-info">
                  <div className="list-dog-card__avatar-box">
                    <AvatarBox
                      avatar={
                        card.owner && card.owner.avatar
                          ? `${MAIN_URL}/get-file/${card.owner.avatar.file_path.orig}`
                          : undefined
                      }
                    />
                  </div>
                  <div className="list-dog-card__user-box">
                    <p className="list-dog-card__user-name">{card.owner.name}</p>
                    <div className="list-dog-card__separator list-dog-card__separator_type_mobile" />
                    <div className="list-dog-card__location-box">
                      <div className="list-dog-card__location-icon-box">
                        {PlaceIcon({
                          mainClassName: "list-dog-card__location-icon",
                          fillClassName: "list-dog-card__location-icon-fill",
                        })}
                      </div>
                      {card.owner.location.address_components.length > 0 &&
                        card.owner.location.address_components.some((item) =>
                          item.types.includes("country")
                        ) &&
                        card.owner.location.address_components.some((item) =>
                          item.types.includes("locality")
                        ) ? (
                        <>
                          <p className="list-dog-card__location-text">
                            {
                              card.owner.location.address_components.find((item) =>
                                item.types.includes("country")
                              ).long_name
                            }
                            <span className="list-dog-card__location-text list-dog-card__location-text_type_mobile">
                              ,
                            </span>
                          </p>
                          <div className="list-dog-card__separator" />
                          <p className="list-dog-card__location-text">
                            {
                              card.owner.location.address_components.find((item) =>
                                item.types.includes("locality")
                              ).long_name
                            }
                          </p>
                        </>
                      ) : (
                        <p className="list-dog-card__location-text">
                          {card.owner.location.formatted_address}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                : null}



              <div className="list-dog-card__labels-block">
                {/* {card.isTopTouTou ? (
                <div className="list-dog-card__label list-dog-card__label_type_top-tou">
                  <img
                    className="list-dog-card__label-icon"
                    src={tou}
                    alt="Top TouTou icon"
                  />
                  <p className="list-dog-card__label-text">{topTouTou}</p>
                </div>
              ) : null} */}
                {card.features.with_deposit ? (
                  <div className="list-dog-card__label list-dog-card__label_type_deposit">
                    <img
                      className="list-dog-card__label-icon"
                      src={coin}
                      alt="Deposit icon"
                    />
                    <p className="list-dog-card__label-text">{deposit}</p>
                  </div>
                ) : null}
              </div>



            </div>
            : null
          }
        </div>
      </Link>
    </div>
  );
}

export default ListDogCard;
