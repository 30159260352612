import { useField } from "formik";
import { motion } from "framer-motion";
import Balancer from "react-wrap-balancer";
import { CheckboxCheckIcon } from "../../assets/icons/icons";
import "./CheckboxWithFormik.css";

function CheckboxWithFormik({ label, type, ...props }) {
  const [field, meta] = useField({ ...props, type: "checkbox" });

  return (
    <div
      className={`formik-checkbox ${
        Boolean(type) ? `formik-checkbox_type_${type}` : ""
      }`}
    >
      <div className="formik-checkbox__container">
        <label className="formik-checkbox__box">
          <input
            className="formik-checkbox__invisible-input"
            type="checkbox"
            {...field}
            {...props}
          />
          <motion.span
            className={`formik-checkbox__pseudo-item ${
              field.checked ? "formik-checkbox__pseudo-item_checked" : ""
            }`}
            initial={{ background: "var(--checkbox-bg-color)" }}
            animate={{
              background: field.checked
                ? "var(--bright-color)"
                : "var(--checkbox-bg-color)",
            }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            {CheckboxCheckIcon(
              "formik-checkbox__check-icon",
              "formik-checkbox__check-icon-stroke"
            )}
          </motion.span>
        </label>
        <span className="formik-checkbox__label">
          <Balancer>{label}</Balancer>
        </span>
      </div>

      <motion.div
        className="formik-checkbox__error-box"
        initial={{
          height: "0px",
          marginTop: "0px",
          opacity: 0,
          visibility: "hidden",
        }}
        animate={{
          height: meta.touched && meta.error ? "14px" : "0px",
          marginTop: meta.touched && meta.error ? "6px" : "0px",
          opacity: meta.touched && meta.error ? 1 : 0,
          visibility: meta.touched && meta.error ? "visible" : "hidden",
        }}
        transition={{ duration: 0.15 }}
      >
        {meta.touched && meta.error ? (
          <p className="formik-checkbox__error">
            <Balancer>{meta.error}</Balancer>
          </p>
        ) : null}
      </motion.div>
    </div>
  );
}

export default CheckboxWithFormik;
