import { motion, AnimatePresence } from "framer-motion";
import defaultAvatar from "../../assets/images/header/default-avatar.png";
import "./AvatarBox.css";

function AvatarBox({ avatar }) {
  return (
    <div className="avatar">
      <AnimatePresence mode="popLayout" initial={false}>
        {avatar ? (
          <motion.img
            className="avatar__img"
            src={avatar}
            alt=""
            key={avatar}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.15 }}
          />
        ) : (
          <motion.div className="avatar__default-box">
            <img
              className="avatar__default-img"
              src={defaultAvatar}
              alt=""
              key="default-avatar"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.15 }}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default AvatarBox;
