import { useContext, useEffect, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import Balancer from "react-wrap-balancer";
import { TranslationContext } from "../../assets/contexts/translationContext";
import useWindowSize from "../../assets/hooks/useWindowSize";
import {
  REG_AUTH_TYPE,
  LOGIN_AUTH_TYPE,
  RECOVERY_AUTH_TYPE,
  PASSWORD_AUTH_TYPE,
  ESC_KEY,
} from "../../assets/utils/constants";
import {
  LogoIcon,
  AuthBackCurve,
  AuthImageCurve,
  AuthStarsIcon,
  AuthShapesIcon,
  SimpleArrowIcon,
  CloseIcon,
  // AuthFacebookIcon,
  // AuthGoogleIcon,
  RighArrowtIcon,
} from "../../assets/icons/icons";
import "./Auth.css";

function Auth({
  type,
  imageTitle,
  titleIcon,
  imageText,
  image,
  mobile,
  topPattern,
  bottomPattern,
  isBackBtnVisible,
  isCloseBtnVisible,
  title,
  subtitle,
  children,
  redirectText,
  redirectLink,
  recoveryStep,
  email,
  onClose,
  onRedirect,
  onBack,
  isAuthOpen,
  isAuthSwitch,
  regStep,
  logout,
}) {
  const overlay = useRef();
  const { auth } = useContext(TranslationContext);
  const {
    backBtn,
    //  or, facebook, google,
    message,
    ok,
  } = auth;
  const { width, height } = useWindowSize();

  // close on overlay
  useEffect(() => {
    if (type === PASSWORD_AUTH_TYPE || (type === REG_AUTH_TYPE && regStep === 2)) return;

    function handleOverlayClose(evt) {
      if (
        overlay.current === evt.target ||
        overlay.current === evt.target.closest(".auth__overlay")
      ) {
        closePopup();
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });

  // close on esc
  useEffect(() => {
    if (type === PASSWORD_AUTH_TYPE || (type === REG_AUTH_TYPE && regStep === 2)) return;

    function handleEscClose(evt) {
      if (evt.key === ESC_KEY) {
        closePopup();
      }
    }

    window.addEventListener("keydown", handleEscClose);
    return () => {
      window.removeEventListener("keydown", handleEscClose);
    };
  });

  function closePopup() {
    onClose();
    if (type === REG_AUTH_TYPE && regStep > 0) logout();
  }

  return (
    <section
      className={`auth ${isAuthOpen ? "auth_opened" : ""}`}
      key={type}
      style={{ minHeight: width <= 665 && height }}
    >
      <motion.div
        className="auth__overlay"
        ref={overlay}
        onTouchStart={() =>
          type !== PASSWORD_AUTH_TYPE ? closePopup() : undefined
        }
        initial={{ opacity: isAuthSwitch ? (width <= 870 ? 1 : 0.95) : 0 }}
        animate={{
          opacity: type === PASSWORD_AUTH_TYPE || width <= 870 ? 1 : 0.95,
        }}
        exit={{ opacity: isAuthSwitch ? (width <= 870 ? 1 : 0.95) : 0 }}
        transition={{ duration: 0.3 }}
      >
        {AuthBackCurve(
          "auth__back-curve auth__back-curve_type_left",
          "auth__back-curve-fill"
        )}
        {AuthBackCurve(
          "auth__back-curve auth__back-curve_type_right",
          "auth__back-curve-fill"
        )}
      </motion.div>

      <motion.div
        className={`auth__container auth__container_type_${type} ${
          type === REG_AUTH_TYPE && regStep === 2
            ? "auth__container_type_breeder-info"
            : ""
        }`}
        style={{ minHeight: width <= 870 && width > 665 && height }}
        initial={{ opacity: isAuthSwitch ? 1 : 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: isAuthSwitch ? 1 : 0 }}
        transition={{ duration: 0.3 }}
      >
        <motion.button
          className="auth__close-btn auth__close-btn_type_mobile"
          type="button"
          aria-label="Close auth page"
          initial={{ opacity: isCloseBtnVisible ? 0.8 : 0 }}
          animate={{ opacity: isCloseBtnVisible ? 0.8 : 0 }}
          style={{ pointerEvents: isCloseBtnVisible ? "auto" : "none" }}
          transition={{ duration: 0.2 }}
          onClick={closePopup}
        >
          {CloseIcon("auth__close-icon", "auth__close-icon-stroke")}
        </motion.button>

        {type !== REG_AUTH_TYPE || (type === REG_AUTH_TYPE && regStep < 2) ? (
          <motion.div
            className={`auth__image-block auth__image-block_type_${type}`}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{
              type: "spring",
              stiffness: 300,
              damping: 24,
            }}
          >
            {topPattern ? (
              <img
                className="auth__pattern auth__pattern_type_top"
                src={topPattern}
                alt=""
              />
            ) : null}
            <img className="auth__pattern" src={bottomPattern} alt="" />

            {imageTitle ? (
              <div className="auth__image-text-block">
                <div className="auth__image-title-box">
                  <p className="auth__image-title">
                    <Balancer>{imageTitle}</Balancer>
                  </p>
                  {titleIcon ? (
                    <div className="auth__title-icon-box">{titleIcon}</div>
                  ) : null}
                </div>
                {imageText ? (
                  <p className="auth__image-text">
                    <Balancer>{imageText}</Balancer>
                  </p>
                ) : null}
              </div>
            ) : null}

            <div className={`auth__image-box auth__image-box_type_${type}`}>
              {AuthImageCurve("auth__image-curve", "auth__image-curve-fill")}
              {AuthStarsIcon("auth__stars-icon", "auth__stars-icon-fill")}
              {AuthShapesIcon("auth__shapes-icon", "auth__shapes-icon-fill")}
              {type === REG_AUTH_TYPE || type === LOGIN_AUTH_TYPE ? (
                <div className="auth__patch" />
              ) : null}
              <img className="auth__image" src={image} alt="" />
              {mobile ? (
                <img
                  className="auth__image auth__image_type_mobile"
                  src={mobile}
                  alt=""
                />
              ) : null}
            </div>

            <div className="auth__logo-box">
              {LogoIcon(
                "auth__logo",
                "auth__logo-main-fill",
                "auth__logo-stroke",
                "auth__logo-secondary-fill"
              )}
            </div>
          </motion.div>
        ) : null}

        <motion.div
          className="auth__content"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{
            type: "spring",
            stiffness: 300,
            damping: 24,
          }}
        >
          <div className="auth__btns-block">
            <motion.button
              className="auth__back-btn"
              type="button"
              initial={{ opacity: isBackBtnVisible ? 1 : 0 }}
              animate={{ opacity: isBackBtnVisible ? 1 : 0 }}
              style={{ pointerEvents: isBackBtnVisible ? "auto" : "none" }}
              transition={{ duration: 0.2 }}
              onClick={onBack}
            >
              {SimpleArrowIcon("auth__back-icon", "auth__back-icon-stroke")}
              <p className="auth__back-btn-text">{backBtn}</p>
            </motion.button>
            <motion.button
              className="auth__close-btn"
              type="button"
              aria-label="Close auth page"
              initial={{ opacity: isCloseBtnVisible ? 0.8 : 0 }}
              animate={{ opacity: isCloseBtnVisible ? 0.8 : 0 }}
              style={{ pointerEvents: isCloseBtnVisible ? "auto" : "none" }}
              transition={{ duration: 0.2 }}
              onClick={closePopup}
            >
              {CloseIcon("auth__close-icon", "auth__close-icon-stroke")}
            </motion.button>
          </div>

          <AnimatePresence initial={false} mode="wait">
            {(type !== RECOVERY_AUTH_TYPE ||
              (type === RECOVERY_AUTH_TYPE && recoveryStep === 0)) && (
              <motion.div
                className="auth__form-block"
                key={`form-${type}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.15 }}
              >
                <p className="auth__title">{title}</p>

                {subtitle ? (
                  <p className="auth__subtitle">
                    <Balancer>{subtitle}</Balancer>
                  </p>
                ) : null}

                {children}

                {/* {(type === REG_AUTH_TYPE && regStep === 0) ||
                type === LOGIN_AUTH_TYPE ? (
                  <div className="auth__third-party">
                    <div className="auth__divider">
                      <div className="auth__divider-line" />
                      <p className="auth__divider-text">{or}</p>
                      <div className="auth__divider-line" />
                    </div>

                    <div className="auth__third-party-btns-block">
                      <button
                        className="auth__third-party-btn auth__third-party-btn_type_facebook"
                        type="button"
                      >
                        {AuthFacebookIcon("auth__third-party-icon")}
                        <p className="auth__third-party-text">{facebook}</p>
                      </button>

                      <button className="auth__third-party-btn" type="button">
                        {AuthGoogleIcon("auth__third-party-icon")}
                        <p className="auth__third-party-text">{google}</p>
                      </button>
                    </div>
                  </div>
                ) : null} */}

                {(type === REG_AUTH_TYPE && regStep === 0) ||
                type !== REG_AUTH_TYPE ? (
                  redirectText && redirectLink ? (
                    <div className="auth__redirect-block">
                      <p className="auth__redirect-text">{redirectText}</p>
                      <button
                        className="auth__redirect-link"
                        type="button"
                        onClick={onRedirect}
                      >
                        <p className="auth__redirect-link-text">
                          {redirectLink}
                        </p>
                        {RighArrowtIcon(
                          "auth__redirect-icon",
                          "auth__redirect-icon-stroke"
                        )}
                      </button>
                    </div>
                  ) : null
                ) : null}
              </motion.div>
            )}

            {type === RECOVERY_AUTH_TYPE && recoveryStep === 1 && (
              <motion.div
                className="auth__message-block"
                key="message"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.15 }}
              >
                <p className="auth__message-title">{message.title}</p>
                <p
                  className="auth__message-text"
                  dangerouslySetInnerHTML={{ __html: message.text(email) }}
                />
                <motion.button
                  className="auth__ok-btn"
                  type="button"
                  initial={{ background: "var(--active-btn-color)" }}
                  whileHover={{ background: "var(--hover-btn-color)" }}
                  whileTap={{ background: "var(--tap-btn-color)" }}
                  transition={{ duration: 0.2, ease: "easeInOut" }}
                  onClick={closePopup}
                >
                  <p className="auth__ok-btn-text">{ok}</p>
                </motion.button>
              </motion.div>
            )}
          </AnimatePresence>
        </motion.div>
      </motion.div>
    </section>
  );
}

export default Auth;
