import { useContext, useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TranslationContext } from "../../../assets/contexts/translationContext";
import InputWithFormik from "../../InputWithFormik/InputWithFormik";
import AuthSubmitBtn from "../AuthSubmitBtn/AuthSubmitBtn";
import {
  CHECK_CODE_POPUP,
  NOT_FOUND_ERR_CODE,
  TOO_MANY_REQUESTS_ERR_CODE,
} from "../../../assets/utils/constants";
import "./CheckEmailCode.css";
import { CurrentUserContext } from "../../../assets/contexts/currentUserContext";
import moment from "moment";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";

function CheckEmailCode({
  submitText,
  onSubmit,
  isPrelouderVisible,
  type,
  handleResendCode,
  isResendPreloader,
  apiError,
  isPopup,
  isOpened,
}) {
  const { errors, labels, checkEmailCode } = useContext(TranslationContext);
  const user = useContext(CurrentUserContext);
  const [apiErrText, setApiErrText] = useState("");
  const [timeLeft, setTimeLeft] = useState(
    user && user.new_email && user.new_email.generated_utc
      ? Number(moment().format("X")) -
      Number(moment(`${user.new_email.generated_utc}+00:00`).format("X"))
      : 61
  );

  useEffect(() => {
    // console.log(user)
    if (user) {
      setTimeLeft(
        Number(moment().format("X")) -
        Number(moment(`${user.new_email.generated_utc}+00:00`).format("X"))
      );
      const timer = setInterval(() => {
        setTimeLeft(
          Number(moment().format("X")) -
          Number(moment(`${user.new_email.generated_utc}+00:00`).format("X"))
        );
        clearInterval(timer);
      }, 1000);
    } else {
      setTimeLeft(0);
    }
  }, [user, timeLeft]);

  useEffect(() => {
    switch (true) {
      case apiError === NOT_FOUND_ERR_CODE:
        setApiErrText(errors.code404);
        break;
      case apiError === TOO_MANY_REQUESTS_ERR_CODE:
        setApiErrText(errors.tooManyRequests);
        break;
      case apiError !== "":
        setApiErrText(errors.apiErr);
        break;
      default:
        setApiErrText("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiError]);

  const formikRef = useRef();

  useEffect(() => {
    if (!isPopup) return
    if (!isOpened) formikRef.current?.resetForm()
  }, [isPopup, isOpened])

  return (
    <Formik
      initialValues={{ code: "" }}
      validationSchema={Yup.object({
        code: Yup.string().length(6, errors.length).required(errors.required),
      })}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit(values, setSubmitting);
      }}
      innerRef={formikRef}
    >
      {({ isValid, dirty, isSubmitting }) => (
        <Form
          className={`auth__form auth__form_type_code ${type === CHECK_CODE_POPUP ? "auth__form_type_check-popup" : ""
            }`}
        >
          <div className="auth__inputs-block">
            <InputWithFormik
              label={labels.code}
              id="code"
              name="code"
              type="text"
            />
          </div>
          {user ? (
            <button
              className={`check-email-code__resend ${61 - Number(timeLeft) >= 0 || isResendPreloader
                  ? "check-email-code__resend_disabled"
                  : ""
                }`}
              type="button"
              onClick={handleResendCode}
            >
              {isResendPreloader ? (
                <MiniPreloader
                  style={{ width: "fit-content" }}
                  isResendPreloader
                />
              ) : 61 - Number(timeLeft) >= 0 ? (
                `${("0" + ~~((61 - timeLeft) / 60)).slice(-2)}:${(
                  "0" +
                  ((61 - timeLeft) % 60)
                ).slice(-2)}`
              ) : (
                checkEmailCode.resend_code
              )}
            </button>
          ) : null}

          <div className="auth__submit-btn-block">
            <AuthSubmitBtn
              text={submitText}
              apiError={apiErrText}
              {...{ isValid, dirty, isSubmitting, isPrelouderVisible }}
            />
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default CheckEmailCode;
