import { useEffect, useRef, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { FixedSizeList as List } from 'react-window';
import AutoSizer from "react-virtualized-auto-sizer";
import Balancer from "react-wrap-balancer";
import { TranslationContext } from "../../../assets/contexts/translationContext";
import { CurrentUserContext } from "../../../assets/contexts/currentUserContext";
import useWindowSize from "../../../assets/hooks/useWindowSize";
import usePreventScroll from "../../../assets/hooks/usePreventScroll";
import {
  DROPDOWN_PROFILE,
  MOBILE_NAV_TYPE,
  SMALL_BREEDS,
  MEDIUM_BREEDS,
  BIG_BREEDS,
  DROPDOWN_LANG,
  LANGUAGES,
  FR_LANGUAGE,
  EN_LANGUAGE,
  BUYER_USER_TYPE,
  BREEDER_USER_TYPE,
} from "../../../assets/utils/constants";
import {
  ProfileMenuTopEllipse,
  ProfileMenuBottomEllipse,
  SearchIcon,
  CheckIcon,
} from "../../../assets/icons/icons";
import "./DropdownPopup.css";


function DropdownPopup({
  isOpen,
  onClose,
  type,
  children,
  dropdownVariants,
  itemVariants,
  navList,
  onLogout,
  searchKey,
  handleSearchSubmit,
  handleKeyChange,
  breedsSelected,
  setBreedsSelected,
  breeds,
  isLangOpen,
  openDropdown,
  language,
  selectLanguage,
}) {
  const overlay = useRef();
  const langOverlay = useRef();
  const { header } = useContext(TranslationContext);
  const user = useContext(CurrentUserContext);
  const { width, height } = useWindowSize();
  usePreventScroll(isOpen);

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (overlay.current === evt.target) {
        onClose(type);
      }

      if (langOverlay.current === evt.target) {
        onClose(DROPDOWN_LANG);
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });






  function CatalogRow({ style, index }) {
    const item = breeds[index]

    return (
      <div style={style} className="header__breed-item" key={item.value} >
        <Link
          className="header__breed-link"
          to={`/catalog/pets/dogs/${item.value}`}
          onClick={() => onClose(type)}
        >
          <p className="header__breed-name">
            <Balancer >
              {
                item.title[
                user
                  ? user.language
                  : language.toLowerCase()
                ]
              }
            </Balancer>
          </p>
          {Boolean(item.count) ? (
            <p className="header__offers-number">
              {item.count}
            </p>
          ) : null}
        </Link>
      </div>

    )
  }

  return (
    <>
      <div
        className={`header__dropdown header__dropdown_type_${type} ${isOpen ? "header__dropdown_opened" : ""
          }`}
        style={{ height: width <= 900 && height - 44 }}
      >
        {Boolean(children) ? (
          children
        ) : (
          <motion.div
            variants={dropdownVariants}
            style={{ pointerEvents: isOpen ? "auto" : "none" }}
            className="header__dropdown-container"
          >
            {type === MOBILE_NAV_TYPE ? (
              <motion.div
                className="header__dropdown-item"
                variants={itemVariants}
                key="search-bar"
              >
                <div className="header__search-box">
                  <form
                    className="header__search-form"
                    onSubmit={handleSearchSubmit}
                  >
                    <div className="header__search-icon-box">
                      {SearchIcon(
                        "header__search-icon",
                        "header__search-icon-stroke"
                      )}
                    </div>
                    <input
                      className="header__search-input"
                      id="search"
                      name="search"
                      type="text"
                      autoComplete="off"
                      placeholder={header.search}
                      value={searchKey || ""}
                      onChange={handleKeyChange}
                    />
                    <button className="header__find-btn" type="submit">
                      <p className="header__find-btn-text">
                        {header.searchBtn}
                      </p>
                    </button>
                  </form>
                </div>
              </motion.div>
            ) : null}

            <ul className="header__dropdown-list">
              {navList.map((item, i) => (
                <motion.li
                  className={`header__dropdown-item ${user &&
                    ((user.user_type === BUYER_USER_TYPE &&
                      (item.link.includes("adverts") ||
                        item.link.includes("subscription") ||
                        item.link.includes("meets"))) ||
                      (user.user_type === BREEDER_USER_TYPE &&
                        (item.link.includes("favourites") ||
                          item.link.includes("breeders"))))
                    ? "header__dropdown-item_hidden"
                    : ""
                    }`}
                  variants={itemVariants}
                  key={`dropdown-type-${type}-item-${i}`}
                >
                  <NavLink
                    className={({ isActive }) =>
                      "header__link header__link_type_dropdown" +
                      (isActive ? " header__link_selected" : "")
                    }
                    end
                    to={
                      item.breader_link && user.user_type === BREEDER_USER_TYPE
                        ? item.breader_link
                        : item.link
                    }
                    onClick={() => onClose(type)}
                  >
                    <div className="header__icon-box header__icon-box_type_dropdown">
                      {item.icon({
                        mainClassName: "header__dropdown-icon",
                        fillClassName: "header__dropdown-icon-fill",
                        strokeClassName: "header__dropdown-icon-stroke",
                      })}
                      {item.text === header.chat &&
                        Boolean(item.amount) &&
                        Number(item.amount) !== 0 ? (
                        <div className="header__new-messages" />
                      ) : null}
                    </div>
                    <p className="header__text header__text_type_dropdown">
                      {item.text}
                    </p>
                    {Boolean(item.amount) && Number(item.amount) !== 0 ? (
                      <p className="header__text header__text_type_amount">
                        {item.amount}
                      </p>
                    ) : null}
                  </NavLink>
                </motion.li>
              ))}

              {type === MOBILE_NAV_TYPE && width <= 900 ? (
                <motion.li
                  className="header__dropdown-item"
                  variants={itemVariants}
                  key={`dropdown-type-${type}-lang`}
                >
                  <motion.div
                    className="header__box-with-dropdown header__box-with-dropdown_type_mobile-lang"
                    initial={false}
                    animate={isLangOpen ? "open" : "closed"}
                  >
                    <button
                      className="header__link header__link_type_dropdown"
                      type="button"
                      onClick={() => openDropdown(DROPDOWN_LANG)}
                    >
                      <div className="header__icon-box header__icon-box_type_dropdown">
                        {LANGUAGES[language].flag}
                      </div>
                      <p className="header__text header__text_type_dropdown">
                        {header.language}
                      </p>
                      <p className="header__text header__text_type_amount">
                        {LANGUAGES[language].lang}
                      </p>
                    </button>
                    <div
                      className={`header__dropdown header__dropdown_type_mobile-lang ${isLangOpen ? "header__dropdown_opened" : ""
                        }`}
                    >
                      <motion.ul
                        className="header__dropdown-list header__dropdown-list_type_lang"
                        variants={dropdownVariants}
                        style={{
                          pointerEvents: isLangOpen ? "auto" : "none",
                        }}
                      >
                        <motion.li
                          className="header__dropdown-item"
                          variants={itemVariants}
                          key={FR_LANGUAGE}
                        >
                          <button
                            className={`header__lang-btn ${language === FR_LANGUAGE
                              ? "header__lang-btn_selected"
                              : ""
                              }`}
                            // style={{ opacity: 0.4, pointerEvents: "none" }}
                            type="button"
                            onClick={() => selectLanguage(FR_LANGUAGE)}
                          >
                            <div className="header__icon-box header__icon-box_type_lang">
                              {LANGUAGES[FR_LANGUAGE].flag}
                            </div>
                            <p className="header__text header__text_type_lang">
                              {LANGUAGES[FR_LANGUAGE].lang}
                            </p>
                            <div className="header__icon-box header__icon-box_type_check">
                              {CheckIcon(
                                "header__check-icon",
                                "header__check-icon-stroke"
                              )}
                            </div>
                          </button>
                        </motion.li>
                        <div className="header__divider-line" />
                        <motion.li
                          className="header__dropdown-item"
                          variants={itemVariants}
                          key={EN_LANGUAGE}
                        >
                          <button
                            className={`header__lang-btn ${language === EN_LANGUAGE
                              ? "header__lang-btn_selected"
                              : ""
                              }`}
                            type="button"
                            onClick={() => selectLanguage(EN_LANGUAGE)}
                          >
                            <div className="header__icon-box header__icon-box_type_lang">
                              {LANGUAGES[EN_LANGUAGE].flag}
                            </div>
                            <p className="header__text header__text_type_lang">
                              {LANGUAGES[EN_LANGUAGE].lang}
                            </p>
                            <div className="header__icon-box header__icon-box_type_check">
                              {CheckIcon(
                                "header__check-icon",
                                "header__check-icon-stroke"
                              )}
                            </div>
                          </button>
                        </motion.li>
                      </motion.ul>
                    </div>
                    <div
                      className="header__dropdown-overlay"
                      ref={langOverlay}
                      onTouchStart={() => onClose(DROPDOWN_LANG)}
                    />
                  </motion.div>
                </motion.li>
              ) : null}
            </ul>

            {type === DROPDOWN_PROFILE ? (
              <>
                <motion.button
                  className="header__btn header__btn_type_logout"
                  type="button"
                  onClick={onLogout}
                  variants={itemVariants}
                  key={`dropdown-type-${type}-logout`}
                >
                  <p className="header__text header__text_type_logout">
                    {header.logout}
                  </p>
                </motion.button>

                {ProfileMenuTopEllipse({
                  mainClassName:
                    "header__profile-ellipse header__profile-ellipse_type_top",
                  fillClassName: "header__profile-ellipse-fill",
                })}

                {ProfileMenuBottomEllipse({
                  mainClassName:
                    "header__profile-ellipse header__profile-ellipse_type_bottom",
                  fillClassName: "header__profile-ellipse-fill",
                })}
              </>
            ) : null}

            
            {type === MOBILE_NAV_TYPE ? (
              <motion.div
                className="header__dropdown-item header__dropdown-item_type_breed"
                variants={itemVariants}
                key="breeds"
              >
                <div className="headeer__breeds-title-box">
                  <p className="header__breeds-title">{header.breeds}</p>
                  <ul className="header__selectors-list">
                    <li className="header__selector-item">
                      <button
                        className={`header__selector-btn ${breedsSelected === ""
                          ? "header__selector-btn_selected"
                          : ""
                          }`}
                        type="button"
                        onClick={() => setBreedsSelected("")}
                      >
                        {header.allDogs}
                      </button>
                    </li>
                    <li className="header__selector-item">
                      <button
                        className={`header__selector-btn ${breedsSelected === SMALL_BREEDS
                          ? "header__selector-btn_selected"
                          : ""
                          }`}
                        type="button"
                        onClick={() => setBreedsSelected(SMALL_BREEDS)}
                      >
                        {header.smallDogs}
                      </button>
                    </li>
                    <li className="header__selector-item">
                      <button
                        className={`header__selector-btn ${breedsSelected === MEDIUM_BREEDS
                          ? "header__selector-btn_selected"
                          : ""
                          }`}
                        type="button"
                        onClick={() => setBreedsSelected(MEDIUM_BREEDS)}
                      >
                        {header.mediumDogs}
                      </button>
                    </li>
                    <li className="header__selector-item">
                      <button
                        className={`header__selector-btn ${breedsSelected === BIG_BREEDS
                          ? "header__selector-btn_selected"
                          : ""
                          }`}
                        type="button"
                        onClick={() => setBreedsSelected(BIG_BREEDS)}
                      >
                        {header.bigDogs}
                      </button>
                    </li>
                  </ul>
                </div>

                <div className="header__breeds-block">
                  <AnimatePresence mode="wait" initial={false}>
                    {breeds ?
                      <AutoSizer>
                        {({ height, width }) => (
                          <List
                            className="header__breeds-list"
                            key={breedsSelected ? breedsSelected : "all"}
                            height={height}
                            itemCount={breeds.length}
                            itemSize={24}
                            width={width}
                          >
                            {CatalogRow}
                          </List>
                        )}
                      </AutoSizer>
                      : null}

                  </AnimatePresence>
                </div>
              </motion.div>
            ) : null}
          </motion.div>
        )}
        
      </div>

      <div
        className="header__dropdown-overlay"
        ref={overlay}
        onTouchStart={() => onClose(type)}
      />
    </>
  );
}

export default DropdownPopup;
