import { useSearchParams } from 'react-router-dom';
import './Search.css';
import { useContext, useEffect, useState } from 'react';
import mainApi from '../../assets/api/MainApi';
import MiniPreloader from '../MiniPreloader/MiniPreloader';
import ListDogCard from '../ListDogCard/ListDogCard';
import { NothingFoundShape } from '../../assets/icons/icons';

import dog from "../../assets/images/catalog/nothing-found-dog.png";
import { TranslationContext } from '../../assets/contexts/translationContext';


function Search({
    language,
}) {
    const { catalog } = useContext(TranslationContext);


    const [searchParams] = useSearchParams()
    const value = searchParams.get('value')
    const [isLoading, setLoading] = useState(true)
    const [items, setItems] = useState(undefined)
    useEffect(() => {
        setLoading(true)
        if (!value) {
            setLoading(false)
            return
        }
        mainApi.searchAdverts({ text: value })
            .then((res) => {
                console.log(res.data)
                setItems(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [value])
    return (
        <div className='search-page'>
            <p className='search-page__querry'>{value ? value : 'None'}</p>
            {isLoading ?
                <div className='search-page__loading'>
                    <MiniPreloader />
                </div>
                :
                items && items.length > 0 ?

                    <ul className="search-page__cards-list">
                        {items.map((card) => (
                            <li className="search-page__cards-item" key={card._id}>
                                <ListDogCard {...{ language, card }} />
                            </li>
                        ))}
                    </ul>
                    :
                    <div className="search-page__nothing-block">
                        <div className="search-page__nothing-container">
                            <div className="search-page__nothing-img-box">
                                {NothingFoundShape({
                                    mainClassName: "search-page__nothing-shape",
                                    fillClassName: "search-page__nothing-shape-fill",
                                })}
                                <img
                                    className="search-page__nothing-img"
                                    src={dog}
                                    alt=""
                                />
                            </div>
                            <p className="search-page__nothing-title">{catalog.nothingTitle}</p>

                        </div>
                    </div>
            }

        </div>
    );
}

export default Search