import { useState, useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TranslationContext } from "../../../assets/contexts/translationContext";
import Auth from "../Auth";
import InputWithFormik from "../../InputWithFormik/InputWithFormik";
import AuthSubmitBtn from "../AuthSubmitBtn/AuthSubmitBtn";
import {
  PASSWORD_AUTH_TYPE,
  LOGIN_AUTH_TYPE,
} from "../../../assets/utils/constants";
import bottomPattern from "../../../assets/images/auth/new-password-bottom-pattern.png";
import topPattern from "../../../assets/images/auth/new-password-top-pattern.png";
import image from "../../../assets/images/auth/new-password.png";
import "./NewPassword.css";

function NewPassword({
  isAuthOpen,
  handleAuthOpen,
  resetPassword,
  isPrelouderVisible,
  apiError
}) {
  const { auth, errors, labels } = useContext(TranslationContext);
  const { newPassword } = auth;
  const { title, subtitle, submitText } = newPassword;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [emailToken, setEmailToken] = useState("");
  const [isPasswordVisible, setPasswordVisible] = useState({});
  const [apiErrText, setApiErrText] = useState("");

  useEffect(() => {
    let token = searchParams.get("email_token");
    if (!token) {
      navigate("/");
      handleAuthOpen(LOGIN_AUTH_TYPE);
    } else {
      setEmailToken(token);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (true) {
      case apiError !== "":
        setApiErrText(errors.apiErr);
        break;
      default:
        setApiErrText("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiError]);

  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    newPassword: Yup.string().min(8, errors.min).required(errors.required),
    confirmPassword: Yup.string()
      .required(errors.required)
      .oneOf([Yup.ref("newPassword")], errors.confirmPassword),
  });

  function togglePasswordVisibility(name) {
    setPasswordVisible((prevObj) => ({
      ...prevObj,
      [name]: !Boolean(isPasswordVisible[name]),
    }));
  }

  return (
    <Auth
      type={PASSWORD_AUTH_TYPE}
      isBackBtnVisible={false}
      isCloseBtnVisible={false}
      {...{
        bottomPattern,
        topPattern,
        image,
        title,
        subtitle,
        isAuthOpen,
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          resetPassword(values, emailToken, setSubmitting);
        }}
      >
        {({ isValid, dirty, isSubmitting }) => (
          <Form className="auth__form auth__form_type_password">
            <div className="auth__inputs-block">
              <InputWithFormik
                label={labels.newPassword}
                id="newPassword"
                name="newPassword"
                type={isPasswordVisible.newPassword ? "text" : "password"}
                isPasswordVisible={isPasswordVisible.newPassword}
                onPasswordToggle={togglePasswordVisibility}
              />

              <InputWithFormik
                label={labels.confirmPassword}
                id="confirmPassword"
                name="confirmPassword"
                type={isPasswordVisible.confirmPassword ? "text" : "password"}
                isPasswordVisible={isPasswordVisible.confirmPassword}
                onPasswordToggle={togglePasswordVisibility}
              />
            </div>

            <div className="auth__submit-btn-block">
              <AuthSubmitBtn
                text={submitText}
                apiError={apiErrText}
                {...{ isValid, dirty, isSubmitting, isPrelouderVisible }}
              />
            </div>
          </Form>
        )}
      </Formik>
    </Auth>
  );
}

export default NewPassword;
