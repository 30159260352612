import { useContext } from "react";
import { motion } from "framer-motion";
import { useField } from "formik";
import { TranslationContext } from "../../../assets/contexts/translationContext";
import {
  BUYER_USER_TYPE,
  BREEDER_USER_TYPE,
  // SHELTER_USER_TYPE,
} from "../../../assets/utils/constants";
import "./UserTypeSelector.css";

const userTypeVariants = {
  selected: {
    fontWeight: 700,
    opacity: 1,
    borderBottomWidth: "2px",
    borderBottomColor: "var(--bright-color)",
    paddingBottom: "11px",
    transition: { duration: 0.2 },
  },
  unselected: {
    fontWeight: 400,
    opacity: 0.7,
    borderBottomWidth: "1px",
    borderBottomColor: "var(--border-color)",
    paddingBottom: "12px",
    transition: { duration: 0.2 },
  },
};

function UserTypeSelector({ setFieldValue, setUserType, ...props }) {
  const { auth } = useContext(TranslationContext);
  const {
    buyer,
    breeder,
    // shelter
  } = auth;
  const [meta] = useField(props);

  function selectUserType(value) {
    setFieldValue(props.name, value);
    if (setUserType) setUserType(value);
  }

  return (
    <div className="type-selector">
      <motion.div
        className="type-selector__btn-box"
        initial={false}
        animate={meta.value === BUYER_USER_TYPE ? "selected" : "unselected"}
      >
        <motion.button
          className="type-selector__btn"
          type="button"
          variants={userTypeVariants}
          style={{
            pointerEvents: meta.value === BUYER_USER_TYPE ? "none" : "auto",
          }}
          onClick={() => selectUserType(BUYER_USER_TYPE)}
        >
          {buyer}
        </motion.button>
      </motion.div>

      <motion.div
        className="type-selector__btn-box"
        initial={false}
        animate={meta.value === BREEDER_USER_TYPE ? "selected" : "unselected"}
      >
        <motion.button
          className="type-selector__btn"
          type="button"
          variants={userTypeVariants}
          style={{
            pointerEvents: meta.value === BREEDER_USER_TYPE ? "none" : "auto",
          }}
          onClick={() => selectUserType(BREEDER_USER_TYPE)}
        >
          {breeder}
        </motion.button>
      </motion.div>

      {/* <motion.div
        className="type-selector__btn-box"
        initial={false}
        animate={meta.value === SHELTER_USER_TYPE ? "selected" : "unselected"}
      >
        <motion.button
          className="type-selector__btn"
          type="button"
          variants={userTypeVariants}
          style={{
            pointerEvents: meta.value === SHELTER_USER_TYPE ? "none" : "auto",
          }}
          onClick={() => selectUserType(SHELTER_USER_TYPE)}
        >
          {shelter}
        </motion.button>
      </motion.div> */}
    </div>
  );
}

export default UserTypeSelector;
