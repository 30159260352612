import { useContext, useState, useRef, useEffect } from "react";
import { Formik, Form } from "formik";
import { motion, AnimatePresence } from "framer-motion";
import { TranslationContext } from "../../../../assets/contexts/translationContext";
import { SubcategoryContext } from "../../../../assets/contexts/subcategoryContext";
import { CurrentUserContext } from "../../../../assets/contexts/currentUserContext";
import mainApi from "../../../../assets/api/MainApi";
import useWindowSize from "../../../../assets/hooks/useWindowSize";
import InputWithFormik from "../../../InputWithFormik/InputWithFormik";
// import SelectWithFormik from "../../../SelectWithFormik/SelectWithFormik";
import SelectInput from "../../../SelectInput/SelectInput";
import SwitchCheckboxWithFormik from "../../../SwitchCheckboxWithFormik/SwitchCheckboxWithFormik";
import SwitchSelectWithFormik from "../../../SwitchSelectWithFormik/SwitchSelectWithFormik";
import ToggleBoxWithFormik from "../../../ToggleBoxWithFormik/ToggleBoxWithFormik";
import AvatarBox from "../../../AvatarBox/AvatarBox";
import DeskPopup from "../../DeskPopup/DeskPopup";
import MiniPreloader from "../../../MiniPreloader/MiniPreloader";
import {
  BUYER_USER_TYPE,
  ADD_BREED_POPUP,
  BREED_FILTER,
  ADDRESS_HINT_TYPE,
  INSTAGRAM_REGEXT_PATTERN,
  LOF_ALL_TYPE,
  LOF_SOME_TYPE,
  LOF_NO_TYPE,
} from "../../../../assets/utils/constants";
import { CloseIcon, EditIcon } from "../../../../assets/icons/icons";
import "./ProfileInfo.css";

const searchBarVariants = {
  open: {
    clipPath: "inset(0% 0% 0% 0% round 10px)",
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.5,
      delayChildren: 0.2,
      staggerChildren: 0.05,
    },
  },
  closed: {
    clipPath: "inset(0% 100% -0% 0% round 10px)",
    transition: {
      type: "spring",
      bounce: 0,
      duration: 0.3,
    },
  },
};

function ProfileInfo({
  language,
  isProfile,
  initialValues,
  validationSchema,
  onSubmit,
  avatarFileValidity,
  onAvatarChange,
  submitText,
  addressHints,
  suggestAddress,
  isPrelouderVisible,
  setIsPrelouderVisible,
  setAddressHints,
  apiError,
  emailError,
}) {
  // console.log(initialValues);
  const formRef = useRef(null);
  const { desk, labels, errors } = useContext(TranslationContext);
  const {
    profileTitle,
    breederInfo,
    addBreedBtn,
    socialNetworks,
    placeholder,
    country,
    notifications,
  } = desk;
  const { searchNothing } = errors;
  const user = useContext(CurrentUserContext);
  const subcategory = useContext(SubcategoryContext);
  const searchInput = useRef(null);
  const searchOverlay = useRef(null);
  const searchInputPopup = useRef(null);
  const { width } = useWindowSize();
  const [breedsList, setBreedsList] = useState([]);
  const [isSearchBarOpen, setSearchBarOpen] = useState(false);
  const [breedSearchKey, setBreedSearchKey] = useState("");
  const [breedsHints, setBreedsHints] = useState([]);
  const [isBreedHintsVisible, setIsBreedHintsVisible] = useState(false);
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [isAddressHintsVisible, setIsAddressHintsVisible] = useState(false);
  const [isAddressChange, setIsAddressChange] = useState(false);
  const [isAddressTiping, setIsAddressTiping] = useState(false);
  const [apiErrText, setApiErrText] = useState("");

  useEffect(() => {
    if (!subcategory) return;

    setBreedsList(
      subcategory.filters.find((item) => item.value === BREED_FILTER).options
    );
  }, [subcategory]);

  useEffect(() => {
    function handleOverlayClose(evt) {
      if (searchOverlay.current === evt.target) {
        closeSearchBar();
      }
    }

    document.addEventListener("mousedown", handleOverlayClose);
    return () => {
      document.removeEventListener("mousedown", handleOverlayClose);
    };
  });

  useEffect(() => {
    switch (true) {
      case apiError !== "":
        setApiErrText(errors.apiErr);
        break;
      default:
        setApiErrText("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiError]);

  useEffect(() => {
    if (width > 550 && isAddPopupOpen) {
      closeSearchBar();
      closePopup();
    }
  }, [isAddPopupOpen, width]);

  useEffect(() => {
    if (!formRef || !isAddressChange) return;

    const validateField = formRef.current.validateField;
    validateField("addressValue");
  }, [isAddressChange]);

  function openPopup() {
    setIsAddPopupOpen(true);
    searchInputPopup.current.focus();
  }

  function closePopup() {
    setIsAddPopupOpen(false);
  }

  function openSearchBar() {
    setSearchBarOpen(true);
    searchInput.current.focus();
  }

  function closeSearchBar() {
    setSearchBarOpen(false);
    setBreedSearchKey("");
    setBreedsHints([]);
    setIsBreedHintsVisible(false);
  }

  function handleBreedKeyChange(evt) {
    const value = evt.target.value;
    setBreedSearchKey(value);
    if (value.length > 0)
      setBreedsHints(
        breedsList.filter((breed) =>
          breed.title[user.language].toLowerCase().includes(value.toLowerCase())
        )
      );
    setIsBreedHintsVisible(value.length > 0);
  }

  function handleBreedHintClick(breed, setFieldValue, arr) {
    setFieldValue("breeds", [...arr, breed]);
    closeSearchBar();
    closePopup();
  }

  function handleRemoveBreed(arr, breed, setFieldValue) {
    const breeds = arr.slice();
    const newBreeds = breeds.filter((item) => item.value !== breed.value);
    setFieldValue("breeds", newBreeds);
  }

  function handlePlaceKeyChange(evt, setFieldValue, name) {
    evt.preventDefault();
    const value = evt.target.value;
    console.log(name);
    setFieldValue(name, value);
    setIsAddressHintsVisible(value.length > 2);
    setIsPrelouderVisible(true);
    setIsAddressTiping(true);
    if (value.length <= 2) {
      setIsPrelouderVisible(false);
      setAddressHints([]);
    }
  }

  function handlePlaceKeyBlur() {
    setIsAddressTiping(false);
  }

  useEffect(() => {
    if (!isAddressTiping) return;

    const value = formRef?.current?.values?.addressValue;
    console.log(value);
    if (!value) return;
    const delayDebounceFn = setTimeout(() => {
      console.log(value);
      if (value && value.length > 2)
        suggestAddress({ searchKey: value, types: ADDRESS_HINT_TYPE });
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formRef.current, isAddressTiping]);

  function handlePlaceHintClick(hint, setFieldValue) {
    setIsAddressChange(false);
    mainApi
      .getPlace({ place_id: hint.place_id })
      .then((res) => {
        setFieldValue("address", res);
        setFieldValue("addressValue", res.formatted_address);
        setIsAddressChange(true);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsAddressHintsVisible(false);
      });
  }

  function handleSelect(name, value, setFieldValue) {
    console.log(name, value);
    setFieldValue(name, value.key);
  }

  function handlePhoneChange(evt, setFieldValue, name) {
    evt.preventDefault();
    let value = evt.target.value.replace(/\D/g, "");
    let formattedValue = "";
    if (!value) {
      setFieldValue(name, "");
    } else {
      switch (true) {
        case value.length <= 2:
          formattedValue = "+" + value.substring(0);
          setFieldValue(name, formattedValue);
          break;
        case value.length <= 3:
          formattedValue =
            "+" + value.substring(0, 2) + " " + value.substring(2);
          setFieldValue(name, formattedValue);
          break;
        case value.length <= 5:
          formattedValue =
            "+" +
            value.substring(0, 2) +
            " " +
            value.substring(2, 3) +
            " " +
            value.substring(3);
          setFieldValue(name, formattedValue);
          break;
        case value.length <= 7:
          formattedValue =
            "+" +
            value.substring(0, 2) +
            " " +
            value.substring(2, 3) +
            " " +
            value.substring(3, 5) +
            " " +
            value.substring(5);
          setFieldValue(name, formattedValue);
          break;
        case value.length <= 9:
          formattedValue =
            "+" +
            value.substring(0, 2) +
            " " +
            value.substring(2, 3) +
            " " +
            value.substring(3, 5) +
            " " +
            value.substring(5, 7) +
            " " +
            value.substring(7);
          setFieldValue(name, formattedValue);
          break;
        case value.length <= 11:
          formattedValue =
            "+" +
            value.substring(0, 2) +
            " " +
            value.substring(2, 3) +
            " " +
            value.substring(3, 5) +
            " " +
            value.substring(5, 7) +
            " " +
            value.substring(7, 9) +
            " " +
            value.substring(9);
          setFieldValue(name, formattedValue);
          break;
        case value.length > 11:
          formattedValue =
            "+" +
            value.substring(0, 2) +
            " " +
            value.substring(2, 3) +
            " " +
            value.substring(3, 5) +
            " " +
            value.substring(5, 7) +
            " " +
            value.substring(7, 9) +
            " " +
            value.substring(9, 11);
          setFieldValue(name, formattedValue);
          break;
        default:
          setFieldValue(name, formattedValue);
      }
    }
  }

  function handleEnableNotifications(evt, setFieldValue) {
    const checked = evt.target.checked;
    setFieldValue("isEnabled", checked);
    setFieldValue("isNewMessages", checked);
    setFieldValue("isPromotions", checked);
  }

  return (
    <>
      {user ? (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          innerRef={formRef}
          onSubmit={(values, { setSubmitting, setFieldError }) => {
            onSubmit(values, setSubmitting, setFieldError);
          }}
        >
          {({ values, isValid, dirty, isSubmitting, setFieldValue }) => (
            <Form
              className={`profile-info__form ${
                !isProfile ? "profile-info__form_type_reg" : ""
              } ${
                user.user_type === BUYER_USER_TYPE
                  ? "profile-info__form_type_buyer"
                  : ""
              }`}
              encType="multipart/form-data"
            >
              <div className="profile-info__container">
                <div className="profile-info__forms-block">
                  <div className="profile-info__form-container">
                    <p className="profile-info__title profile-info__title_type_profile">
                      {profileTitle}
                    </p>
                    <div className="profile-info__inputs-block profile-info__inputs-block_type_info">
                      {isProfile ? (
                        <div className="profile-info__input-box profile-info__input-box_type_name">
                          <InputWithFormik
                            label={`${labels.name}*`}
                            id="name"
                            name="name"
                            type="text"
                            autoComplete="off"
                          />
                        </div>
                      ) : null}

                      {isProfile ? (
                        <div className="profile-info__input-box profile-info__input-box_type_email">
                          <InputWithFormik
                            label={`${labels.email}*`}
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="off"
                          />
                        </div>
                      ) : null}

                      <div className="profile-info__input-box profile-info__input-box_type_phone">
                        <InputWithFormik
                          label={`${labels.phone}${
                            user.user_type !== BUYER_USER_TYPE ? "*" : ""
                          }`}
                          id="phone"
                          name="phone"
                          type="tel"
                          autoComplete="off"
                          onChange={handlePhoneChange}
                          {...{ setFieldValue }}
                        />
                      </div>

                      <div className="profile-info__input-box profile-info__input-box_type_country">
                        <SelectInput
                          label={`${labels.country}*`}
                          name="country"
                          value={country}
                          isGrey
                          readOnly
                        />
                        {/* <SelectWithFormik
                    label={labels.country}
                    id="country"
                    name="country"
                    type="text"
                    selectList={COUNTRY_LIST}
                    onSelect={handleSelect}
                    {...{ setFieldValue }}
                  /> */}
                      </div>

                      {user.user_type !== BUYER_USER_TYPE ? (
                        <div className="profile-info__input-box profile-info__input-box_type_address">
                          <InputWithFormik
                            label={
                              user.user_type === BUYER_USER_TYPE
                                ? `${labels.address}*`
                                : `${labels.breederAddress}*`
                            }
                            id="addressValue"
                            name="addressValue"
                            type="search"
                            autoComplete="off"
                            onChange={handlePlaceKeyChange}
                            onBlur={handlePlaceKeyBlur}
                            {...{
                              setFieldValue,
                            }}
                          />

                          <div
                            className={`profile-info__place-hint-box ${
                              isAddressHintsVisible
                                ? "profile-info__place-hint-box_visible"
                                : ""
                            }`}
                          >
                            {!isPrelouderVisible ? (
                              <ul className="profile-info__place-list">
                                {addressHints.length > 0 ? (
                                  addressHints.map((hint) => (
                                    <li
                                      className="profile-info__place-item"
                                      key={`place-hint-${hint.place_id}`}
                                      onClick={() =>
                                        handlePlaceHintClick(
                                          hint,
                                          setFieldValue
                                        )
                                      }
                                    >
                                      <p className="profile-info__place-text">
                                        {hint.main_text}
                                      </p>
                                    </li>
                                  ))
                                ) : (
                                  <li
                                    className="profile-info__place-item profile-info__place-item_type_nothing"
                                    key="nothing-location"
                                  >
                                    <p className="profile-info__place-text">
                                      {searchNothing}
                                    </p>
                                  </li>
                                )}
                              </ul>
                            ) : (
                              <MiniPreloader />
                            )}
                          </div>
                        </div>
                      ) : null}

                      {user.user_type !== BUYER_USER_TYPE ? (
                        <div className="profile-info__input-box profile-info__input-box_type_description">
                          <InputWithFormik
                            label={`${labels.profileDescription}*`}
                            id="description"
                            name="description"
                            type="text"
                            autoComplete="off"
                            isTextarea={true}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {user.user_type !== BUYER_USER_TYPE ? (
                    <div className="profile-info__form-container">
                      <p className="profile-info__title">{breederInfo}</p>
                      <div className="profile-info__inputs-block profile-info__inputs-block_type_breeders">
                        <div className="profile-info__breeds-box">
                          <motion.div
                            className="profile-info__breeds-list-box"
                            initial={{
                              height: "0px",
                              opacity: 0,
                              visibility: "hidden",
                            }}
                            animate={{
                              height:
                                values.breeds.length > 0
                                  ? "fit-content"
                                  : "0px",
                              opacity: values.breeds.length > 0 ? 1 : 0,
                              visibility:
                                values.breeds.length > 0 ? "visible" : "hidden",
                            }}
                            transition={{ duration: 0.15 }}
                          >
                            {values.breeds.length > 0 ? (
                              <ul className="profile-info__breeds-list">
                                <AnimatePresence mode="popLayout">
                                  {values.breeds.map((breed) => (
                                    <motion.li
                                      className="profile-info__breed-box"
                                      key={`breed-item-${breed.value}`}
                                      initial={{ opacity: 0 }}
                                      animate={{ opacity: 1 }}
                                      exit={{ opacity: 0 }}
                                      transition={{ duration: 0.15 }}
                                    >
                                      <p className="profile-info__breed-text">
                                        {
                                          breed.title[
                                            user && user.language
                                              ? user.language
                                              : language.toLowerCase()
                                          ]
                                        }
                                      </p>
                                      <button
                                        className="profile-info__delete-breed-btn"
                                        type="button"
                                        aria-label="Delete breed"
                                        onClick={() =>
                                          handleRemoveBreed(
                                            values.breeds,
                                            breed,
                                            setFieldValue
                                          )
                                        }
                                      >
                                        {CloseIcon(
                                          "profile-info__delete-icon",
                                          "profile-info__delete-icon-stroke"
                                        )}
                                      </button>
                                    </motion.li>
                                  ))}
                                </AnimatePresence>
                              </ul>
                            ) : null}
                          </motion.div>

                          <motion.div
                            className="profile-info__add-breed-box"
                            initial={false}
                            animate={isSearchBarOpen ? "open" : "closed"}
                          >
                            <button
                              className={`profile-info__breed-box profile-info__breed-box_type_add-btn ${
                                values.breeds.length >= 5
                                  ? "profile-info__breed-box_disabled"
                                  : ""
                              }`}
                              type="button"
                              onClick={() =>
                                width > 550 ? openSearchBar() : openPopup()
                              }
                            >
                              <p className="profile-info__breed-text profile-info__breed-text_type_color">
                                {addBreedBtn}
                              </p>
                            </button>
                            <div
                              className={`profile-info__search-block ${
                                isSearchBarOpen
                                  ? "profile-info__search-block_visible"
                                  : ""
                              } ${
                                isBreedHintsVisible
                                  ? "profile-info__search-block_type_hint"
                                  : ""
                              }`}
                            >
                              <motion.div
                                className="profile-info__search-bar"
                                variants={searchBarVariants}
                                style={{
                                  pointerEvents: isSearchBarOpen
                                    ? "auto"
                                    : "none",
                                }}
                              >
                                <div className="profile-info__search-input-box">
                                  <input
                                    ref={searchInput}
                                    className="profile-info__search-input"
                                    id="breedSearch"
                                    name="search"
                                    type="text"
                                    autoComplete="off"
                                    placeholder={placeholder.breedSearch}
                                    value={breedSearchKey || ""}
                                    onChange={handleBreedKeyChange}
                                  />
                                </div>
                                <button
                                  className="profile-info__search-close-btn"
                                  type="button"
                                  aria-label="Close search bar"
                                  onClick={closeSearchBar}
                                >
                                  {CloseIcon(
                                    "profile-info__close-icon",
                                    "profile-info__close-icon-stroke"
                                  )}
                                </button>
                              </motion.div>
                              <div
                                className={`profile-info__hint-box ${
                                  isBreedHintsVisible
                                    ? "profile-info__hint-box_visible"
                                    : ""
                                }`}
                              >
                                <ul className="profile-info__breeds-list">
                                  {breedsHints.length > 0 ? (
                                    breedsHints.slice(0, 12).map((hint) => (
                                      <li
                                        className={`profile-info__breed-box ${
                                          values.breeds.some(
                                            (breed) =>
                                              breed.value === hint.value
                                          )
                                            ? "profile-info__breed-box_hidden"
                                            : ""
                                        }`}
                                        key={`breed-hint-${hint.value}`}
                                        onClick={() =>
                                          handleBreedHintClick(
                                            hint,
                                            setFieldValue,
                                            values.breeds
                                          )
                                        }
                                      >
                                        <p className="profile-info__breed-text">
                                          {hint.title[user.language]}
                                        </p>
                                      </li>
                                    ))
                                  ) : (
                                    <li
                                      className="profile-info__nothing-box"
                                      key="nothing-breed"
                                    >
                                      <p className="profile-info__breed-text profile-info__breed-text_type_nothing">
                                        {searchNothing}
                                      </p>
                                    </li>
                                  )}
                                </ul>
                              </div>
                              <div
                                className="profile-info__search-overlay"
                                ref={searchOverlay}
                                onTouchStart={closeSearchBar}
                              />
                            </div>
                          </motion.div>
                        </div>

                        <div className="profile-info__input-box">
                          <InputWithFormik
                            label={`${labels.startYear}*`}
                            id="startYear"
                            name="startYear"
                            type="text"
                            autoComplete="off"
                            isPlaceholder={true}
                          />
                        </div>

                        <div className="profile-info__input-box">
                          <SwitchCheckboxWithFormik
                            label={`${labels.verifiedBreeder}*`}
                            id="isVerified"
                            name="isVerified"
                          />
                        </div>

                        <div className="profile-info__input-box">
                          <InputWithFormik
                            label={labels.sirenNumber}
                            id="siren"
                            name="siren"
                            type="text"
                            autoComplete="off"
                            placeholder={placeholder.blank}
                            isPlaceholder={true}
                          />
                        </div>

                        <div className="profile-info__input-box">
                          <SwitchCheckboxWithFormik
                            label={`${labels.breedClub}*`}
                            id="isBreedClub"
                            name="isBreedClub"
                          />
                        </div>

                        <div className="profile-info__input-box">
                          <SwitchCheckboxWithFormik
                            label={`${labels.geneticTests}*`}
                            id="isGenetic"
                            name="isGenetic"
                          />
                        </div>

                        <div className="profile-info__input-box">
                          <SwitchSelectWithFormik
                            label={`${labels.lof}*`}
                            id="lof"
                            name="lof"
                            isProfileLof={true}
                            list={[
                              {
                                title: labels.all,
                                key: LOF_ALL_TYPE,
                              },
                              {
                                title: labels.some,
                                key: LOF_SOME_TYPE,
                              },
                              {
                                title: labels.no,
                                key: LOF_NO_TYPE,
                              },
                            ]}
                            onSelect={handleSelect}
                            {...{ setFieldValue }}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {user.user_type !== BUYER_USER_TYPE ? (
                    <div className="profile-info__form-container">
                      <p className="profile-info__title">{socialNetworks}</p>
                      <div className="profile-info__inputs-block profile-info__inputs-block_type_social">
                        <div className="profile-info__input-box">
                          <InputWithFormik
                            label={labels.facebook}
                            id="facebook"
                            name="facebook"
                            type="text"
                            autoComplete="off"
                            placeholder={placeholder.usernameLink}
                            isPlaceholder={true}
                            // isNoValidationVisible={true}
                          />
                        </div>

                        <div className="profile-info__input-box">
                          <InputWithFormik
                            label={labels.instagram}
                            id="instagram"
                            name="instagram"
                            type="text"
                            autoComplete="off"
                            placeholder={placeholder.usernameLink}
                            isPlaceholder={true}
                            // isNoValidationVisible={true}
                          />
                        </div>
                        {/* REMOVE AFTER TESTS */}
                        {/* <button onClick={() => {
                      const matches = values.instagram.match(INSTAGRAM_REGEXT_PATTERN)
                      console.log('ProfileInfo.js || 732 || value, matches, matches[1] ', values.instagram, matches, matches && matches[1])
                    }} type="button">
                      check
                    </button> */}

                        <div className="profile-info__input-box">
                          <InputWithFormik
                            label={labels.whatsapp}
                            id="whatsapp"
                            name="whatsapp"
                            type="text"
                            autoComplete="off"
                            placeholder={placeholder.usernameNumber}
                            isPlaceholder={true}
                            // isNoValidationVisible={true}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                {isProfile ? (
                  <div className="profile-info__avatar-block">
                    <div className="profile-info__avatar-box">
                      <AvatarBox avatar={values.avatar} />
                    </div>
                    {avatarFileValidity.errorMessage ? (
                      <p className="profile-info__error-msg">
                        {avatarFileValidity.errorMessage}
                      </p>
                    ) : null}
                    <div className="profile-info__file-form">
                      <label
                        className="profile-info__custom-input-file"
                        htmlFor="avatar"
                      >
                        <input
                          className="profile-info__invisible-input-file"
                          id="avatar"
                          name="avatar"
                          type="file"
                          accept="image/png, image/jpg, image/jpeg, image/webp"
                          onChange={(evt) => onAvatarChange(evt, setFieldValue)}
                        />
                        {EditIcon({
                          mainClassName: "profile-info__edit-icon",
                          strokeClassName: "profile-info__edit-icon-stroke",
                        })}
                      </label>
                    </div>
                  </div>
                ) : null}
              </div>

              {isProfile ? (
                <div className="profile-info__notifications-block">
                  <p className="profile-info__title">{notifications}</p>
                  <div className="profile-info__notifications-box">
                    <ToggleBoxWithFormik
                      label={labels.enableNotifications}
                      id="isEnabled"
                      name="isEnabled"
                      onChange={handleEnableNotifications}
                      {...{ setFieldValue }}
                    />

                    <motion.div
                      className="profile-info__notifications"
                      initial={{
                        height: "0px",
                        marginBottom: "0px",
                        opacity: 0,
                        visibility: "hidden",
                      }}
                      animate={{
                        height: values.isEnabled ? "fit-content" : "0px",
                        marginBottom: values.isEnabled
                          ? width > 850
                            ? "20px"
                            : "12px"
                          : "0px",
                        opacity: values.isEnabled ? 1 : 0,
                        visibility: values.isEnabled ? "visible" : "hidden",
                      }}
                      transition={{ duration: 0.2 }}
                    >
                      <ToggleBoxWithFormik
                        label={labels.newMessages}
                        id="isNewMessages"
                        name="isNewMessages"
                      />

                      {/* <ToggleBoxWithFormik
                  label={labels.breedSubscriptions}
                  id="isBreedSubscriptions"
                  name="isBreedSubscriptions"
                  /> */}

                      <ToggleBoxWithFormik
                        label={labels.promotions}
                        id="isPromotions"
                        name="isPromotions"
                      />
                    </motion.div>
                  </div>
                </div>
              ) : null}

              <div className="profile-info__submit-box">
                <motion.button
                  className="profile-info__submit-btn"
                  type="submit"
                  initial={{
                    background: "var(--disabled-btn-color)",
                    borderColor: "var(--disabled-btn-color)",
                  }}
                  animate={{
                    background:
                      !(isValid && dirty) || isSubmitting
                        ? "var(--disabled-btn-color)"
                        : "var(--active-btn-color)",
                    borderColor:
                      !(isValid && dirty) || isSubmitting
                        ? "var(--disabled-btn-color)"
                        : "var(--active-btn-color)",
                  }}
                  whileHover={{
                    background: "var(--hover-btn-color)",
                    borderColor: "var(--hover-btn-color)",
                  }}
                  whileTap={{
                    background: "var(--tap-btn-color)",
                    borderColor: "var(--tap-btn-color)",
                  }}
                  transition={{ duration: 0.2, ease: "easeInOut" }}
                  style={{
                    pointerEvents:
                      !(isValid && dirty) || isSubmitting ? "none" : "auto",
                  }}
                >
                  <motion.p
                    className="profile-info__btn-text"
                    initial={{ opacity: 0.6 }}
                    animate={{
                      opacity: !(isValid && dirty) || isSubmitting ? 0.6 : 1,
                    }}
                    transition={{ duration: 0.2, ease: "easeInOut" }}
                  >
                    {submitText}
                  </motion.p>
                </motion.button>

                <motion.div
                  className="profile-info__error-box"
                  initial={{
                    height: "0px",
                    marginTop: "0px",
                    opacity: 0,
                    visibility: "hidden",
                  }}
                  animate={{
                    height: apiError || emailError ? "14px" : "0px",
                    marginTop: apiError || emailError ? "6px" : "0px",
                    opacity: apiError || emailError ? 1 : 0,
                    visibility: apiError || emailError ? "visible" : "hidden",
                  }}
                  transition={{ duration: 0.15 }}
                >
                  {apiError ? (
                    <p className="profile-info__error">{apiErrText}</p>
                  ) : null}
                  {emailError ? (
                    <p className="profile-info__error">{emailError}</p>
                  ) : null}
                </motion.div>
              </div>

              {width <= 550 ? (
                <DeskPopup
                  isOpen={isAddPopupOpen}
                  onClose={closePopup}
                  title={addBreedBtn}
                  type={ADD_BREED_POPUP}
                >
                  <motion.div
                    className="profile-info__add-breed-box profile-info__add-breed-box_type_popup"
                    initial={false}
                    animate="open"
                  >
                    <div className="profile-info__search-block profile-info__search-block_visible">
                      <motion.div
                        className="profile-info__search-bar"
                        variants={searchBarVariants}
                        style={{ pointerEvents: "auto" }}
                      >
                        <div className="profile-info__search-input-box">
                          <input
                            ref={searchInputPopup}
                            className="profile-info__search-input"
                            id="popupBreedSearch"
                            name="search"
                            type="text"
                            autoComplete="off"
                            placeholder={placeholder.breedSearch}
                            value={breedSearchKey || ""}
                            onChange={handleBreedKeyChange}
                          />
                        </div>
                      </motion.div>
                      <div
                        className={`profile-info__hint-box ${
                          isBreedHintsVisible
                            ? "profile-info__hint-box_visible"
                            : ""
                        }`}
                      >
                        <ul className="profile-info__breeds-list">
                          {breedsHints.length > 0 ? (
                            breedsHints.map((hint) => (
                              <li
                                className={`profile-info__breed-box ${
                                  values.breeds.some(
                                    (breed) => breed.value === hint.value
                                  )
                                    ? "profile-info__breed-box_hidden"
                                    : ""
                                }`}
                                key={`mobile-breed-hint-${hint.value}`}
                                onClick={() =>
                                  handleBreedHintClick(
                                    hint,
                                    setFieldValue,
                                    values.breeds
                                  )
                                }
                              >
                                <p className="profile-info__breed-text">
                                  {hint.title[user.language]}
                                </p>
                              </li>
                            ))
                          ) : (
                            <li
                              className="profile-info__nothing-box"
                              key="nothing-breed-popup"
                            >
                              <p className="profile-info__breed-text profile-info__breed-text_type_nothing">
                                {searchNothing}
                              </p>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </motion.div>
                </DeskPopup>
              ) : null}
            </Form>
          )}
        </Formik>
      ) : null}
    </>
  );
}

export default ProfileInfo;
