import { BritainFlag, FranceFlag } from "../icons/icons";
// import { getRandomId } from "./utils";

export const MAIN_URL = "https://app.pet4me.fr";
// export const MAIN_URL = "https://pet4me-backend.cabatest.ru";
export const WS_MAIN_URL = 'wss://app.pet4me.fr'

export const PREV = "prev";

export const NEXT = "next";

export const EN_LANGUAGE = "EN";

export const FR_LANGUAGE = "FR";

export const LANGUAGES = {
  EN: {
    lang: "En",
    flag: BritainFlag("flag-icon"),
  },
  FR: {
    lang: "Fr",
    flag: FranceFlag("flag-icon"),
  },
};

export const DROPDOWN_BREEDS = "breeds";

export const DROPDOWN_COMMUNITY = "community";

export const DROPDOWN_PROFILE = "profile";

export const DROPDOWN_LANG = "lang";

export const SMALL_BREEDS = "small";

export const MEDIUM_BREEDS = "medium";

export const BIG_BREEDS = "big";

export const BREED_CARD_WIDTH = 102;

export const TRANSITION_SPEED = 200;

export const MOBILE_NAV_TYPE = "nav";

export const REG_AUTH_TYPE = "registration";

export const LOGIN_AUTH_TYPE = "login";

export const RECOVERY_AUTH_TYPE = "recovery";

export const PASSWORD_AUTH_TYPE = "password";

export const BUYER_USER_TYPE = "users";

export const BREEDER_USER_TYPE = "breeders";

export const SHELTER_USER_TYPE = "shelter";

export const ESC_KEY = "Escape";

export const PAGE_NOT_FOUND_CODE = 404;

export const NEW_DEPOSITS_TYPE = "new";

export const APPROVED_DEPOSITS_TYPE = "approved";

export const PUBLISHED_ADVERT_TYPE = "published";

export const DRAFT_ADVERT_TYPE = "drafts";

export const LOF_ALL_TYPE = "all";

export const LOF_SOME_TYPE = "some";

export const LOF_NO_TYPE = "no";

export const CHANGE_PASSWORD_POPUP = "change_password";

export const DELETE_ACCOUNT_POPUP = "delete_account";

export const ADD_BREED_POPUP = "add_breed";

export const CHECK_CODE_POPUP = "check_code";

export const ENTER_KEY = "Enter";

export const DEFAULT_MEETS_NUMBER = 2;

export const DEFAULT_MOBILE_MEETS_NUMBER = 1;

export const BLOG_CARDS_NUMBER = 14;

export const BREEDERS_CARDS_NUMBER = 4;

export const ITEMS_CARDS_NUMBER = 6;

export const CHATS_LIMIT_NUMBER = 25;

export const MESSAGES_LIMIT_NUMBER = 100;

export const UPCOMING_LIMIT_NUMBER = 10;

export const BLOG_COLLECTION = "blog";

export const ITEMS_COLLECTION = "items";

export const BREEDERS_COLLECTION = "breeders";

export const ITEMS_ADVERTS_COLLECTION = "items";

export const DRAFTS_ADVERTS_COLLECTION = "drafts";

export const AVATAR_IMAGE_TYPE = "avatars";

export const IMAGES_IMAGE_TYPE = "images";

export const PROFILE_IMAGE_TYPE = "profile_photos";

export const NEWS_IMAGE_TYPE = "news";

export const PHOTOS_IMAGE_TYPE = "photos";

export const ADDRESS_HINT_TYPE = "address";

export const CITY_HINT_TYPE = "(cities)";

export const REGIONS_HINT_TYPE = "(regions)";

export const DISTANCE_LIST = [
  "50000",
  "3000",
  "10000",
  "20000",
  "40000",
  "100000",
  "200000",
  "2000000",
];

export const LOCATION_FILTER = "location";

export const BREED_FILTER = "breed";

export const AKITA_BREED = "akita";

export const BASENJI_BREED = "basenji";

export const BASSET_BREED = "basset-hound";

export const BEAGLE_BREED = "beagle";

export const BERGAMASCO_BREED = "bergamasco";

export const BERNESE_MOUNTAIN_BREED = "bernese-mountain";

export const BORDER_COLLIE_BREED = "border-collie";

export const BOXER_BREED = "boxer";

export const BICHON_BREED = "bichon-frise";

export const BRIARD_BREED = "briard";

export const BULLDOG_BREED = "bulldog";

export const BULL_TERRIER_BREED = "bull-terrier";

export const COCKER_SPANIEL_BREED = "cocker-spaniel";

export const COLLIE_BREED = "collie";

export const CORGI_BREED = "corgi";

export const CHINESE_CRESTED_BREED = "chinese-crested";

export const CHOW_BREED = "chow-chow";

export const CHIHUAHUA_BREED = "chihuahua";

export const DALMATIAN_BREED = "dalmatian";

export const DOBERMAN_BREED = "doberman";

export const DACHSHUND_BREED = "dachshund";

export const FRENCH_BULLDOG_BREED = "french-bulldog";

export const GOLDEN_RETRIEVER_BREED = "golden-retriever";

export const GERMAN_SHEPHERD_BREED = "german-shepherd";

export const GREAT_DANE_BREED = "great-dane";

export const GREYHOUND_BREED = "greyhound";

export const HAVANESE_BREED = "havanese";

export const JACK_RUSSELL_BREED = "jack-russell";

export const JAPANESE_CHIN_BREED = "japanese-spaniel";

export const LABRADOR_BREED = "labrador";

export const PAPILLON_BREED = "continental-toy-spaniel";

export const PHARAOH_HOUND_BREED = "pharaoh-hound";

export const POINTER_BREED = "english-pointer";

export const POMERANIAN_BREED = "pomeranian";

export const POODLE_BREED = "poodle";

export const PITBULL_BREED = "pitbull";

export const PUG_BREED = "pug";

export const ROTTWEILER_BREED = "rottweiler";

export const SAINT_BERNARD_BREED = "saint-bernard";

export const SALUKI_BREED = "saluki";

export const SAMOYED_BREED = "samoyed";

export const SCHNAUZER_BREED = "standard-schnauzer";

export const SHAR_PEI_BREED = "shar-pei";

export const SHIBA_INU_BREED = "shiba-inu";

export const SHIH_TZU_BREED = "shih-tzu";

export const HUSKY_BREED = "siberian-husky";

export const TIBETAN_MASTIFF_BREED = "tibetan-mastiff";

export const YORKSHIRE_TERRIER_BREED = "yorkshire-terrier";

export const INIT_EDIT_VALUE = {
  images: null,
  title: "",
  offer_type: "sale",
  with_deposit: true,
  description: "",
  selected_breed: null,
  vaccination: true,
  wormed: true,
  microchip: true,
  dna_test: true,
  date_of_birth: "",
  litter_id: "",
  litter_lof: "",
  father: {
    breed_data: null,
    colour: "",
    date_of_birth: "",
    image: null,
    lof: "",
    name: "",
  },
  mother: {
    breed_data: null,
    colour: "",
    date_of_birth: "",
    image: null,
    lof: "",
    name: "",
  },
  children: {
    male: [],
    female: [],
  },
};

export const INIT_EDIT_VALIDITY = {
  images: false,
  title: false,
  description: false,
  selected_breed: false,
  date_of_birth: false,
  father: false,
  mother: false,
  children: {
    male: [],
    female: [],
  },
  show_error: false,
};

export const PRO_MAIN_TYPE = "pro"

export const MONTHLY_PRO_SUB_LOOKUP_KEY = "monthly_pro_subscription";

export const YEARLY_PRO_SUB_LOOKUP_KEY = "yearly_pro_subscription"


export const DEFAULT_MAX_IMG_SIZE = 15
export const DEFAULT_MAX_AVATAR_SIZE = 5

export const FACEBOOK_REGEXT_PATTERN = /^(?:https?:)?\/\/(?:www\.)?(?:facebook|fb)\.com\/((?![A-z]+\.php)(?!marketplace|gaming|watch|me|messages|help|search|groups)[A-z0-9_\-\.]+)\/?|^(?:www\.)?(?:facebook|fb)\.com\/((?![A-z]+\.php)(?!marketplace|gaming|watch|me|messages|help|search|groups)[A-z0-9_\-\.]+)\/?|^(?:https?:)?\/\/(?:www\.)facebook.com\/(?:profile.php\?id=)?([0-9]+)|^(?:www\.)facebook.com\/(?:profile.php\?id=)?([0-9]+)|^([0-9]+)|^([A-z0-9_\\-\\.]+)$/
export const WHATSAPP_REGEXT_PATTERN = /^([0-9]{10,16})$|^(?:https?:)?\/\/wa.me\/([0-9]{10,16})\/?|^wa.me\/([0-9]{10,16})\/?/
export const INSTAGRAM_REGEXT_PATTERN = /^(?:https?:)?\/\/(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)\/?|^(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)\/?|^([A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?)$/

export const ALREADY_EXIST_ERR_CODE = 400
export const NOT_FOUND_ERR_CODE = 404
export const TOO_MANY_REQUESTS_ERR_CODE = 429
export const ERROR_VISIBILITY_TIME = 3000