import { useField } from "formik";
import { motion } from "framer-motion";
import "./SwitchSelectWithFormik.css";

function SwitchSelectWithFormik({
  label,
  list,
  setFieldValue,
  onSelect,
  type,
  isProfileLof,
  ...props
}) {
  const [meta] = useField(props);

  return (
    <div
      className={`formik-switch-select ${Boolean(type) ? `formik-switch-select_type_${type}` : ""
        }`}
    >
      <span className="formik-switch-select__label">{label}</span>
      <ul className="formik-switch-select__btns">
        {isProfileLof ?
          list.map((item) => (
            <motion.li
              className="formik-switch-select__btn"
              key={item.key}
              initial={{ background: "var(--light-border-color)" }}
              animate={{
                background:
                item.key === meta.value
                    ? "var(--bright-color)"
                    : "var(--light-border-color)",
              }}
              transition={{ duration: 0.15, ease: "easeInOut" }}
              onClick={() => onSelect(props.name, item, setFieldValue)}
            >
              <motion.p
                className="formik-switch-select__btn-text"
                initial={{ color: "var(--text-color)" }}
                animate={{
                  color:
                  item.key === meta.value
                      ? "var(--text-on-contrast-color)"
                      : "var(--text-color)",
                }}
                transition={{ duration: 0.15, ease: "easeInOut" }}
              >
                {item.title}
              </motion.p>
            </motion.li>
          ))
          :
          list.map((item) => (
            <motion.li
              className="formik-switch-select__btn"
              key={item}
              initial={{ background: "var(--light-border-color)" }}
              animate={{
                background:
                  item === meta.value
                    ? "var(--bright-color)"
                    : "var(--light-border-color)",
              }}
              transition={{ duration: 0.15, ease: "easeInOut" }}
              onClick={() => onSelect(props.name, item, setFieldValue)}
            >
              <motion.p
                className="formik-switch-select__btn-text"
                initial={{ color: "var(--text-color)" }}
                animate={{
                  color:
                    item === meta.value
                      ? "var(--text-on-contrast-color)"
                      : "var(--text-color)",
                }}
                transition={{ duration: 0.15, ease: "easeInOut" }}
              >
                {item}
              </motion.p>
            </motion.li>
          ))}
      </ul>
    </div>
  );
}

export default SwitchSelectWithFormik;
