import moment from "moment-timezone";

export function filterMessagesByDates(messages) {
  if (!messages || messages.length === 0) return [];

  let arrayByDate = [];

  messages.forEach((element) => {
    let date = moment(element.utc_datetime + "+00:00")
      .format("DD.MM.YYYY")
      .toString();
    switch (true) {
      case arrayByDate.length === 0:
        arrayByDate = [
          {
            date: date,
            items: [element],
          },
        ];
        break;

      case arrayByDate.filter((item) => item.date === date).length === 0:
        arrayByDate = [
          ...arrayByDate,
          {
            date: date,
            items: [element],
          },
        ];
        break;

      default:
        arrayByDate = arrayByDate.map((item) => {
          if (item.date === date) {
            return {
              ...item,
              items: [...item.items, element],
            };
          } else {
            return item;
          }
        });
        break;
    }
  });

  arrayByDate = arrayByDate.reverse().map((item) => {
    let arrayByTime = [];

    item.items.reverse().forEach((el) => {
      let time = moment(el.utc_datetime + "+00:00")
        .format("HH:mm")
        .toString();
      switch (true) {
        case arrayByTime.length === 0:
          arrayByTime = [
            {
              time: time,
              is_user: el.is_user,
              items: [el],
            },
          ];
          break;

        case arrayByTime.filter(
          (item) => item.time === time && item.is_user === el.is_user
        ).length === 0:
          arrayByTime = [
            ...arrayByTime,
            {
              time: time,
              is_user: el.is_user,
              items: [el],
            },
          ];
          break;

        default:
          switch (true) {
            case arrayByTime[arrayByTime.length - 1].is_user !== el.is_user &&
              arrayByTime[arrayByTime.length - 1].time === time:
              arrayByTime = [
                ...arrayByTime,
                {
                  time: time,
                  is_user: el.is_user,
                  items: [el],
                },
              ];
              break;

            default:
              let find = false;
              arrayByTime = arrayByTime
                .reverse()
                .map((item2) => {
                  if (
                    item2.time === time &&
                    item2.is_user === el.is_user &&
                    !find
                  ) {
                    find = true;
                    return {
                      ...item2,
                      is_user: el.is_user,
                      items: [el, ...item2.items],
                    };
                  } else {
                    return item2;
                  }
                })
                .reverse();
              break;
          }
          break;
      }
    });
    return {
      ...item,
      items_by_time: arrayByTime.reverse(),
    };
  });

  return arrayByDate.sort(
    (a, b) =>
      moment(b.date, "DD.MM.YYYY").valueOf() -
      moment(a.date, "DD.MM.YYYY").valueOf()
  );
}



export function getRandomId() {
  const ObjectId = (m = Math, d = Date, h = 16, s = s => m.floor(s).toString(h)) =>
    s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h))
  return ObjectId()

}


export function CREATE_VALIDATION_ARRAY(validity) {
  const values = Object.values(validity)
  const keys = Object.keys(validity)
  let array_to_validate = values.map((item, i) => {
    return {
      value: item,
      key: keys[i],
    }
  }).filter(item => item.key !== 'show_error')
  return array_to_validate
}

export function CHECK_IS_VALID(validity) {
  let array_to_validate = CREATE_VALIDATION_ARRAY(validity)

  const is_valid = array_to_validate.filter((item, i) => {
    if (item.key === 'children') {
      if (item.value.female.length > 0 && item.value.male.length > 0) {

        if (item.value.female.filter((item2, i2) => !item2.validity).length > 0 || item.value.male.filter((item2, i2) => !item2.validity).length > 0) return true
        else return false
      }
      else if (item.value.female.length > 0) {


        if (item.value.female.filter((item2, i2) => !item2.validity).length > 0) return true
        else return false
      }
      else if (item.value.male.length > 0) {

        if (item.value.male.filter((item2, i2) => !item2.validity).length > 0) return true
        else return false
      }
      else {

        return true
      }

    }
    else return !item.value
  })

  return is_valid.length === 0
}

export function STRING_OR_NULL(string) {
  return string ? string : null
}

export function FORMAT_CHILDERN_IN_ADVERT(items, items2, offer_type, with_deposit) {
  let new_items = []
  if (items && items.length > 0) new_items = new_items.concat(items)
  if (items2 && items2.length > 0) new_items = new_items.concat(items2)
  new_items = new_items.map((maped_item) => {
    return {
      _id: STRING_OR_NULL(maped_item._id),
      name: STRING_OR_NULL(maped_item.name),
      colour: STRING_OR_NULL(maped_item.colour),
      peculiarities: STRING_OR_NULL(maped_item.peculiarities),
      price: offer_type !== 'adoption' ? STRING_OR_NULL(`${maped_item.price}`) : null,
      deposit_percent: offer_type === 'sale' ? with_deposit ? 20 : null : null,
      gender: STRING_OR_NULL(maped_item.gender),
      image: maped_item.image ? STRING_OR_NULL(maped_item.image._id) : null,
      is_available: maped_item.is_available,
      is_closed: maped_item.is_closed,
    }
  })
  return new_items
}



export function FORMAT_DATA_ADVERT_TO_API({ _id, data, subcategory }) {
  const father = data.father
  const mother = data.mother
  return {
    _id: STRING_OR_NULL(_id),
    category_id: subcategory.category._id,
    sub_category_id: subcategory._id,
    title: STRING_OR_NULL(data.title),
    with_deposit: data.offer_type === 'adoption' ? null : data.with_deposit,
    offer_type: data.offer_type,
    date_of_birth: moment(data.date_of_birth).isValid() ? STRING_OR_NULL(moment(data.date_of_birth).format('YYYY-MM-DD')) : null,
    description: STRING_OR_NULL(data.description),
    breed: data.selected_breed ? data.selected_breed.value : null,
    litter_info: {
      id: STRING_OR_NULL(data.litter_id),
      lof: STRING_OR_NULL(data.litter_lof),
      vaccination: data.vaccination,
      wormed: data.wormed,
      microchip: data.microchip,
      dna_test: data.dna_test,
    },
    father: {
      name: STRING_OR_NULL(father.name),
      breed: father.breed_data ? father.breed_data.value : null,
      colour: STRING_OR_NULL(father.colour),
      date_of_birth: moment(father.date_of_birth).isValid() ? STRING_OR_NULL(moment(father.date_of_birth).format('YYYY-MM-DD')) : null,
      lof: STRING_OR_NULL(father.lof),
      image: father.image ? STRING_OR_NULL(father.image._id) : null,
    },
    mother: {
      name: STRING_OR_NULL(mother.name),
      breed: mother.breed_data ? mother.breed_data.value : null,
      colour: STRING_OR_NULL(mother.colour),
      date_of_birth: moment(father.date_of_birth).isValid() ? STRING_OR_NULL(moment(mother.date_of_birth).format('YYYY-MM-DD')) : null,
      lof: STRING_OR_NULL(mother.lof),
      image: mother.image ? STRING_OR_NULL(mother.image._id) : null,
    },
    children: FORMAT_CHILDERN_IN_ADVERT(data.children.female, data.children.male, data.offer_type, data.offer_type === 'adoption' ? false : data.with_deposit),
    images: data.images && data.images.length > 0 ? data.images.length > 30 ? data.images.map((maped_item) => maped_item._id).slice(0, 30) : data.images.map((maped_item) => maped_item._id) : [],
  }
}

export function PARSE_PHOTOS_TO_GALLERY(photos) {
  let images = []
  for (let index = 0; index < photos.length; index = index + 3) {
    const element1 = photos[index];
    const element2 = photos[index + 1];
    const element3 = photos[index + 2];
    if (element1) {
      images = [
        ...images,
        [element1],
        [element2, element3],
      ]
    }

  }
  return images
}

export function formatPhone(phone) {
  let value = phone.replace(/\D/g, "");
  let formattedValue = "";
  if (!value) {
    return "";
  } else {
    formattedValue =
      "+" +
      value.substring(0, 2) +
      " " +
      value.substring(2, 3) +
      " " +
      value.substring(3, 5) +
      " " +
      value.substring(5, 7) +
      " " +
      value.substring(7, 9) +
      " " +
      value.substring(9);
    return formattedValue;
  }
}

export const storage = {
  saveFavoriteList: function (set) {
      localStorage.setItem('FavoriteList', JSON.stringify(Array.from(set)));
  },
  getFavoriteList() {
      return JSON.parse(localStorage.getItem('FavoriteList'));
  },
  getCurrentScroll() {
      return localStorage.getItem('scroll');
  },
  saveCurrentScroll(currentScroll) {
      return localStorage.setItem('scroll', currentScroll);
  }
}

export function getCookie(name) {
  var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
}

// console.log({ csrf_access_token: getCookie('csrf_access_token'), csrf_refresh_token: getCookie('csrf_refresh_token') })
// authjwt_access_csrf_header_name: str = "X-ACCESS-CSRF-TOKEN"
// authjwt_refresh_csrf_header_name: str = "X-REFRESH-CSRF-TOKEN"


export const convertToKeyValueArray = obj => Object.entries(obj).map(([key, value]) => ({ key, value }));
