import { useEffect, useState } from "react";
import { useField } from "formik";
import { motion, AnimatePresence } from "framer-motion";
import {
  PasswordHiddenIcon,
  PasswordVisibleIcon,
  CheckIcon,
} from "../../assets/icons/icons";
import "./InputWithFormik.css";

function InputWithFormik({
  label,
  labelSpan,
  isPasswordVisible,
  onPasswordToggle,
  onChange,
  setFieldValue,
  values,
  isPlaceholder,
  isTextarea,
  isUserExist,
  isNoValidState,
  isNoValidationVisible,
  ...props
}) {
  const [field, meta] = useField(props);
  const [isLabelTransform, setLabelTransform] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (isPlaceholder) setLabelTransform(true);
  }, [isPlaceholder]);

  useEffect(() => {
    if (isPlaceholder || isFocused) return;

    setLabelTransform(Boolean(meta.value));
  }, [isFocused, isPlaceholder, meta.value]);

  function handleFocused() {
    setLabelTransform(true);
    setIsFocused(true);
  }

  function handleUnfocused(evt) {
    const value = evt.target.value;
    if (!isPlaceholder) {
      setLabelTransform(Boolean(value));
      setIsFocused(false);
    }
    if (props.onBlur) props.onBlur();
  }

  return (
    <div className="formik-input">
      <div
        className={`formik-input__container ${
          isLabelTransform ? "formik-input__container_focused" : ""
        }`}
      >
        <AnimatePresence initial={false} mode="popLayout">
          <motion.label
            className="formik-input__label"
            htmlFor={props.id || props.name}
            key={props.id || props.name}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.6 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.15 }}
          >
            {labelSpan ? (
              <p className="formik-input__label-text">
                {label}{" "}
                <span className="formik-input__label-span">{labelSpan}</span>
              </p>
            ) : (
              label
            )}
          </motion.label>
        </AnimatePresence>
        {isTextarea ? (
          <textarea
            className={`formik-input__input formik-input__input_type_textarea ${
              meta.touched && meta.error ? "formik-input__input_invalid" : ""
            } ${
              meta.touched && !meta.error ? "formik-input__input_valid" : ""
            }`}
            {...field}
            {...props}
            onClick={handleFocused}
            onFocus={handleFocused}
            onBlur={(evt) => {
              handleUnfocused(evt);
              field.onBlur(evt);
            }}
          />
        ) : (
          <input
            className={`formik-input__input ${
              meta.touched && meta.error ? "formik-input__input_invalid" : ""
            } ${isNoValidationVisible ? '' :
              props.name.toLowerCase().includes("email") &&
              isUserExist !== undefined
                ? meta.touched && !meta.error && isUserExist && !isNoValidState
                  ? "formik-input__input_valid"
                  : ""
                : meta.touched && !meta.error && !isNoValidState
                ? "formik-input__input_valid"
                : ""
            } ${
              props.name.toLowerCase().includes("password") ||
              (props.name.toLowerCase().includes("email") &&
                isUserExist !== undefined)
                ? "formik-input__input_type_with-icon"
                : ""
            }`}
            {...field}
            {...props}
            onChange={(evt) =>
              onChange
                ? props.name.toLowerCase().includes("email")
                  ? onChange(evt, setFieldValue, meta.error, values)
                  : onChange(evt, setFieldValue, props.name)
                : field.onChange(evt)
            }
            onClick={handleFocused}
            onFocus={handleFocused}
            onBlur={(evt) => {
              handleUnfocused(evt);
              field.onBlur(evt);
            }}
          />
        )}
        {props.name.toLowerCase().includes("password") ? (
          <button
            className="formik-input__password-btn"
            type="button"
            aria-label="Toggle password visibility"
            onClick={() => onPasswordToggle(props.name)}
          >
            <AnimatePresence initial={false} mode="popLayout">
              {isPasswordVisible && (
                <motion.div
                  className="formik-input__pass-icon-box"
                  key="password-visible"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.15 }}
                >
                  {PasswordVisibleIcon(
                    "formik-input__icon",
                    "formik-input__icon-fill"
                  )}
                </motion.div>
              )}
              {!isPasswordVisible && (
                <motion.div
                  className="formik-input__pass-icon-box"
                  key="password-hidden"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.15 }}
                >
                  {PasswordHiddenIcon(
                    "formik-input__icon",
                    "formik-input__icon-fill"
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </button>
        ) : props.name.toLowerCase().includes("email") &&
          isUserExist &&
          meta.value &&
          !meta.error ? (
          <AnimatePresence mode="wait">
            <motion.div
              className="formik-input__icon-box"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.15 }}
            >
              {CheckIcon(
                "formik-input__check-icon",
                "formik-input__check-icon-stroke"
              )}
            </motion.div>
          </AnimatePresence>
        ) : null}
      </div>

      <motion.div
        className="formik-input__error-box"
        initial={{
          height: "0px",
          marginTop: "0px",
          opacity: 0,
          visibility: "hidden",
        }}
        animate={{
          height: meta.touched && meta.error ? "14px" : "0px",
          marginTop: meta.touched && meta.error ? "6px" : "0px",
          opacity: meta.touched && meta.error ? 1 : 0,
          visibility: meta.touched && meta.error ? "visible" : "hidden",
        }}
        transition={{ duration: 0.15 }}
      >
        {meta.touched && meta.error ? (
          <p className="formik-input__error">{meta.error}</p>
        ) : null}
      </motion.div>
    </div>
  );
}

export default InputWithFormik;
